<script>
// import { throttle } from 'lodash';
import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    slidenIn:vSlidenIn
  },
  data(){
    return{
      activeID1:"",
      abc:12,
      titleDesignElements: null
    }
  },
  methods:{
    ToBigModel(){
      window.open("https://www.oilgasinfoai.com/AiPop")
    },
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },
    dou1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Murf platform.</a>' +
          '<br><span style="color:orange">https://murf.ai/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://murf.ai/';
        this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    xun1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Lumen  website platform.</a>' +
          '<br><span style="color:orange">https://lumen5.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://lumen5.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    tong1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Midjourney  website platform.</a>' +
          '<br><span style="color:orange">https://www.midjourney.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://www.midjourney.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });

    },
    zhi1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the ChatGLM  website platform.</a>' +
          '<br><span style="color:orange">https://chatglm.cn/?lang=en', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://chatglm.cn/?lang=en';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    teng1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Flair  website platform.</a>' +
          '<br><span style="color:orange">https://flair.ai/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://flair.ai/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    tian1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Gamma  website platform.</a>' +
          '<br><span style="color:orange">https://gamma.app/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://gamma.app/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    ge1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Cursor  website platform.</a>' +
          '<br><span style="color:orange">https://www.cursor.com', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://www.cursor.com';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    wan1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Runwayml  website platform.</a>' +
          '<br><span style="color:orange">https://runwayml.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://runwayml.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    expo1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas Exposition  website platform.</a><br><span style="color:orange">https://www.oilgasmall.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 2)
        // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
        const externalLink = 'https://www.oilgasmall.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    job1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas JobAI  website platform.</a><br><span style="color:orange">https://www.oilgasjobai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 2)
        const externalLink = 'https://www.oilgasjobai.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    info1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas Information  website platform.</a><br><span style="color:orange">https://www.oilgasinfoai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 2)
        const externalLink = 'https://www.oilgasinfoai.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    wen1(){
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Open AI  website platform.</a>' +
          '<br><span style="color:orange">https://openai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://openai.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    handleScroll2() {
      // 获取所有 titleDesign 元素
      // const titleDesignElements = document.querySelectorAll('.cardindex');
      // console.log(" titleDesignElements----------", titleDesignElements); // 输出 title
      // 遍历所有 titleDesign 元素

      // titleDesignElements.forEach((element) => {
      //   // console.log("head1----------",'head'+element.id); // 输出 title
      //   // console.log("activted1----------",this.activeID1); // 输出 title
      //   const style = window.getComputedStyle(element);
      //   const rect = element.getBoundingClientRect();
      //   // 检查元素是否为 sticky 定位且顶部位置为 0（或页面顶部）
      //   if (style.position === 'sticky' && (rect.top < 150 && rect.top>0)) {
      //     this.activeID1 = element.id
      //   }
      // });
      let elementTop = this.$refs.zixun.offsetTop
      console.log('this.$refs.zixun.offsetTop---------',elementTop)
      let currentTop=document.getElementById("totalModel").scrollTop
      console.log('.getElementById("totalModel").scrollY--------',currentTop)
      if(currentTop+100 >= elementTop){
        let elementTop2 = this.$refs.general.offsetTop
        if (currentTop + 100 <= elementTop2){
          this.activeID1 = 'APP1'
        }else {
          this.activeID1 = 'APP2'
        }
      }
    },
    FreeMobileNavigation(refName){
      // 使用 this.$refs 访问目标元素
      let distanceY = this.$refs[refName].getBoundingClientRect().top;
      console.log("distanceY---------",distanceY)
      // 使用 scrollIntoView 方法滚动到该元素s
      document.getElementById("totalModel").scrollBy({
        top: distanceY-80, // 向下滚动100像素
        left: 0,
        behavior: 'smooth'
      });
    }
  },
  mounted() {
    // this.titleDesignElements = document.querySelectorAll('.cardindex');
    // const throttledScroll = throttle(this.handleScroll2, 200);
    // document.getElementById("totalModel").addEventListener('scroll', throttledScroll);
    document.getElementById("totalModel").addEventListener('scroll', this.handleScroll2)
  },
  destroyed() {
    // 页面销毁时移除滚动事件监听
    // document.getElementById("totalModel").removeEventListener('scroll', this.handleScroll2);
  }
}
</script>

<template>
  <div class="customizationGPTs" style="height: fit-content">
    <div class="customizationTitle" style="padding:1vw">
      <span v-sliden-in="{}" style="font-family: 'Times New Roman';font-size: 7vw;line-height:7vw;color: #122277;">
        1. Free Oil & Gas INFO
      </span>
<!--      <div class="selectors" >-->
<!--        <div class="selector" id="setclass_APP1" :class="{'is-active': this.activeID1 === 'APP1'}" @click="FreeMobileNavigation('card1')">-->
<!--          <span >1. Oil & Gas INFO GPTs</span>-->
<!--        </div>-->
<!--        <div class="selector" id="setclass_APP2" :class="{'is-active': this.activeID1 === 'APP2'}" @click="FreeMobileNavigation('card2')">-->
<!--          <span>2. Office Support GPTs</span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
      <div class="card" ref="card1" style="display: flex;height: fit-content">
<!--        <div style="width:20%;">-->
<!--          <div class="cardindex" key="key1" id="APP1" style="display: flex;z-index: 2;background-color: white;position: sticky;top:100px">-->
<!--            <span style="margin: 20px auto ;font-weight: 700;font-size: 35px" >-->
<!--              1.-->
<!--             </span>-->
<!--          </div>-->
<!--        </div>-->
        <!--    油气资讯GPTs-->
        <div ref="zixun" style="padding:10px  4.6875vw 25px 4.6875vw;" class="screen3">
          <div style="top: 17vw;background-color: white;z-index: 2">
            <div class="title" v-sliden-in="{}">Free Oil & Gas INFO GPTs</div>
          </div>
            <div class="light" v-sliden-in="{}">
              Provide free oil & gas information.
              <span class="light2" style="font-size: 3.6vw;color: #193fe0"> <br>For a customized usage experience,
               <span style="white-space: nowrap;font-size: 3.6vw"> <span
                   style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px"
                   @click="clickConsult('Free Oil & Gas INFO GPTs')">Contact Us</span>.</span></span>
            </div>


          <div class="pro1">

              <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="info1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/information.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1">Oil & Gas Information</div>
                  <div class="title2">
                    MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
                <!--                    </div>-->
              </div>

              <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="ToBigModel">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/gptzixun.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> Free Oil & Gas GPT</div>
                  <div class="title2">
                    MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
              </div>

              <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="job1">
                <div class="back">
                  <div class="photo">
                    <img src="@/assets/job.png">
                  </div>
                </div>
                <div class="back1">
                  <div class="title1"> Oil & Gas JobAI</div>
                  <div class="title2">
                    MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
              </div>

            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="swiper-pagination"></div>-->
            <!--              </div>-->


          </div>
        </div>

      </div >
      <div class="card" ref="card2" style="display: flex;height: fit-content">
<!--        <div style="width:20%;">-->
<!--          <div class="cardindex" key="key2" id="APP2" style="display: flex;z-index: 2;background-color: white;position: sticky;top:100px">-->
<!--        <span  style="margin: 20px auto ;font-weight: 700;font-size: 40px">-->
<!--          2.-->
<!--         </span>-->
<!--          </div>-->
<!--        </div>-->
        <!--    通用性GPTs-->
        <div ref="general" style="" class="screenGeneral">
          <div v-sliden-in="{}" style="top: 16vw;background-color: white;z-index: 2">
            <div class="title" style="">Office GPTs</div>
          </div>
            <div v-sliden-in="{}" class="light">
              Improve work efficiency.
              <span class="light2" style="color: #193fe0"> <br>For customized usage training and development,
                <span style="white-space: nowrap;"><span
                    style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px"
                    @click="clickConsult('Office GPTs')">Contact Us</span>.</span></span>
            </div>


          <!--            <div ref="observeElement111" class="list-item-generate" data-index="50" :class="{ visible: isVisible[50] }" >-->
          <div class="pro1">
            <!--油田-->
            <!--              <div ref="observeElement111" class="list-item" data-index="50" :class="{ visible: isVisible[50] }" >-->
            <div class="grand2 grid1" @click="wen1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/openai.png"/>
                </div>
                <div>
                  <div class="title1">Open AI</div>

                </div>

              </div>
            </div>
            <!--              </div>-->
            <!--        油藏-->
            <!--              <div ref="observeElement111" class="list-item" data-index="50" :class="{ visible: isVisible[50] }" >-->
            <div class="grand2 grid1" @click="dou1">
              <div class="back">
                <div class="photo"><img src="@/assets/murf.png"/></div>
                <div>
                  <div class="title1">Murf</div>

                </div>
              </div>
            </div>
            <!--              </div>-->
            <!--        智能-->
            <!--                <div ref="observeElement111" class="list-item" data-index="50" :class="{ visible: isVisible[50] }" >-->
            <div class="grand2 grid1" @click="tong1">
              <div class="back">
                <div class="photo">
                  <img style="height: 85%;margin-top: 7.5%" src="@/assets/MidJourney-copy.png"/>
                </div>
                <div>
                  <div class="title1">Midjourney</div>

                </div>
              </div>
            </div>
            <!--                </div>-->
            <!--采油气-->
            <!--              <div ref="observeElement112" class="list-item" data-index="51" :class="{ visible: isVisible[51] }" >-->
            <div class="grand2 grid1" @click="zhi1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/zhi.png" style="height: 75%;margin-top: 10%"/>
                </div>
                <div>
                  <div class="title1">ChatGLM</div>

                </div>
              </div>
            </div>
            <!--                </div>-->
            <!--        智能-->
            <!--                <div ref="observeElement112" class="list-item" data-index="51" :class="{ visible: isVisible[51] }" >-->
            <div class="grand2 grid1" @click="xun1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/Lumen5-PrimaryLogo.png" style="height:85%;margin-top: 5%"/>
                </div>
                <div>
                  <div class="title1">Lumen</div>

                </div>
              </div>
            </div>
            <!--                </div>-->
            <!--采油气-->
            <!--                  <div ref="observeElement112" class="list-item" data-index="51" :class="{ visible: isVisible[51] }" >-->
            <div class="grand2 grid1" @click="teng1">
              <div class="back">
                <div class="photo">
                  <img style="height: 90%;margin-top: 5%" src="@/assets/flair.png"/>
                </div>
                <div>
                  <div class="title1">Flair</div>

                </div>
              </div>
            </div>
            <!--                  </div>-->
            <!--采油气-->
            <!--              <div ref="observeElement113" class="list-item" data-index="52" :class="{ visible: isVisible[52] }" >-->
            <div class="grand2 grid1" style="padding-bottom: 0" @click="tian1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/gamma.png"/>
                </div>
                <div>
                  <div class="title1">Gamma</div>

                </div>
              </div>
            </div>
            <!--                </div>-->
            <!--采油气-->
            <!--              <div ref="observeElement113" class="list-item" data-index="52" :class="{ visible: isVisible[52] }" >-->
            <div class="grand2 grid1" style="padding-bottom: 0" @click="ge1">
              <div class="back">
                <div class="photo">
                  <img src="@/assets/placeholder-logo.webp"/>
                </div>
                <div>
                  <div class="title1">Cursor</div>

                </div>
              </div>
            </div>
            <!--              </div>-->
            <!--采油气-->
            <!--                <div ref="observeElement113" class="list-item" data-index="52" :class="{ visible: isVisible[52] }" >-->
            <div class="grand2 grid1" style="padding-bottom: 0" @click="wan1">
              <div class="back">
                <div class="photo">
                  <img style="height: 90%;margin-top: 5%" src="@/assets/runway.png"/>
                </div>
                <div>
                  <div class="title1">Runwayml</div>

                </div>
              </div>
            </div>
            <!--                </div>-->

            <!--            &lt;!&ndash;采油气&ndash;&gt;-->
            <!--            <div class="grand2" @click="yi1">-->
            <!--              <div class="back">-->
            <!--                <div class="photo">-->
            <!--                  <img  style="height: 85%;margin-top: 10%" src="@/assets/yi.png"/>-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                  <div class="title1">Yizhen TV</div>-->
            <!--                  <div class="title2">-->
            <!--                    Yizhen TV is an intelligent AI content generation platform, which collects tools such as AI digital person, AI help writing, AI video, AI painting, etc. It supports multi-platform article one-key-to-video and digital person broadcasting video, and helps enterprises and self-media to create efficiently.-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

          </div>
          <!--            </div>-->


        </div>
      </div >
  </div>
</template>

<style scoped lang="scss">
.screenGeneral {
  //z-index: 10;
  height: fit-content;
  padding: 0px 4.6875vw 25px 4.6875vw;
  background: rgba(255, 255, 255, 1);

  .grid1 {
    opacity: 0 !important;
    transform: translateY(20px);


  }

  .grid1.visible {
    opacity: 1 !important;
    transform: translateY(0);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  .title {
    margin: 0 auto;
    //text-align: center;
    height: fit-content;
    opacity: 1;
    //font-size: 20px;
    //color: #53A3F7;
    font-family: Times New Roman;
    font-weight: bold;
    //line-height: 6vw;
    font-size: 6vw;
    text-align: left;
    line-height: 40px;
    color: black;
  }

  .light {
    //margin: 10px 2vw;
    //text-align: justify;
    width: fit-content;
    height: fit-content;
    //font-size: 14px;
    font-family: Times New Roman;
    font-weight: 400;
    color: #2C3E50;
    //line-height: 20px;
    font-size: 3.6vw;
    line-height: 23px;
    margin: 10px 0;
  }

  .light2 {
    //margin: 20px auto 0 auto;
    text-align: center;
    width: fit-content;
    //font-size: 14px;
    font-family: Times New Roman;
    font-weight: 400;
    color: #FF6400;
    //line-height: 20px;
    font-size: 3.6vw;
    line-height: 23px;
    margin: 10px 0;
  }

  .pro1 {
    height: fit-content;
    width: 100%;
    //z-index: 89156;
    display: grid;
    grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 2px;
    row-gap: 0;

    .grand2 {
      cursor: pointer;
      width: 100%;
      height: fit-content;
      padding: 9px 4px 0 4px;
      opacity: 1;
      position: relative;

      .back {
        width: 100%;
        height: 100%;
        background: white;;
        padding: 10px 5px 6px 5px;
        //box-shadow: 1px 0 2px 0px rgba(115, 115, 115, 0.5);
        border: 1px solid #C9C9C9;
        border-radius: 9px;
        //display: flex;
      }

      .photo {
        overflow: hidden;
        border-radius: 9px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        margin: 8px auto 6px auto;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 35px;
        height: 40px;
      }

      .title1 {
        opacity: 4;
        margin: 2px auto 8px auto;
        text-align: center;
        height: 7.3.6vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Times New Roman;
        font-weight: normal;
        color: #0a0a0a;
        line-height: 20px;
      }

      .title2 {
        width: 110px;
        opacity: 4;
        margin: 5px 3px 5px 0;
        height: 14px;
        word-wrap: break-word; //换行
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-family: Times New Roman;
        font-weight: 500;
        //color: #2168DB;
        line-height: 16px;
      }

      .consult {
        position: absolute;
        display: none;
        font-family: Times New Roman;
        font-weight: 500;
        font-size: 0.80878105141537vw;
        bottom: 3.94437577255871vh;
        left: 1.85540150202195vw;
      }
    }
  }

}
.screen3 {
  //z-index: 10;
  height: fit-content;
  padding: 25px  4.6875vw 25px 0;
  background: rgba(255, 255, 255, 1);

  .title {
    margin: 0 auto;
    //text-align: center;
    height: fit-content;
    opacity: 1;
    //font-size: 20px;
    //color: #53A3F7;
    font-family: Times New Roman;;
    //font-family:Times New Roman;
    font-weight: bold;
    //line-height: 6vw;
    color: #000000;
    font-size: 6vw;
    text-align: left;
    line-height: 40px
  }

  .light {
    //margin: 10px 2vw;
    //text-align: justify;
    width: fit-content;
    height: fit-content;
    //font-size: 14px;
    font-family: Times New Roman;
    font-weight: 400;
    color: #2C3E50;
    //line-height: 20px;
    font-size: 3.6vw;
    line-height: 23px;
    margin: 10px 0
  }

  .light2 {
    //margin: 20px auto 0 auto;
    text-align: center;
    width: fit-content;
    //font-size: 14px;
    font-family: Times New Roman;
    font-weight: 400;
    color: #FF6400;
    //line-height: 20px;
    font-size: 3.6vw;
    line-height: 23px;
    margin: 10px 0
  }

  .pro1 {
    height: fit-content;
    width: 100%;
    //z-index: 89156;

    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: 0.98887515451174vh 0 0 0;
      opacity: 1;
      position: relative;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border-radius: 9px;
        border: white 2px solid;
        margin-top: 5px;
        // border-radius:6px;
        // display: flex;
      }

      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: fit-content;
        background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        // border-radius:6px;
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
      }

      .photo {
        overflow: hidden;
        //background-image: url("../assets/pro1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        border-radius: 9px;
        height: 260px;
      }

      .title1 {
        text-align: left;
        font-size: 22px;
        font-family: Times New Roman;
        font-weight: bold;
        color: white;
        text-overflow: ellipsis;
        width: 60vw;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        //text-overflow: ellipsis;
        margin: 0 0 7px 0
        //white-space: nowrap;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        // text-align: justify;
        font-size: 15px;
        font-family: Times New Roman;
        font-weight: 500;
        margin: auto 0 10px 0;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }

  .pro11 {
    height: fit-content;
    width: 100%;
    //z-index: 89156;

    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: 0.98887515451174vh 0 0 0;
      opacity: 1;
      position: relative;

      .back {
        width: 100%;
        height: 260px;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border-radius: 9px;
        border: white 2px solid;
        // border-radius:6px;
        // display: flex;
      }

      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: fit-content;
        background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
        //box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        // border-radius:6px;
        display: flex;
        padding: 10px 15px;
        justify-content: space-between;
      }

      .photo {
        overflow: hidden;
        //background-image: url("../assets/pro1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        border-radius: 9px;
        height: 100%;
      }

      .title1 {
        text-align: left;
        //font-size: 18px;
        font-family: Times New Roman;
        font-weight: bold;
        color: white;
        text-overflow: ellipsis;
        width: 58vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        //white-space: nowrap;
        //line-height: 35px;
        margin: 0 0 7px 0;
        font-size: 22px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        margin: auto 0 10px 0;
        // text-align: justify;
        font-size: 15px;
        font-family: Times New Roman;
        font-weight: 500;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }

  .pro2 {
    height: fit-content;
    width: 100%;
    overflow-x: auto;
    //z-index: 89156;


    .grand1 {
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: 0.98887515451174vh 0 0 0;
      opacity: 1;
      height: 250px;
      position: relative;

      .back {
        width: 100%;
        height: fit-content;
        //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
        background-color: white;
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        //border-radius: 9px;
        border: white 2px solid;
        // border-radius:6px;
        // display: flex;
      }

      .back1 {
        bottom: 0;
        position: absolute;
        width: 100%;
        color: white;
        height: fit-content;
        background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
        ////box-shadow: 5px 0 14px 0 #d4e4ff;
        border: white 2px solid;
        border-top: none;
        // border-radius:6px;
        display: flex;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        padding: 10px 15px;
        justify-content: space-between;
      }

      .photo {
        overflow: hidden;

        border-radius: 9px;
        border: #dddddd 3px solid;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 100%;
        height: 100%;
      }

      .title1 {
        text-align: left;
        font-size: 18px;
        font-family: Times New Roman;
        font-weight: bold;
        color: white;
        text-overflow: ellipsis;
        width: 50vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        //white-space: nowrap;
        //line-height: 35px;
      }

      .title2 {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 4;
        //width: 100%;
        height: 100%;
        // text-align: justify;
        margin: auto 0 10px 0;
        font-size: 12px;
        font-family: Times New Roman;
        font-weight: 500;
        //color: #2168DB;
        //line-height: 35px;
      }
    }

  }

  .pro3 {
    .grand1 {
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      grid-gap: 10px;
      height: fit-content;

      .cards {

      }
    }
  }
}
.is-active{
  //color: red !important;
  background-color: #B4B4B4 !important;
}
.customizationGPTs {
  background-color: white;
  width: 100%;
  .customizationTitle{
    margin: 0 auto;

    text-align: center;
    //height: -moz-fit-content;
    height: fit-content;
    opacity: 1;
    font-size: 25px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    line-height: 35px;
    position: sticky;
    top: -1px;
    padding:10px 0;
    background-color: white;
    z-index: 3;
    .selectors{
      margin-top:10px;
      margin-left: 2.5%;
      width: 95%;
      height: 40px;
      //background-color: #eb7c31;
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr);
      background-color: #371e75;
      //box-shadow: -5px 0px 10px rgba(189, 206, 243, 0.58);
      //border-bottom-left-radius: 10px;
      .selector{
        width: 100%;display: flex;
        //border-bottom-left-radius: 10px;
        //box-shadow: -5px 0px 10px rgba(189, 206, 243, 0.58);
        color: white;
        border: white solid 1px;
      }
      span{
        display: flex;
        align-items: center;
        margin: auto;
        text-align: center;
        //height: -moz-fit-content;
        height: fit-content;
        opacity: 1;
        font-size: 3.6vw;
        font-family: "Times New Roman";
        font-weight: bold;
        line-height: 6vw;

      }
    }
  }
  .screen13 {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);
    position: relative;


    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 6vw;
    }

    .light {
      margin: 15px 0;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }
    .pro1 {
      //height: fit-content;
      width: 100%;
      //z-index: 89156;
      .swiper-container0{
        position: relative;
      }
      ::v-deep .swiper-pagination {
        position: absolute;
      }
      ::v-deep .swiper-pagination-bullet {
        width: 5px !important;
        height: 25px !important;

        //position: absolute;
      }

      .swiper-container-vertical > .swiper-pagination-bullets{
        left:0px !important;
      }
      .swiper-pagination-bullet-active {
        background-color: rgb(47, 110, 255);
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        margin:0 0 0 auto;
        border-radius: 6px;
        padding: 15px  0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro11 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          margin:auto 0 0 auto;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        right: 20px;
        bottom: 20px;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 260px;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }
  .screen13::before{
    content: "";
    position: absolute;
    left: -3.6vw;
    width: 4px;
    height: 100%;
    z-index
    : 1;
    background: #d8d8d8;
  }
  //.screenGeneral {
  //  //z-index: 10;
  //  height: fit-content;
  //  padding: 20px 15px;
  //  background: rgba(255, 255, 255, 1);
  //  position: relative;
  //  .title {
  //    margin: 0 auto;
  //    text-align: center;
  //    height: fit-content;
  //    opacity: 1;
  //    font-size: 20px;
  //    //color: #53A3F7;
  //    font-family: Roboto,sans-serif;
  //    font-weight: bold;
  //    line-height: 6vw;
  //  }
  //
  //  .light {
  //    margin: 15px auto;
  //    text-align: justify;
  //    width: fit-content;
  //    height: fit-content;
  //    font-size: 16px;
  //    font-family: Roboto,sans-serif;
  //    font-weight: 400;
  //    color: #2C3E50;
  //    line-height: 25px;
  //  }
  //
  //  .light2 {
  //    margin: 20px auto 0 auto;
  //    text-align: justify;
  //    width: fit-content;
  //    font-size: 16px;
  //    font-family: Roboto,sans-serif;
  //    font-weight: 400;
  //    color: #FF6400;
  //    line-height: 25px;
  //  }
  //  .pro1 {
  //    height: fit-content;
  //    width: 100%;
  //    //z-index: 89156;
  //    display: grid;
  //    grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  //    column-gap: 2px;
  //    row-gap: 0;
  //
  //    .grand2 {
  //      cursor: pointer;
  //      width: 100%;
  //      height: fit-content;
  //      padding: 8px 4px 0 4px;
  //      opacity: 1;
  //      position: relative;
  //
  //      .back {
  //        width: 100%;
  //        height: 100%;
  //        background:  white;;
  //        padding: 0 5px;
  //        //box-shadow: 1px 0 2px 0px rgba(115, 115, 115, 0.5);
  //        border: 1px solid #C9C9C9;
  //        border-radius: 9px;
  //        //display: flex;
  //      }
  //
  //      .photo { overflow: hidden;
  //        border-radius: 9px;
  //        background-size: contain;
  //        background-position: center;
  //        background-repeat: no-repeat;
  //        background-color: transparent; /* 这里设置背景颜色为透明 */
  //        opacity: 1.25;
  //        margin: 8px auto 6px auto;
  //        display: flex;
  //        justify-content: center;
  //        text-align: center;
  //        width: 35px;
  //        height: 40px;
  //      }
  //
  //      .title1 {
  //        opacity: 4;
  //        margin: 2px auto 8px auto;
  //        text-align: center;
  //        height: fit-content;
  //        display: flex;
  //        align-items: center;
  //        justify-content: center;
  //        font-size: 16px;
  //        font-family: Roboto,sans-serif;
  //        //font-weight: bold;
  //        color: #0a0a0a ;
  //        line-height: 25px;
  //      }
  //
  //      .title2 {
  //        width: 110px;
  //        opacity: 4;
  //        margin: 5px 3px 5px 0;
  //        height: 14px;
  //        word-wrap: break-word; //换行
  //        white-space: nowrap;
  //        overflow: hidden;
  //        text-overflow: ellipsis;
  //        font-size: 12px;
  //        font-family: Roboto,sans-serif;
  //        font-weight: 500;
  //        //color: #2168DB;
  //        line-height: 16px;
  //      }
  //
  //      .consult {
  //        position: absolute;
  //        display: none;
  //        font-family: Roboto,sans-serif;
  //        font-weight: 500;
  //        font-size: 0.80878105141537vw;
  //        bottom: 3.94437577255871vh;
  //        left: 1.85540150202195vw;
  //      }
  //    }
  //  }
  //
  //}
  .screenGenera::before{
    content: "";
    position: absolute;
    left: -3.6vw;
    width: 4px;
    height: 100%;
    z-index
    : 1;
    background: #d8d8d8;
  }

}

</style>
