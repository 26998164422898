<script>
import {vMoveInDuration} from "@/utils/vMoveInDuration";
export default {
  directives:{
    moveInDuration:vMoveInDuration
  },
  methods:{
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },
    expo() {
      this.$confirm('<a style="color: white"><a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas Exposition  website platform.</a><br><span style="color:orange">https://www.oilgasmall.com/', ' ', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,

        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.oilgasmall.com/", "_blank");
      }).catch(() => {

      });

    },
    gptzixun() {
      this.$confirm('<a style="color: white"><a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Free Oil & Gas INFO  website platform.</a><br><span style="color:orange">https://www.oilgasinfoai.com/', ' ', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,

        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.oilgasinfoai.com/", "_blank");
      }).catch(() => {

      });

    },
    job() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas JobAI website platform.</a><br><span style="color:orange">https://www.oilgasjobai.com/', ' ', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://www.oilgasjobai.com/", "_blank");
      }).catch(() => {

      });

      //
      // this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas JobAI  website platform.', ' ', {
      // if (answer) {
      //   localStorage.setItem("skip", 0)
      //   window.open("https://oilgasjobai.com/", "_blank");
      // } else {
      //   // 用户取消跳转, 留在当前页面
      // }

    },
    info() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas Information  website platform.</a><br><span style="color:orange">https://www.oilgasinfoai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.oilgasinfoai.com/", "_blank");
      }).catch(() => {

      });

    },
    // 测试
    wen() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Open AI  website platform.</a><br><span style="color:orange">https://openai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://openai.com/", "_blank");
      }).catch(() => {

      });
    },
    dou() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Murf  website platform.</a>' +
          '<br><span style="color:orange">https://murf.ai/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://murf.ai/", "_blank");
      }).catch(() => {

      });

    },
    xun() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Lumen  website platform.</a>' +
          '<br><span style="color:orange">https://lumen5.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://lumen5.com/", "_blank");
      }).catch(() => {

      });

    },
    tong() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Midjourney  website platform.</a>' +
          '<br><span style="color:orange">https://www.midjourney.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.midjourney.com/", "_blank");
      }).catch(() => {

      });

    },
    // 测试156
    zhi() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the ChatGLM  website platform.</a>' +
          '<br><span style="color:orange">https://chatglm.cn/?lang=en', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://chatglm.cn/?lang=en", "_blank");
      }).catch(() => {

      });

    },
    teng() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Flair  website platform.</a>' +
          '<br><span style="color:orange">https://flair.ai/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://flair.ai/", "_blank");
      }).catch(() => {

      });

    },
    tian() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Gamma  website platform.</a>' +
          '<br><span style="color:orange">https://gamma.app/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://gamma.app/", "_blank");
      }).catch(() => {

      });

    },
    ge() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Cursor  website platform.</a>' +
          '<br><span style="color:orange">https://www.cursor.com', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open(" https://www.cursor.com", "_blank");
      }).catch(() => {

      });

    },
    wan() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Runwayml Cursor  website platform.</a>' +
          '<br><span style="color:orange">https://runwayml.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://runwayml.com/", "_blank");
      }).catch(() => {

      });
    },
    yi() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Scholarcy  website platform.</a>' +
          '<br><span style="color:orange">https://www.scholarcy.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.scholarcy.com/", "_blank");
      }).catch(() => {

      });

    },
  }

}
</script>

<template>
<div class="freeGPTsContainer" >
  <div class="part1" style="    border: #aeaed7 dashed 1px;">
    <div class="part1_1" v-move-in-duration="{duration:800,direction:'x',offset:'-100'}" style="display:flex;">
      <div style="margin:auto">
        <div class="title11" style="color: black">
          Free Oil & Gas INFO GPTs
        </div>
        <div class="title22" style="color: black">
          Provide free oil & gas information.<br>
          <span style="color: #fb7017">For a customized usage experience,<span style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw" @click="clickConsult('Free Oil & Gas INFO GPTs')">Contact Us</span>.</span>
        </div>
      </div>
    </div>
    <div class="part1_2" >
      <div class="part1_2_1" >
        <div class="knowledge" v-move-in-duration="{duration:1200,direction:'x',offset:'100'}" style="height: 50%;width: 100%" @click="info">
          <div v-zoom-in="{duration:600}" class="grand111" style="position: relative;height: fit-content" >
            <div class="back">
              <div class="photo">
                <img class="image" style="border-radius: 9px" src="@/assets/information.png">
              </div>
            </div>
            <div class="back1">
              <div class="title1"> Oil & Gas INFO</div>
              <div class="title2">
                MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
              </div>
            </div>
          </div>
        </div>
        <div class="job" v-move-in-duration="{duration:800,direction:'x',offset:'100'}" style="height: 50%;width: 100%" @click="job">
<!--          <a href="/gptKnowledge" target="_blank">-->
            <div v-zoom-in="{duration:600}" class="grand111" style="position: relative;height: fit-content "  >
              <div class="back">
                <div class="photo">
                  <img class="image" style="border-radius: 9px" src="@/assets/job.png">
                </div>
              </div>
              <div class="back1">
                <div class="title1"> Oil & Gas JobAI</div>
                <div class="title2">
                  MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                </div>
              </div>
            </div>
<!--          </a>-->
        </div>

      </div>
      <div class="part1_2_2" v-move-in-duration="{duration:1500,direction:'x',offset:'100'}">
        <div  class="grand111" style="position: relative;height: fit-content" @click="gptzixun">
          <div class="back">
            <div class="photo" style="height: 52.5vh;">
              <img class="image" style="border-radius: 9px" src="@/assets/gptzixun.png">
            </div>
          </div>
          <div class="back1">
            <div class="title1"> Free Oil & Gas GPT</div>
            <div class="title2">
              MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="part2" style="    border: #aeaed7 dashed 1px;">
    <div v-move-in-duration="{duration:1200,direction:'x',offset:'-100'}" class="part2_1">
      <div class="grand2" @click="wen">
        <div class="back">
          <div class="photo">
            <!--                      <img class="image" src="@/assets/wen.png"/>-->
            <img class="image" src="@/assets/openai.png"/>
          </div>
          <div class="title1">
            <span>Open AI<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    ERNIE Bot is Baidu's new generation of knowledge-enhanced large language models that can dialog and interact with people, answer questions, assist in creation, and efficiently and conveniently help people access information, knowledge, and inspiration.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--        油藏-->
      <div class="grand2" @click="dou">
        <div class="back">
          <div class="photo"><img class="image" src="@/assets/murf.png"/></div>
          <div class="title1">
            <span>Murf<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    Grace is an AI tool developed by ByteDance based on the Skylark model, offering features such as a chatbot, a writing assistant, and an English learning assistant, which can help people access information by answering a variety of questions through dialog.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--        智能-->
      <div class="grand2" @click="tong">
        <div class="back">
          <div class="photo">
            <img class="image" src="@/assets/MidJourney-copy.png"/>
          </div>
          <div class="title1">
            <span>Midjourney<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    Tongyi Qianwen is a large language model independently developed by Aliyun, which can provide users with services and assistance in different fields and tasks through natural language understanding and semantic analysis on the basis of users' natural language input.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--采油气-->
      <div class="grand2" @click="zhi">
        <div class="back">
          <div class="photo">
            <img class="image" src="@/assets/zhi.png"/>
          </div>
          <div class="title1">
            <span>ChatGLM<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    The first generative AI assistant launched by Beijing Zhi Spectrum Huazhang Technology Co., Ltd, which is based on Zhi Spectrum AI's self-developed Chinese-English bilingual dialogue model ChatGLM2, pre-trained with trillions of characters of text and code, to provide users with intelligent services.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--        智能-->
      <div class="grand2" @click="xun">
        <div class="back">
          <div class="photo">
            <img class="image" src="@/assets/Lumen5-PrimaryLogo.png"/>
          </div>
          <div class="title1">
            <span>Lumen<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    The new generation of cognitive intelligence big model launched by KU Xunfei has cross-domain knowledge and language understanding ability, which can understand and perform tasks based on natural dialog way, providing language understanding, knowledge question and answer and other abilities.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--采油气-->
      <div class="grand2" @click="teng">
        <div class="back">
          <div class="photo">
            <img class="image" src="@/assets/flair.png"/>
          </div>
          <div class="title1">
            <span>Flair<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    Tencent Hunyuan is a big language model developed by Tencent, with cross-domain knowledge and natural language understanding capabilities, to achieve based on human-computer natural language dialogue, understand user instructions and perform tasks, to help users realize the human access to information, knowledge and inspiration.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--采油气-->
      <div class="grand2" @click="tian">
        <div class="back">
          <div class="photo">
            <img class="image" src="@/assets/gamma.png"/>
          </div>
          <div class="title1">
            <span>Gamma<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    Tiangong AI is able to realize a variety of application scenarios such as personalized AI search, intelligent Q&A, chat interaction, etc., and has a rich knowledge base covering the fields of science, technology, culture, art, history and so on.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--采油气-->
      <div class="grand2" @click="ge">
        <div class="back">
          <div class="photo">
            <img class="image" src="@/assets/placeholder-logo.webp"/>
          </div>
          <div class="title1">
            <span>Cursor<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    Wenxin Yige is launched by Baidu relying on its Wenxin big model and flying paddle technology of text to generate pictures of A1 art and creative assistance platform, as the first echelon of the domestic AI picture generation tools and platforms.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--采油气-->
      <div class="grand2" @click="wan">
        <div class="back">
          <div class="photo">
            <img class="image" src="@/assets/runway.png"/>
          </div>
          <div class="title1">
            <span>Runwayml<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    WanCai AI is a powerful collection of AI content creation tools. In addition to providing AI intelligent writing support, it also integrates powerful AI-generated content features such as AI face change, photo digital person creation and AI short video creation, which further expands the field of AI creation and gives you unlimited possibilities for your creation.-->
          <!--                  </div>-->
        </div>
      </div>
      <!--采油气-->
      <div class="grand2" @click="yi">
        <div class="back">
          <div class="photo">
            <img class="image" src="@/assets/scholarcy.png"/>
          </div>
          <div class="title1">
            <span>Scholarcy<br></span>
            <img src="@/assets/arrow_right_blue.png">
          </div>
          <!--                  <div class="title2">-->
          <!--                    Yizhen TV is an intelligent AI content generation platform, which collects tools such as AI digital person, AI help writing, AI video, AI painting, etc. It supports multi-platform article one-key-to-video and digital person broadcasting video, and helps enterprises and self-media to create efficiently.-->
          <!--                  </div>-->
        </div>
      </div>
    </div>
    <div v-move-in-duration="{duration:800,direction:'x',offset:'100'}" class="part2_2" style="display: flex">
      <div style="margin:auto">
        <div class="title11" style="color: black">
          Office GPTs
        </div>
        <div class="title22" style="color: black">
          Link to mainstream AI tool models, allowing you to reach them<br>with one click.<br>
          <span style="color: #fb7017">For customized usage training and development,<span style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw" @click="clickConsult('Office GPTs')">Contact Us</span>.</span>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.freeGPTsContainer{
  width: 100%;
  height: 110vh;
  font-family: "Times New Roman";
  //background-color: blue;
  .title11{
    font-size: 1.9vw;
    color: white;
    font-weight: bolder;
    padding: 0vw 2vw;
    width: 100%;
    height: fit-content;
  }
  .title22{
    font-size: 1.2vw;
    line-height: 2vw;
    color: white;
    padding: 2vw 2vw ;
    padding-top: 3vh;
    padding-right: 3vw;
  }
  .part1{
    height: 55vh;
    width: 100%;
    display: flex;
    .part1_1{
      height: 100%;
      width: 50%;
      background-color: white;
      //padding-bottom: 1vw;
      //margin-bottom: 1vw;
    }
    .part1_2{
      height: 100%;
      width: 50%;
      display: flex;
    }
    .part1_2_1{
      width: 50%;height: 100%;background-color: white;padding: 1vh;
    }
    .part1_2_2{
      width: 50%;height: 100%;background-color: white;padding: 1vh;
    }
  }
  .part2{
    width: 100%;
    height: 55vh;
    display: flex;
    .part2_1{
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
      gap: 1vw;
      height: 100%;
      width: 50%;
      background-color: white;
      padding: 0 1vw;
    }
    .part2_2{
      height: 100%;
      width: 50%;
      background-color: white;

    }
  }
}


.grand2 {
  cursor: pointer;
  width: 100%;
  margin: auto;
  height: 22vh;
  //padding: 0.98887515451174vh 0 0.98887515451174vh 0;
  opacity: 1;
  position: relative;

  .back {
    width: 100%;
    height: 100%;
    background: white;;;
    //box-shadow: 5px 0 14px 0px #D4E4FF;
    border: 2px solid #D4E4FF;
    border-radius: 9px;
  }

  .photo { overflow: hidden;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    opacity: 1.25;
    margin: 2vh auto auto auto;
    display: flex;
    justify-content: center;
    text-align: center;
    width:8.73176761433869vh;
    height: 8.73176761433869vh;
  }

  .title1 {
    opacity: 4;
    margin-top: 2.22496909765142vh;
    text-align: center;
    //display: flex;
    justify-content: center;
    align-items: center;
    height: 2.71940667490729vh;
    font-size: 1.1vw;
    font-family: "Times New Roman";
    font-weight: bold;
    //color: #2168DB;
    line-height: 2.71940667490729vh;
    img{
      padding-top: 1.5vh;
      width: 1vw;
      object-fit: contain;
    }
  }

  .title2 {
    opacity: 4;
    margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
    //text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden;
    height: 14vh;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
    font-size: 0.80878105141537vw;
    font-family: "Times New Roman";
    font-weight: 500;
    //color: #2168DB;
    line-height: 2.71940667490729vh;
  }

  .consult {
    position: absolute;
    display: none;
    font-family: "Times New Roman";
    font-weight: 500;
    font-size: 0.80878105141537vw;
    bottom: 3.94437577255871vh;
    left: 1.85540150202195vw;
  }
}

.grand2:hover {
  .back {
    background-color: rgb(247, 106, 35);
  }

  .photo { overflow: hidden;

  }

  .title1 {
    color: white;
    margin-top: 3.22496909765142vh;
    //position: absolute;
    //left: 1.85540150202195vw;
    //top: 2.22496909765142vh;
    //height: 2.71940667490729vh;
    //color: white;
    //font-size: 0.97094165222415vw;
  }

  .title2 {
    margin-top: 8.22496909765142vh;
    //text-align: justify;
    color: white;
    font-size: 0.80878105141537vw;
  }

  .consult {
    cursor: pointer;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    color: white;
  }

}

.grand111 {
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  //padding: 0.98887515451174vh 0 0 0;
  opacity: 1;
  height: fit-content;
  position: relative;

  .back {
    width: 100%;
    height: fit-content;
    //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
    background-color: white;
    ////box-shadow: 5px 0 14px 0 #d4e4ff;
    //border-radius: 9px;
    border: white 2px solid;
    // border-radius:6px;
    // display: flex;
  }

  .back1 {
    bottom: 0;
    position: absolute;
    width: 100%;
    color: white;
    height: fit-content;
    background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
    ////box-shadow: 5px 0 14px 0 #d4e4ff;
    border: white 2px solid;
    border-top: none;
    // border-radius:6px;
    display: flex;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    padding: 10px 15px;
    justify-content: space-between;
  }

  .photo { overflow: hidden;

    border-radius: 9px;
    border: #dddddd 3px solid;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
    opacity: 1.25;
    width: 100%;
    height: 26vh;
  }

  .title1 {
    opacity: 4;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4vw;;
    font-family: "Times New Roman";

    font-weight: bold;
    color: white;
    //line-height: 35px;
  }

  .title2 {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 4;
    //width: 100%;
    height: 100%;
    // text-align: justify;
    margin: auto 0 0 auto;
    font-size: 1.12vw;
    font-family: "Times New Roman";
    font-weight: 500;
    //color: #2168DB;
    //line-height: 35px;
  }
}
</style>
