<script >
import {vMoveInDuration} from "@/utils/vMoveInDuration";
export default {
  directives:{
    moveInDuration:vMoveInDuration
  },
  data(){
    return{
      cilentHeight:window.innerHeight,
    }
  },
  methods:{
    ToBigmodel(){
      window.open("https://www.oilgasinfoai.com/AIAnswer", "_blank");
    },
    ToInfo(){
      window.open('https://www.oilgasinfoai.com/','_blank')
    },
    NavigatTo(refName){
      console.log("this.$parent", this.$parent)
      // 使用 this.$refs 访问目标元素
      let distanceY = this.$parent.$refs[refName].offsetTop;
      // 使用 scrollIntoView 方法滚动到该元素s
      window.scrollBy({
        top: distanceY-window.scrollY-this.cilentHeight*0.07, // 向下滚动100像素
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<template>
<div class="navigationContainer">
  <div class="part0" style="display: flex;height: 27vh;width: 100%;padding: 2vh 1vh 2vh 1vh;gap: 1vh">
    <div v-move-in-duration="{duration:2000,direction:'y',offset:'-100'}" style="width: 50%;overflow: hidden">
      <div class="part0_2"  @click="ToBigmodel" style="width: 100%;height: 100%;background-color: black;display: flex">
      <span style="
    font-weight: bolder;
    margin: auto;
    color: white;
    font-size: 2vw;">
      Free Oil & Gas INFO GPT
            </span>
      </div>
    </div>
    <div v-move-in-duration="{duration:2000,direction:'x',offset:'100'}" style="width: 50%;overflow: hidden">
      <div class="part0_1"  @click="ToInfo" style="width: 100%;height: 100%;background-color: black;display: flex">
      <span style="
    font-weight: bolder;
    margin: auto;
    color: white;
    font-size: 2vw;">
              Free Oil & Gas INFO
      </span>

    </div>
    </div>
  </div>
  <div class="part3" style="width: 100%;display: grid;height:10vh;grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)">
    <div style="overflow:hidden;height: 100%">
      <div  @click="NavigatTo('consultMove')" class="part3_1" style="height: 100%">
      <span>
              Concomitant Smart<br>Oilfield Solution
      </span>
      </div>
    </div>
    <div style="overflow:hidden;height: 100%">
    <div @click="NavigatTo('professional')" class="part3_3" style="height: 100% ">
      <span>
              Customized Artificial Intelligence
      </span>

    </div>
    </div>
    <div style="overflow:hidden;height: 100%">
    <div @click="NavigatTo('software')" class="part3_2" style="height: 100%">
      <span>
             Smart Oilfield Software Products and Development
      </span>

    </div>
    </div>
    <div style="overflow:hidden;height: 100%">
    <div @click="NavigatTo('mediaMove')" class="part3_4" style="height: 100% ">
      <span>
              Intelligent Multimedia
      </span>

    </div>
    </div>
  </div>
<!--  <div style="display: flex;width: 100%;height: 28vh">-->
<!--    <div class="part2" @click="NavigatTo('consultMove')" v-move-in-duration="{duration:2000,direction:'x',offset:'-100'}">-->
<!--      <div class="freeGPTs" style="display: flex" >-->
<!--        <div style="margin: auto">-->
<!--          <div class="title" style="height: 7vh;margin: auto">-->
<!--            <span style="text-align: center">Concomitant Smart<br>Oilfield Solution</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="part1">-->
<!--      <div class="part1_2" >-->

<!--        <div class="part1_2_2" @click="NavigatTo('consultMove')" v-move-in-duration="{duration:2000,direction:'Y',offset:'-100'}" style="height: 100%;width: 50%;display: flex;">-->
<!--          <div class="mediumGPTs" style="width: 100%;height: 100%;display: flex">-->
<!--            <div class="title" style="margin: auto">-->
<!--              <span >Smart Oilfield<br>Software</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="part1_2_1" @click="NavigatTo('mediaMove')" v-move-in-duration="{duration:2000,direction:'x',offset:'100'}" style="height: 100%;width: 50%;display: flex">-->
<!--          <div class="solutinsGPTs" style="width: 100%;height: 100%;display: flex">-->
<!--            <div class="title" style="margin: auto">-->
<!--              <span>Intelligent Multimedia</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="part1_1" @click="NavigatTo('professional')" v-move-in-duration="{duration:2000,direction:'Y',offset:'100'}">-->
<!--        <div class="customizationGPTs">-->
<!--          <div class="title">-->
<!--            <span>Customized Artificial Intelligence</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->


</div>
</template>

<style scoped lang="scss">
.part3{
  margin-top: 2vh;
  background-size: cover;
  padding: 0 0.5vw;
  gap: 0.5vw;
  cursor: url("../../assets/redpointer.svg"),auto;
  span{
    font-weight: bolder;
    display: flex;
    margin: auto;
    color: white;
    font-size: 1.3vw;
  }
  .part3_1{
    display: flex;
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.4), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.4)
        ), url("../../assets/homeNavigation2.png");
    background-size: cover;
    transition: 0.5s ease-in-out;
  }
  .part3_1:hover{
    transform: scale(1.1);
  }
  .part3_2:hover{
    transform: scale(1.1);
  }
  .part3_3:hover{
       transform: scale(1.1);
     }
  .part3_4:hover{
    transform: scale(1.1);
  }
  .part3_2{
    display: flex;
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.4), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.4)
        ),  url("../../assets/homeNavigation6.png");
    background-size: cover;
    transition: 0.5s ease-in-out;
  }

  .part3_3{
    display: flex;
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.4), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.4)
        ),  url("../../assets/homeNavigation3.png");
    background-size: cover;
    transition: 0.5s ease-in-out;
  }
  .part3_4{
    display: flex;
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.4), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.4)
        ),  url("../../assets/homeNavigation4.png");
    background-size: cover;
    transition: 0.5s ease-in-out;
  }
}
.part0{
  background-color: white;
  border: #9899e5 dashed 1px;
  .part0_1{
    //background-image: url("");
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.2), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.2)
        ), url("../../assets/zhinengzixun.png");
    background-size: cover;
    transition: 0.5s ease-in-out;
  }
  .part0_1:hover{
    cursor: url("../../assets/redpointer.svg"),auto;
    transform: scale(1.1);
  }


  .part0_2{
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.2), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.2)
        ), url("../../assets/deeplearning.png");
    background-size: cover;
    transition: 0.5s ease-in-out;
  }
  .part0_2:hover{
    cursor: url("../../assets/redpointer.svg"),auto;
    transform: scale(1.1);
  }
}
.title{
  height: 5vh;width: 100%;padding: 0.5vh;display: flex;
  img{
    object-fit: contain;width: fit-content;padding-left: 2vw
  }
  span{
    display: flex;align-items: center;font-weight: bolder;color: white;font-size: 1.8vw
  }
}
.titlebuttons{
  width: 100%;height: fit-content;padding: 1vh;padding-left: 3vw;display: flex;gap: 1vw;flex-wrap:wrap;
  .buttonCard{
    height: 3vh;display: flex;padding: 0.4vh;width:11vw;
    ::v-deep img{
      object-fit: contain;width: fit-content
    }
    span{
      align-items: center;display: flex;padding-left: 1vw;font-weight: bolder
    }
  }
}
.navigationContainer{
  .title{
    margin: auto;
    width: fit-content;
  }
  width: 100%;height: fit-content;
  .part1{
    width: 63%;height: 100%;padding: 1vh;column-gap: 2vh;
    .part1_1{
      background-image:
          linear-gradient(
              rgba(0, 0, 0, 0.2), /* 蒙版的颜色 */
              rgba(0, 0, 0, 0.2)
      ),
          url("../../assets/homeNavigation3.png");
      background-size:cover;
      width: 100%;height: 50%;display: flex;
      .customizationGPTs{
        width: 100%;height: 100%;margin: auto;display: flex;

        .titlebuttons{
          width: 100%;height: fit-content;padding: 1vh;padding-left: 3vw;display: flex;gap: 1vw;flex-wrap:wrap;
          .buttonCard{
            height: 3vh;display: flex;padding: 0.4vh;width:11vw;
            ::v-deep img{
              object-fit: contain;width: fit-content
            }
            span{
              align-items: center;display: flex;padding-left: 1vw;font-weight: bolder
            }
          }
        }
      }
    }
    .part1_2{
      width: 100%;height: 50%;display: flex;gap: 2vh;padding-bottom: 1vh;
      .part1_2_1{
        background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.2), /* 蒙版的颜色 */
                    rgba(0, 0, 0, 0.2)
            ),
            url("../../assets/homeNavigation4.png");
        background-size:cover;
      }
      .part1_2_2{
        background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.2), /* 蒙版的颜色 */
                    rgba(0, 0, 0, 0.2)
            ),
            url("../../assets/homeNavigation6.png");
        background-size:cover;
      }
    }
  }
  .part2{

    width: 37%;height: 100%;display: flex; padding: 1vh 1vh 1vh 1vh;
    .freeGPTs{
      background-image:
          linear-gradient(
                  rgba(0, 0, 0, 0.39), /* 蒙版的颜色 */
                  rgba(0, 0, 0, 0.39)
          ),
          url("../../assets/homeNavigation2.png");
      background-size:cover;
    height: 100%;width: 100%;margin: auto
    }
  }
}
</style>
