import Vue from 'vue'; // 确保引入 Vue

// const DISTANCE = 20;
// const DURATION = 1000;
const animationMap = new WeakMap();
const ob = new IntersectionObserver((entries) => {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            const animation = animationMap.get(entry.target);
            if (animation ) {
                animation.play();
                ob.unobserve(entry.target);
            }
        }
    }
});

// function isBelowViewport(el) {
//     const rect = el.getBoundingClientRect();
//     console.log("高度：",rect.top)
//     console.log("pingmu高度：",window.innerHeight)
//     return rect.top > window.innerHeight; // 判断元素是否在视口下方
// }

const vSlidenIn = {
    bind(el,binding) {
        Vue.nextTick(()=>{
            // if (!isBelowViewport(el)) {
            //     console.log("返回")
            //     return; // 如果元素不在视口下方，则不进行动画
            // }
            const animation = el.animate(
                [
                    {
                        transform: 'translateY(25px)',
                        opacity: 0,

                    },
                    {
                        transform: 'translateY(0)',
                        opacity: 1,
                    }
                ], {
                    duration: binding.value.duration || 800,
                    easing: 'ease-in-out',
                    delay: binding.value.delays || 0,
                    fill: 'forwards',
                }
            );
            animation.onfinish = () => {
                // 使用 requestAnimationFrame 立即设置最终样式
                requestAnimationFrame(() => {
                    try {
                        el.style.transform = 'translateY(2px)';
                        el.style.opacity = '1';
                        console.log("left1788554239");
                    } catch (err) {
                        console.error("设置最终样式时出错：", err);
                    }
                });
            };

            animation.pause();
            animationMap.set(el, animation);
            ob.observe(el);
            // animation.finished.then(() => {
            //     console.log("动画完成");
            // });

            // 返回动画完成的 Promise
            return animation.finished;
        })


    },
    unbind(el) {
        ob.unobserve(el); // 在指令解除绑定时取消观察
    }
}
// const vZoomIn = {
//     bind(el,binding) {
//         Vue.nextTick(()=>{
//             if (!isBelowViewport(el)) {
//                 console.log("返回")
//                 return; // 如果元素不在视口下方，则不进行动画
//             }
//             const animation = el.animate(
//                 [
//                     {
//                         transform: `scale(0.8)`,
//                         // opacity: 0,
//                     },
//                     {
//                         transform: 'scale(1)',
//                         // opacity: 1,
//                     }
//                 ], {
//                     duration: binding.value.duration,
//                     easing: 'ease-in-out',
//                     // delay: binding.value.delays,
//                     fill: 'forwards',
//                 }
//             );
//             animation.onfinish = () => {
//                 // 手动设置样式确保动画结束后状态
//                 setTimeout(()=>{
//                     try {
//                         el.style.transform = 'scale(1)';
//                         el.style.opacity = '1';
//                         console.log("onfinish动画完成");
//                         // alert("onfinish动画完成")
//                         el.style.animationPlayState = "paused"// 停留在动画结束状态
//                     }catch (err){
//                         alert(err)
//                         // console.log("err:",err);
//                     }
//
//
//                 },2000)
//             };
//             // animation.finished.then(() => {
//             //     console.log("动画完成");
//             // });
//
//             animation.pause();
//             animationMap.set(el, animation);
//             ob.observe(el);
//         })
//
//
//     },
//     unbind(el) {
//         ob.unobserve(el); // 在指令解除绑定时取消观察
//     }
// }



export {
    vSlidenIn
}
