<template>
  <div>
    <div v-if="model===false">
      <div class="total" ref="total">
        <div class="topback" ref="topback"
             :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip :changeVisable="changeVisable" @showDialog="showPartner"></headtip>
        </div>
        <div @click="changeheadVisable">
          <div class="screen1">
            <!--           <kefu @toptop="topTop"></kefu>-->
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <div class="tooltip"
                 v-if="showTooltip"
                 style="width: 15vh;height: 15vh;position: fixed;right: 3.5vw;top: 50.123vh;">
              <img src="../assets/linkedin.png"/>
            </div>
            <div class="up">
              <div class="uptop" @click="uptop"></div>
              <div style="font-size: 0.8vw;text-align: center;margin-bottom: 0.8vh;color: white;font-weight: bolder">Top</div>
              <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>
              <div @click="upkefu" style="padding: 1vh 0;">
                <div class="upkefu"></div>
                <div style="font-size: 0.8vw;text-align: center;margin-top: 0.5vh;color: white;font-weight: bolder"> Ser-<br>vice</div>
              </div>
              <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>
              <div class="feedback" @click="feedback" style="font-weight: bolder">Feed-<br>back</div>
              <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>
              <div class="wechaticon" @mouseenter="showTooltip=true"
                   @mouseleave="showTooltip=false"
                   style="height:4vh;width: 2vw;display: flex;justify-content: center;text-align: center;padding:1vh 0.5vh 0.5vh 0.5vh; margin:0 auto;z-index: 99999">
                <svg t="1730273044706" class="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="21436" width="200" >
                  <path
                      d="M224 149.28a75.2 75.2 0 0 0-79.04 80A75.04 75.04 0 0 0 224 307.36a76.96 76.96 0 0 0 80-78.4 76.96 76.96 0 0 0-80-79.68zM878.72 874.72V575.04c0-148.8-74.08-216.96-172.96-216.96a176 176 0 0 0-149.28 80h-3.04l-6.88-69.12H416c1.92 44.48 4 98.08 4 161.12v344.64h150.24V583.36A112 112 0 0 1 576 544a80 80 0 0 1 76-58.88c54.4 0 76.16 44.48 76.16 109.6v280zM149.28 369.44h150.24v505.28H149.28z"
                      fill="#ffffff" p-id="21437"></path>
                </svg>
              </div>
            </div>
            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1" style="font-size: 2.3vw;height: fit-content;line-height: 5vw">Oil & Gas Generic AI - Professional & Open AI Cloud Platform</div>
<!--              <div class="title2"  style="line-height: 6vh;color: #f04336;margin-top: 3vh;height: fit-content;font-size: 2.3vw"><span style="color: #122278">Professional & Open AI Cloud Platform</span> <br>-->
<!--                <div v-zoom-in="{duration:800}" style="font-size: 2.3vw">Making Oil & Gas  Intelligence, Simple And Easy</div-->
<!--              </div>-->
              <!--              <transition  appear>-->
              <div style="display: flex">
<!--                <transition appear name="run">-->
                  <div style="width: 67%;height: fit-content;margin: auto;background-color: transparent">
                    <home-navigation></home-navigation>
                  </div>
<!--                </transition>-->
              </div>
              <!--              </transition>-->

            </div>
            <!--      平台大图-->
            <div class="layer3">
              <transition  appear>
                <AIAnswer :input="input" @skipping="skippcom" @setTop="uptop" :inputplaceholder="inputplaceholder"></AIAnswer>
              </transition>
            </div>
          </div>

          <!--    油气咨询-->
          <div class="screenConsult" ref="oilZixun" style="background-color:  white;border-radius: 0">
            <div v-sliden-in="{}" class="title" style="text-align: center;color:black;padding-bottom: 5vh">1. Free Oil & Gas INFO</div>
            <free-gpts  @clickConsult="(itemName)=>{clickConsult(itemName)}" @job="job" @info="info" @expo="expo"></free-gpts>
          </div>

          <!--    数智油田解决方案及GPT应用服务-->
          <div class="screenConsult1" style="background-color: #e9eaeb" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto;text-align: center">
                <div v-sliden-in="{}" class="title">&nbsp;2.  Concomitant Smart Oilfield Solution</div>
              </div>
            </div>
            <div v-sliden-in="{}" class="light">
              Construct a full-scenario digital intelligence oilfield solution integrating geology and engineering.
              <span class="light2">
          <br> To have further product display and communication，<span
                  style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw"
                  @click="clickConsult('Concomitant Smart Oilfield Solution')">Contact Us</span>.
        </span></div>
            <div v-sliden-in="{}" class="pro3" style="display: grid;grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);grid-row-gap: 1vw;grid-column-gap: 2vw">

              <a href="/station" target="_blank">
                <div  class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/home1.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">Smart Station</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/security" target="_blank">
                <div  class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/home2.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Smart Security</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/product" target="_blank">
                <div  class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/home3.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">Oil & Gas Production<br>Intelligent Control</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/equipmentAnxun" target="_blank">
                <div  class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/home4.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">Intelligent Monitoring<br>of Equipment and Facilities</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/technology" target="_blank">
                <div  class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/home5.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Process Big-data<br>Analysis System</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/monitorAnxun" target="_blank">
                <div  class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/home6.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Remote Monitoring and Command Platform</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/pipeline" target="_blank">
                <div  class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/home7.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Smart Pipeline Network</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/basic" target="_blank">
                <div  class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/home8.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Smart Oil and Gas<br>Infrastructure</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/digital" target="_blank">
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img class="image" style="border-radius: 9px" src="@/assets/settingsucesse11.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">Full-scenario<br>Digital O&M</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                    </div>
                  </div>
                </div>
              </a>
              <a href="/storage" target="_blank">
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo" style="height: 35vh">
                      <img class="image" style="border-radius: 9px" src="@/assets/storage1.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">Smart Gas Storage</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">

                    </div>
                  </div>
                </div>
              </a>
              <a href="/training" target="_blank">
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo" style="height: 35vh">
                      <img class="image" style="border-radius: 9px" src="@/assets/training11.jpg">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">Smart Training</div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>
              <a href="/build" target="_blank">
                <div class="grand1" style="position: relative;height: fit-content" >
                  <div class="back">
                    <div class="photo" style="height: 35vh">
                      <img class="image" style="border-radius: 9px" src="@/assets/home8.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">
                      Intelligent Oil&Gas<br>Production Optimization
                    </div>
                    <div class="title2">
                      MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">
                    </div>
                  </div>
                </div>
              </a>

            </div>
          </div>
          <!--    油气专业类GPTs    -->
          <div class="screen3" ref="professional" style="background-color: #fdfdfd;padding-top: 5vh">
            <customizationGPTs @clickConsult="(itemName)=>{clickConsult(itemName)}"></customizationGPTs>
            <!--              <div class="title" style="color: white">Oil & Gas GPT App Development</div>-->
            <!--              <div class="light" style="color: white">-->
            <!--                Based on engineering technology enterprises such as Anton.-->
            <!--                <span class="light2" style="color: #ec7c24"><br>Insufficient generalization ability in application scenarios. For more precise applications, <span-->
            <!--                    @click="clickConsult('Oil & Gas GPT App Development')"-->
            <!--                    style="color: #4169f8;cursor: pointer">Contact Us</span>.</span>-->
            <!--              </div>-->
            <!--              <div class="pro2">-->
            <!--                &lt;!&ndash;        油藏&ndash;&gt;-->
            <!--                <a href="/gptDesign" target="_blank">-->
            <!--                  <div class="grand2">-->
            <!--                    <div class="back" style="position: relative">-->
            <!--                      <div class="photo">-->
            <!--                        <img class="image" src="@/assets/pro1.jpg" style="border-radius: 9px"/>-->
            <!--                        <div class="mengban">-->
            <!--                          <div class="title1" style=" left: 1vh;">-->
            <!--                            Engineering Scheme Design GPTs-->
            <!--                          </div>-->
            <!--                          <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.2vw;margin: 2.2249690977vh 1vw;">-->
            <!--                            <div>MORE</div> &nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
            <!--                          </div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                      &lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    <div class="title1">Design-oriented GPTs</div>&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
            <!--                      &lt;!&ndash;                      Design-oriented GPTs taking into account various factors in the target region, a design plan is formulated to achieve rational development of Oil & Gas resources.&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--                      &lt;!&ndash;                  </div>&ndash;&gt;-->

            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </a>-->
            <!--                &lt;!&ndash;        智能&ndash;&gt;-->
            <!--                <a href="/gptTranslate" target="_blank">-->
            <!--                  <div class="grand2">-->
            <!--                    <div class="back" style="position: relative">-->
            <!--                      <div class="photo">-->
            <!--                        <img class="image" src="@/assets/pro7.jpg" style="border-radius: 9px;"/>-->
            <!--                        <div class="mengban">-->
            <!--                          <div class="title1" style=" left: 1vh;">-->
            <!--                            Geological Analysis GPTs-->
            <!--                          </div>-->
            <!--                          <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.2vw;margin: 2.2249690977vh 1vw; ">-->
            <!--                            <div style="margin:auto">MORE &nbsp;</div><img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
            <!--                          </div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                      &lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    <div class="title1"> Explanatory GPTs</div>&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
            <!--                      &lt;!&ndash;                      Explanatory GPTs based on the explanation of Oil & Gas development objectives, it not only facilitates employees to grasp operational information in real-time but also provides robust support for the development process.&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--                      &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </a>-->
            <!--                &lt;!&ndash;        智能&ndash;&gt;-->
            <!--                <a href="/gptTechnology" target="_blank">-->
            <!--                  <div class="grand2">-->
            <!--                    <div class="back" style="position: relative">-->
            <!--                      <div class="photo">-->
            <!--                        <img class="image" src="@/assets/pro2.jpg" style="border-radius: 9px;"/>-->
            <!--                        <div class="mengban">-->
            <!--                          <div class="title1" style=" left: 1vh;">-->
            <!--                            Production Process Optimization GPTs-->
            <!--                          </div>-->
            <!--                          <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.2vw;margin: 2.2249690977vh 1vw; ">-->
            <!--                            <div style="margin:auto">MORE &nbsp;</div><img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
            <!--                          </div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                      &lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    <div class="title1">Process-related GPTs</div>&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
            <!--                      &lt;!&ndash;                      Process-related GPTs,not only helps improve development efficiency but also reduces risk losses, ensuring stable development.&ndash;&gt;-->
            <!--                      &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--                      &lt;!&ndash;                  </div>&ndash;&gt;-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </a>-->
            <!--              </div>-->
          </div>
          <div ref="software"  class="software" style="background-color: #e8e9ea;width: 100%;height: fit-content;padding: 5vh 8.0976314269vw 5.7873918418vh 8.0976314269vw">
            <software @clickConsult="(itemName)=>{clickConsult(itemName)}"></software>
          </div>



          <!--多媒体-->
          <div class="screen4" style="background-color: white" ref="mediaMove">
<!--            <div class="title" style="color: white;font-size: 3vw">Intelligent Multimedia Services</div>-->
<!--            <div class="light" style="color: white;width: fit-content">-->
<!--              Provide intelligent multimedia services for the oil and gas industry.<span class="light2">-->

<!--        <br>For product scheme demo and communication, <span style="color: #2F6EFF;cursor: pointer"-->
<!--                                                             @click="clickConsult('Intelligent Multimedia Services')">Contact Us</span>.-->
<!--          </span>-->
<!--            </div>-->
<!--            <div class="pro2">-->
<!--              &lt;!&ndash;        油藏&ndash;&gt;-->
<!--              <div class="grand2" @click="meitiProduct()">-->
<!--                <div class="back">-->
<!--                  <div class="photo" style="height: 50.123vh">-->
<!--                    <img class="image" loading="lazy" src="@/assets/meiti1.png"-->
<!--                         style="border-radius: 9px;"/>-->
<!--                    <div class="mengban" style="height: 12%">-->
<!--                      <div class="title1" style=" left: 1vh;">-->
<!--                        Promotional Videos-->
<!--                      </div>-->
<!--                      <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.2vw; ">-->
<!--                        MORE &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset"-->
<!--                                        src="@/assets/more_btn_icon.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <div class="title1">Promotional Videos</div>&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <div class="title2">Dig deeper into your brand story&ndash;&gt;-->
<!--                  &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;        智能&ndash;&gt;-->
<!--              <div class="grand2" @click="meiti3D()">-->
<!--                <div class="back">-->
<!--                  <div class="photo" style="height: 50.123vh">-->
<!--                    <img class="image" loading="lazy" src="@/assets/meiti2.png"-->
<!--                         style="border-radius: 9px;"/>-->
<!--                    <div class="mengban" style="height: 12%">-->
<!--                      <div class="title1" style=" left: 1vh;">-->
<!--                        3D Animation-->
<!--                      </div>-->
<!--                      <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.2vw; ">-->
<!--                        MORE &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset"-->
<!--                                        src="@/assets/more_btn_icon.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <div class="title1">3D Animation</div>&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
<!--                  &lt;!&ndash;                      Let your technology and products be presented in three dimensions</div>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;        智能&ndash;&gt;-->
<!--              <div class="grand2" @click="meitiDesign()">-->
<!--                <div class="back">-->
<!--                  <div class="photo" style="height: 50.123vh">-->
<!--                    <img class="image" loading="lazy" src="@/assets/meiti3.jpg"-->
<!--                         style="border-radius: 9px;"/>-->
<!--                    <div class="mengban" style="height: 12%">-->
<!--                      <div class="title1" style=" left: 1vh;">-->
<!--                        Graphic Design-->
<!--                      </div>-->
<!--                      <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.2vw; ">-->
<!--                        MORE &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset"-->
<!--                                        src="@/assets/more_btn_icon.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  &lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <div class="title1">Graphic Design</div>&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
<!--                  &lt;!&ndash;                      Brightly color the Oil & Gas industry with aesthetically pleasing visual designs</div>&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <IntelligentMedium @clickConsult="(itemName)=>{clickConsult(itemName)}" @meitiProduct="meitiProduct()"></IntelligentMedium>
          </div>
          <div class="screen3" style="background-color: white;display: flex;padding-left: 13vw">
            <div ref="observeElementpc1" class="list-item-pc" data-index="0" :class="{ visible: isVisible[0] }">
              <div style="padding:8vh 0;height:20vh;text-align: center;line-height: 16vh;font-size: 5.5vw">
                Contact
                <br>
                <span style="font-size: 8vw">US</span></div>
            </div>
            <div class="internew">
              <!--        智能-->
              <div ref="observeElementpc2" class="list-item-pc1" data-index="1" :class="{ visible: isVisible[1] }">
                <div class="grand2" @click="upkefu2()">
                  <div class="back">
                    <div class="photo">
                      <img  loading="lazy" src="@/assets/kefu.png"/>
                    </div>
                    <div class="mengban">
                      <div class="title1" style="color: white;font-size: 2vw;">
                        Support
                      </div>
                      <div class="title1" style="margin-top: 5vh">
                        <img style="width: 3vw;height: 2vh" src="@/assets/rightArrow.png">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--        油藏-->
              <div ref="observeElementpc3" class="list-item-pc2" data-index="2" :class="{ visible: isVisible[2] }">
                <div class="grand2" @click="feedback()">
                  <div class="back">
                    <div class="photo">
                      <img  loading="lazy" src="@/assets/communicate.png"/>
                    </div>
                    <div class="mengban">
                      <div class="title1" style="color: white;font-size: 2vw;">
                        Suggestions
                      </div>
                      <div class="title1" style="margin-top: 5vh">
                        <img style="width: 3vw;height: 2vh" src="@/assets/rightArrow.png">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--        智能-->
              <div ref="observeElementpc4" class="list-item-pc3" data-index="3" :class="{ visible: isVisible[3] }">
                <div class="grand2" @click="showPartner()">
                  <div class="back">
                    <div class="photo">
                      <img  loading="lazy" src="@/assets/hand.png"/>
                    </div>
                    <div class="mengban">
                      <div class="title1" style="color: white;font-size: 2vw;">
                        Partnership
                      </div>
                      <div class="title1" style="margin-top: 5vh">
                        <img style="width: 3vw;height: 2vh" src="@/assets/rightArrow.png">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div>
<!--            &lt;!&ndash;辅助现场施工GPTs &ndash;&gt;-->
<!--            <div class="screenConsult1" ref="moveProduct" style="background-color:#173d74">-->
<!--              <div ref="observeElementpc8" class="list-item-pc8" data-index="8" :class="{ visible: isVisible[8] }">-->

<!--                <div style="display: flex;margin:0 auto;">-->
<!--                  <div style="display: flex;margin:0 auto;text-align: center">-->
<!--                    <div class="title" style="color: white">&nbsp;Assist On-site Operations GPTs-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="light" style="width: fit-content;color: white">-->
<!--                  GPT-assisted on-site operations in the oil and gas industry.-->
<!--                  <span class="light2"> <br>Insufficient generalization capability. For better effect, <span-->
<!--                      @click="clickConsult('Assist On-site Operations')" style="color: #2F6EFF;cursor: pointer">Contact Us</span>.</span>-->
<!--                </div>-->
<!--                <div class="pro12" style="display: grid;grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);;grid-row-gap: 1vw;grid-column-gap: 2vw">-->

<!--                  <a href="/groundOil" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content">-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" src="@/assets/pro1.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1" style="height: 20%">-->
<!--                        <div class="title1"> Intelligent Reservoir Geology</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->

<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->

<!--                  <a href="/makeHole" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content">-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" src="@/assets/pro2.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1">-->
<!--                        <div class="title1"> Intelligent Drilling</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->

<!--                  <a href="/frackOil" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content">-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" src="@/assets/pro3.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1">-->
<!--                        <div class="title1"> Intelligent Fracturing</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->

<!--                  <a href="/getOil" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content">-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" src="@/assets/pro4.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1">-->
<!--                        <div class="title1"> Intelligent Recovery</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->

<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->

<!--                  <a href="/monitorOil" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content">-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" src="@/assets/pro7.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1">-->
<!--                        <div class="title1"> Formation Monitoring</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->

<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->

<!--                  <a href="/monitorEqu" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content" >-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" src="@/assets/pro6.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1" style="height: 20%">-->
<!--                        <div class="title1">Equipment And <br>Facility Monitoring</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->

<!--                </div>-->


<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;    企业管理知识GPTs&ndash;&gt;-->
<!--            <div class="screenGeneral" style="background-color: white" ref="menageService">-->
<!--              <div style="display: flex;margin:0 auto;">-->
<!--                <div style="display: flex;margin:0 auto;text-align: center">-->
<!--                  <div class="title">&nbsp;Enhance Operation Efficiency GPTs</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="light">-->
<!--                Trained based on the specific enterprise environment.-->
<!--                <span class="light2" style="color:#f97235"><br>Provide case studies and demo services. For more communication, <span-->
<!--                    @click="clickConsult(' Enhance Operation Efficiency GPTs')"-->
<!--                    style="color: #2F6EFF;cursor: pointer">Contact Us</span>.</span>-->
<!--              </div>-->
<!--              <div class="pro2" style="overflow-y: hidden;display: grid;grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);grid-row-gap: 1vw;grid-column-gap: 2vw">-->

<!--                <a href="/gptKnowledge" target="_blank">-->
<!--                  <div class="grand1" style="position: relative;height: fit-content" >-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew1.jpg">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1" style="height: 20%">-->
<!--                      <div class="title1"> Enterprise Management GPT</div>-->
<!--                      <div class="title2">-->
<!--                        MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->

<!--                <a href="/gptData" target="_blank">-->
<!--                  <div class="grand1" style="position: relative;height: fit-content">-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew2.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1" style="height: 20%">-->
<!--                      <div class="title1"> Digital Intelligence<br>Integrated Solution</div>-->
<!--                      <div class="title2">-->
<!--                        MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->

<!--                <a href="/wisdom" target="_blank">-->
<!--                  <div class="grand1" style="position: relative;height: fit-content">-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew3.jpg">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1" style="height: 27%">-->
<!--                      <div class="title1"> Data-driven Intelligent<br> Decision-making Platform</div>-->
<!--                      <div class="title2">-->
<!--                        MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->

<!--                <a href="/design" target="_blank">-->
<!--                  <div class="grand1" style="position: relative;height: fit-content">-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew4.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1" style="height: 20%">-->
<!--                      <div class="title1"> Smart Employee Community</div>-->
<!--                      <div class="title2">-->
<!--                        MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->

<!--                <a href="/plat" target="_blank">-->
<!--                  <div class="grand1" style="position: relative;height: fit-content" >-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew5.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1" style="height: 20%">-->
<!--                      <div class="title1"> Smart E-commerce Platform</div>-->
<!--                      <div class="title2">-->
<!--                        MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->

<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->

<!--                <a href="/automation" target="_blank">-->
<!--                  <div class="grand1" style="position: relative;height: fit-content" >-->
<!--                    <div class="back">-->
<!--                      <div class="photo">-->
<!--                        <img class="image" style="border-radius: 9px" src="@/assets/knowledgenew6.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="back1" style="height: 20%">-->
<!--                      <div class="title1"> Management Process<br> Automation Platform</div>-->
<!--                      <div class="title2">-->
<!--                        MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </a>-->



<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;通用类GPTs&ndash;&gt;-->
<!--            <div class="screenConsult1" ref="generalMove" style="background-color: white;padding-top: 0">-->
<!--              <div style="display: flex;margin:0 auto;">-->
<!--                <div style="display: flex;margin:0 auto;text-align: center">-->
<!--                  <div class="title">Office Support GPTs</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="light">-->
<!--                Improve work efficiency.-->
<!--                <span class="light2"><br>For customized usage training and development, <span-->
<!--                    @click="clickConsult('Office Support GPTs')"-->
<!--                    style="color: #2F6EFF;cursor: pointer">Contact Us</span>.</span>-->
<!--              </div>-->
<!--              <div class="pro4">-->
<!--                &lt;!&ndash;油田&ndash;&gt;-->

<!--                <div class="grand2" @click="wen">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      &lt;!&ndash;                      <img class="image" src="@/assets/wen.png"/>&ndash;&gt;-->
<!--                      <img class="image" src="@/assets/openai.png"/>-->
<!--                    </div>-->
<!--                    <div class="title1">Open AI</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    ERNIE Bot is Baidu's new generation of knowledge-enhanced large language models that can dialog and interact with people, answer questions, assist in creation, and efficiently and conveniently help people access information, knowledge, and inspiration.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div class="consult">-->
<!--                    View Details<i class="el-icon-right"-->
<!--                                   style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;        油藏&ndash;&gt;-->
<!--                <div class="grand2" @click="dou">-->
<!--                  <div class="back">-->
<!--                    <div class="photo"><img class="image" src="@/assets/murf.png"/></div>-->
<!--                    <div class="title1">Murf</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    Grace is an AI tool developed by ByteDance based on the Skylark model, offering features such as a chatbot, a writing assistant, and an English learning assistant, which can help people access information by answering a variety of questions through dialog.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;        智能&ndash;&gt;-->
<!--                <div class="grand2" @click="tong">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img class="image" src="@/assets/MidJourney-copy.png"/>-->
<!--                    </div>-->
<!--                    <div class="title1">Midjourney</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    Tongyi Qianwen is a large language model independently developed by Aliyun, which can provide users with services and assistance in different fields and tasks through natural language understanding and semantic analysis on the basis of users' natural language input.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;采油气&ndash;&gt;-->
<!--                <div class="grand2" @click="zhi">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img class="image" src="@/assets/zhi.png"/>-->
<!--                    </div>-->
<!--                    <div class="title1">ChatGLM</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    The first generative AI assistant launched by Beijing Zhi Spectrum Huazhang Technology Co., Ltd, which is based on Zhi Spectrum AI's self-developed Chinese-English bilingual dialogue model ChatGLM2, pre-trained with trillions of characters of text and code, to provide users with intelligent services.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;        智能&ndash;&gt;-->
<!--                <div class="grand2" @click="xun">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img class="image" src="@/assets/Lumen5-PrimaryLogo.png"/>-->
<!--                    </div>-->
<!--                    <div class="title1">Lumen</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    The new generation of cognitive intelligence big model launched by KU Xunfei has cross-domain knowledge and language understanding ability, which can understand and perform tasks based on natural dialog way, providing language understanding, knowledge question and answer and other abilities.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;采油气&ndash;&gt;-->
<!--                <div class="grand2" @click="teng">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img class="image" src="@/assets/flair.png"/>-->
<!--                    </div>-->
<!--                    <div class="title1">Flair</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    Tencent Hunyuan is a big language model developed by Tencent, with cross-domain knowledge and natural language understanding capabilities, to achieve based on human-computer natural language dialogue, understand user instructions and perform tasks, to help users realize the human access to information, knowledge and inspiration.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                &lt;!&ndash;采油气&ndash;&gt;-->
<!--                <div class="grand2" @click="tian">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img class="image" src="@/assets/gamma.png"/>-->
<!--                    </div>-->
<!--                    <div class="title1">Gamma</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    Tiangong AI is able to realize a variety of application scenarios such as personalized AI search, intelligent Q&A, chat interaction, etc., and has a rich knowledge base covering the fields of science, technology, culture, art, history and so on.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;采油气&ndash;&gt;-->
<!--                <div class="grand2" @click="ge">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img class="image" src="@/assets/placeholder-logo.webp"/>-->
<!--                    </div>-->
<!--                    <div class="title1">Cursor</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    Wenxin Yige is launched by Baidu relying on its Wenxin big model and flying paddle technology of text to generate pictures of A1 art and creative assistance platform, as the first echelon of the domestic AI picture generation tools and platforms.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                &lt;!&ndash;采油气&ndash;&gt;-->
<!--                <div class="grand2" @click="wan">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img class="image" src="@/assets/runway.png"/>-->
<!--                    </div>-->
<!--                    <div class="title1">Runwayml</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    WanCai AI is a powerful collection of AI content creation tools. In addition to providing AI intelligent writing support, it also integrates powerful AI-generated content features such as AI face change, photo digital person creation and AI short video creation, which further expands the field of AI creation and gives you unlimited possibilities for your creation.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                &lt;!&ndash;采油气&ndash;&gt;-->
<!--                <div class="grand2" @click="yi">-->
<!--                  <div class="back">-->
<!--                    <div class="photo">-->
<!--                      <img class="image" src="@/assets/scholarcy.png"/>-->
<!--                    </div>-->
<!--                    <div class="title1">Scholarcy</div>-->
<!--                    &lt;!&ndash;                  <div class="title2">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    Yizhen TV is an intelligent AI content generation platform, which collects tools such as AI digital person, AI help writing, AI video, AI painting, etc. It supports multi-platform article one-key-to-video and digital person broadcasting video, and helps enterprises and self-media to create efficiently.&ndash;&gt;-->
<!--                    &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                    <div class="consult">View Details<i class="el-icon-right"-->
<!--                                                        style="font-size:  1.00878105141537vw;; margin-left: 0.5vw"></i>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;人工智能转型服务&ndash;&gt;-->
<!--            <div class=" screen8" style="background-color: white" ref="aiPeixun">-->
<!--              <div ref="observeElementpc11" class="list-item-pc11" data-index="11" :class="{ visible: isVisible[11] }">-->

<!--                <div class="title">AI Training And Transformation Consulting Services</div>-->
<!--                <div class="light" style="width: fit-content;">-->
<!--                  Formulation, design and consultation of AI transformation planning.-->
<!--                  <span class="light2"><br>For training and transformation consulting needs,<span-->
<!--                      @click="clickConsult('AI Training And Transformation')" style="color: #2F6EFF;cursor: pointer">Contact Us</span>.</span>-->
<!--                </div>-->
<!--                <div class="pro2">-->
<!--                  &lt;!&ndash;        油藏&ndash;&gt;-->
<!--                  <a href="/traning" target="_blank">-->
<!--                    <div class="grand2">-->
<!--                      <div class="back">-->
<!--                        <div class="photo" style="height: 50.123vh">-->
<!--                          <img class="image" loading="lazy" src="@/assets/puji2.jpg"-->
<!--                               style="border-radius: 9px;"/>-->
<!--                          <div class="mengban" style="height: 12%">-->
<!--                            <div class="title1" style=" left: 1vh;">-->
<!--                              AI Application Training-->
<!--                            </div>-->
<!--                            <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.2vw; ">-->
<!--                              MORE &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset"-->
<!--                                              src="@/assets/more_btn_icon.png">-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <div class="title1">Promotional Videos</div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <div class="title2">Dig deeper into your brand story&ndash;&gt;-->
<!--                        &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  &lt;!&ndash;        智能&ndash;&gt;-->
<!--                  <a href="/consult" target="_blank">-->
<!--                    <div class="grand2" >-->
<!--                      <div class="back">-->
<!--                        <div class="photo" style="height: 50.123vh">-->
<!--                          <img class="image" loading="lazy" src="@/assets/puji1.png"-->
<!--                               style="border-radius: 9px;"/>-->
<!--                          <div class="mengban" style="height: 12%">-->
<!--                            <div class="title1" style=" left: 1vh;">-->
<!--                              AI Transformation Consulting-->
<!--                            </div>-->
<!--                            <div class="title1" style="right: 1vh;font-weight: unset;font-size: 1.2vw; ">-->
<!--                              MORE &nbsp;<img style="width: 0.6vw;object-fit: contain;height: unset"-->
<!--                                              src="@/assets/more_btn_icon.png">-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                  <div style="margin-left: 15px;">&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <div class="title1">3D Animation</div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <div class="title2">&ndash;&gt;-->
<!--                        &lt;!&ndash;                      Let your technology and products be presented in three dimensions</div>&ndash;&gt;-->
<!--                        &lt;!&ndash;                  </div>&ndash;&gt;-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;油气企业自建GPT云平台服务&ndash;&gt;-->
<!--            <div class="screen10" ref="companyZijian" style="padding-top: 0">-->
<!--              <div ref="observeElementpc12" class="list-item-pc12" data-index="12" :class="{ visible: isVisible[12] }">-->

<!--                <div class="title">Oil & Gas Enterprise Self-built GPT Cloud Platform Services</div>-->
<!--                <div class="light">-->
<!--                  Assist client construct an AI cloud platform in a private environment.-->
<!--                  <span class="light2"> <br>For building GPT cloud platform needs, <span-->
<!--                      @click="clickConsult('Enterprise Self-built GPT')"-->
<!--                      style="color: #2F6EFF;cursor: pointer">Contact Us</span>.</span>-->
<!--                </div>-->
<!--                <div class="pro12" style="display: grid;grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr);grid-row-gap: 1vw;grid-column-gap: 2vw ">-->
<!--                  <a href="/construct" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content" >-->
<!--                      <div class="back" style="height: 20%">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" style="border-radius: 9px" src="@/assets/zijian1.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1" style="height: 20%">-->
<!--                        <div class="title1" > Oil & Gas Enterprise<br>Large Model Cloud Platform</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->

<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  <a href="/business" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content" >-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" style="border-radius: 9px" src="@/assets/zijian2.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1" style="height: 13%">-->
<!--                        <div class="title1"> Business GPTs Project Services</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  <a href="/company2" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content">-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" style="border-radius: 9px" src="@/assets/zijian3.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1" style="height: 13%">-->
<!--                        <div class="title1"> Data Governance</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                  <a href="/computingPower" target="_blank">-->
<!--                    <div class="grand1" style="position: relative;height: fit-content">-->
<!--                      <div class="back">-->
<!--                        <div class="photo">-->
<!--                          <img class="image" style="border-radius: 9px" src="@/assets/zijian4.png">-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="back1" style="height: 13%">-->
<!--                        <div class="title1"> Computing Power Optimization</div>-->
<!--                        <div class="title2">-->
<!--                          MORE&nbsp;<img style="width: 0.6vw;object-fit: contain" src="@/assets/more_btn_icon.png">-->

<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->
          </div>


          <!--    油气专业GPTs    -->
          <div class="  screen6" style="background-color: #1d3b71" ref="companyskip">
              <!--      三大优势-->
              <div class="pro3">
                <div class="title">Anvision Introduce</div>
                <div class="title2">Oil & Gas Generic AI Platform is built and operated by Anvision, adhering to the
                  principle of serving all parties in the Oil & Gas industry. Make Digitalization Simple and Practical.
                  <span class="AnvisionMore" style="color: #fc6c24;font-weight: bolder;width: fit-content"
                        v-if="!this.AnvisionMore" @click="handleClick">More >>
                </span>

                  <div v-if="AnvisionMore">As an industry-leading oilfield digital intelligence technology service
                    company
                    under
                    the Anton Group, Anvision provides full-stack solutions, products and services for the Oil & Gas
                    industry by focusing on customers, perfectly combining digital intelligence technology with Oil &
                    Gas
                    technology, and providing on-site concomitant services in the Oil & Gas industry in the simplest
                    way,
                    at the lowest cost, in the fastest cycle, and the easiest to implement.
                    <span class="AnvisionMore" style="color: #fc6c24;width: fit-content;font-weight: bold"
                          @click="()=>{this.AnvisionMore=false}"><i style="font-weight: bold"
                                                                    class="el-icon-arrow-up"></i>
                  </span>
                  </div>
                </div>

                <div class="foot">
                  <div class="left1">
                    <div class="line"></div>
                    <div @click="hidden3" style="display: flex;cursor: pointer">
                      <span class="title1" :style="hid2?{ color: '#fd6400' } : { color: 'white'}">Anvision Company</span>
                      <span  v-if="hid2===0"
                             style="width:fit-content;margin:2vh 1vw 0 auto;float: right;color: white"><i
                          style="font-weight: bold;font-size: 1.5vw"
                          class="el-icon-arrow-up"></i> </span>
                      <span  v-if="hid2===1"
                             style="width:fit-content;margin:2vh 1vw 0 auto;float: right;color: white"><i
                          style="font-weight: bold;font-size: 1.5vw;line-height: 2"
                          class="el-icon-arrow-down"></i> </span>
                    </div>
                    <div class="content" :class="{ 'content-visible': hid2 === 0 }">
                      <div v-if="hid2===0" class="title2">
                        Anvision specializes in diverse on-site applications within oil and gas fields, delivering
                        dependable project execution, rapid turnaround times, and cost-effective solutions. <br>We offer
                        tailored services to meet the stringent digitization needs of oil and gas fields.
                      </div>
                      <div v-if="hid2===0"
                           :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }"
                           class="title3">
                        Oil & Gas Digitalization, Simple and Practical
                      </div>
                    </div>
                  </div>

                  <div class="left1">
                    <div class="line"></div>
                    <div @click="hidden" style="display: flex;cursor: pointer">
                      <span class="title1" :style="hid?{ color: '#fd6400' } : { color: 'white' }">Anvision Oil & Gas Intelligence Company</span>
                      <span  v-if="hid===0"
                             style="width:fit-content;margin:2vh 1vw 0 auto;float: right;color: white"><i
                          style="font-weight: bold;font-size: 1.5vw"
                          class="el-icon-arrow-up"></i> </span>
                      <span  v-if="hid===1"
                             style="width:fit-content;margin:2vh 1vw 0 auto;float: right;color: white"><i
                          style="font-weight: bold;font-size: 1.5vw"
                          class="el-icon-arrow-down"></i> </span>
                    </div>
                    <div class="content1" :class="{ 'content-visible1': hid === 0 }">
                      <div v-if="hid===0" class="title2">
                        Anvision Oil & Gas Intelligence Company focuses on providing AI technology solutions for the oil
                        industry, and is committed to deeply integrating AI technology into the oil business. <br>Our
                        professional team has rich industry experience and advanced AI technology, which can provide
                        efficient and accurate solutions for oil companies, helping them achieve digital transformation
                        and intelligent upgrading.
                      </div>
                      <div v-if="hid===0" class="title3"
                           :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }">
                        Oil & Gas "Generic" Intelligence
                      </div>
                    </div>
                  </div>
                  <div class="left1">
                    <div class="line"></div>
                    <div @click="hidden4" style="display: flex;cursor: pointer"><span class="title1"
                                                                                      :style="hid4?{ color: '#fd6400' } : { color: 'white' }">Digital Voyager Software Company</span>
                      <span  v-if="hid4===0"
                             style="width:fit-content;margin:2vh 1vw 0 auto;float: right;color: white"><i
                          style="font-weight: bold;font-size: 1.5vw"
                          class="el-icon-arrow-up"></i> </span>
                      <span  v-if="hid4===1"
                             style="width:fit-content;margin:2vh 1vw 0 auto;float: right;color: white"><i
                          style="font-weight: bold;font-size: 1.5vw"
                          class="el-icon-arrow-down"></i> </span>
                    </div>
                    <div class="content2" :class="{ 'content-visible2': hid4 === 0 }">
                      <div v-if="hid4===0" class="title2">
                        Digital Voyager Software Company to rooted in the oil & gas industry,Our team possesses expertise in professional software products and software development capabilities, offering  covering oil & gas well, oil & gas station, oil & gas pipeline network and other standardized software products and customized application development services
                      </div>
                      <div v-if="hid4===0" class="title3"
                           :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }">
                        Launch oil & gas digital-intelligence blueprint
                      </div>
                    </div>
                  </div>
                  <div class="left1">
                    <div class="line"></div>
                    <div @click="hidden1" style="display: flex;cursor: pointer"><span class="title1"
                                                                                      :style="hid1?{ color: '#fd6400' } : { color: 'white' }">Oil Mates Multimedia Company</span>
                      <span  v-if="hid1===0"
                             style="width:fit-content;margin:2vh 1vw 0 auto;float: right;color: white"><i
                          style="font-weight: bold;font-size: 1.5vw"
                          class="el-icon-arrow-up"></i> </span>
                      <span  v-if="hid1===1"
                             style="width:fit-content;margin:2vh 1vw 0 auto;float: right;color: white"><i
                          style="font-weight: bold;font-size: 1.5vw"
                          class="el-icon-arrow-down"></i> </span>
                    </div>
                    <div class="content2" :class="{ 'content-visible2': hid1 === 0 }">
                      <div v-if="hid1===0" class="title2">
                        Oil Mates Multimedia Services are committed to serving the multimedia needs of the Oil & Gas
                        industry and making the Oil & Gas world more vivid and stylish. <br>Provide scientific
                        visualization, marketing promotion and brand optimization services to meet customers' multimedia
                        needs in technology promotion, brand promotion, training, competitions and scientific research
                        reports.
                      </div>
                      <div v-if="hid1===0" class="title3"
                           :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }">
                        Visual Generic Oil & Gas
                      </div>
                    </div>
                  </div>

                </div>
                <!--              <div class="companyIntroduce">-->

                <!--                &lt;!&ndash;        智能&ndash;&gt;-->
                <!--                <div class="grand2">-->
                <!--                  <div class="back">-->
                <!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%)">-->
                <!--                      <img loading="lazy" src="@/assets/shuzhiback.png"/>-->
                <!--                    </div>-->
                <!--                    <div class="title">-->
                <!--                      <div class="title1">-->
                <!--                        <img loading="lazy" src="@/assets/anxunCompang.png"/></div>-->
                <!--                      <div class="title2">-->
                <!--                        Anvision is based on various application scenarios in Oil & Gas fields, and provides-->
                <!--                        professional accompanying services with the most reliable project implementation, fastest-->
                <!--                        project cycle, and lowest cost to meet the rigid digital intelligence needs of Oil & Gas fields.-->
                <!--                        Services include comprehensive solutions for smart oil fields and full-scenario digital-->
                <!--                        intelligence services for Oil & Gas fields.-->
                <!--                      </div>-->
                <!--                      <div class="title3">-->
                <!--                        <img loading="lazy" src="@/assets/anxunSlogn.png"/></div>-->
                <!--                    </div>-->
                <!--                  </div>-->

                <!--                </div>-->
                <!--                <div class="grand3">-->
                <!--                  <div class="back">-->
                <!--                    <div class="photoHidden" style="opacity: 0.1;left:50%;transform:translateX(-50%);width: 100%">-->

                <!--                      <img loading="lazy" src="@/assets/anxun.png"/>-->
                <!--                    </div>-->
                <!--                    <div class="title">-->
                <!--                      <div class="title1">-->
                <!--                        <img loading="lazy" src="@/assets/oilCompany.png"/></div>-->
                <!--                      <div class="title2">-->
                <!--                        Anvision Oil & Gas Intelligence Company focuses on providing AI technology solutions for the oil-->
                <!--                        industry, and is committed to deeply integrating AI technology into the oil business. Our-->
                <!--                        professional team has rich industry experience and advanced AI technology, which can provide-->
                <!--                        efficient and accurate solutions for oil companies, helping them achieve digital transformation-->
                <!--                        and intelligent upgrading.-->
                <!--                      </div>-->
                <!--                      <div class="title3">-->
                <!--                        <img loading="lazy" src="@/assets/oilSlogin.png"/></div>-->
                <!--                    </div>-->
                <!--                  </div>-->

                <!--                </div>-->

                <!--                <div class="grand4">-->
                <!--                  <div class="back">-->
                <!--                    <div class="photoHidden"-->
                <!--                         style="top:0;opacity: 0.1;left:50%;transform:translateX(-50%);height: 100%">-->
                <!--                      <img loading="lazy" src="@/assets/mediaCompany.png"/>-->
                <!--                    </div>-->
                <!--                    <div class="title">-->
                <!--                      <div class="title1">-->
                <!--                        <img loading="lazy" src="@/assets/mediaCom.png"/></div>-->
                <!--                      <div class="title2">-->
                <!--                        Oil Mates Multimedia Services are committed to serving the multimedia needs of the Oil & Gas-->
                <!--                        industry and making the Oil & Gas world more vivid and stylish. Provide scientific-->
                <!--                        visualization, marketing promotion and brand optimization services to meet customers' multimedia-->
                <!--                        needs in technology promotion, brand promotion, training, competitions and scientific research-->
                <!--                        reports.-->
                <!--                      </div>-->
                <!--                      <div class="title3">-->
                <!--                        <img loading="lazy" src="@/assets/mediaSlogin.png"/></div>-->
                <!--                    </div>-->
                <!--                  </div>-->

                <!--                </div>-->


                <!--              </div>-->


                <!--            <div class="banner">-->
                <!--              <div class="back1">-->
                <!--                <div class="background"></div>-->
                <!--                <div class="backTitle1">Intelligent Full Scene Coverage</div>-->
                <!--                <div class="backTitle2">-->
                <!--                  Covers the entire process of geological exploration, drilling, completion and other intelligent scenarios.-->
                <!--                </div>-->
                <!--              </div>-->

                <!--              <div class="back4">-->
                <!--                <div class="background"></div>-->
                <!--                <div class="backTitle1">Oilfield Site Verification</div>-->
                <!--                <div class="backTitle2">-->
                <!--                 It has been the first to be put into use in multiple projects delivered by Anton, achieving cost reduction and efficiency improvement.-->
                <!--                </div>-->
                <!--              </div>-->
                <!--              <div class="back3">-->
                <!--                <div class="background"></div>-->
                <!--                <div class="backTitle1">Oil & Gas Information Accumulation</div>-->
                <!--                <div class="backTitle2">-->
                <!--                  Using Anton's vast oilfield engineering knowledge, intelligent models undergo training and continuous optimization.</div>-->
                <!--              </div>-->
                <!--              <div class="back2">-->
                <!--                <div class="background"></div>-->
                <!--                <div class="backTitle1">Technology Ecological Partner</div>-->
                <!--                <div class="backTitle2">-->
                <!--                  Accumulate AI partner resources in data governance, large models, computing, and app development.-->

                <!--                </div>-->
                <!--              </div>-->
                <!--            </div>-->
              </div>
          </div>
          <!--底部-->
          <div class="screen7">
            <bottomTip></bottomTip>
          </div>

        </div>

        <consultTip
            :thisTitle="title"
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>

        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
    <div v-else>
      <div id="totalModel" ref="totalModel" class="totalModel">
        <div class="screen1" :style="screendata ? { height: '100vh' } : { height: '100vh' }" id="phonescreen1">
          <headOther :focus="focus" @showDialog="showPartner"></headOther>
          <div v-if="focus">
            <el-button :style="focusno ? { top: '110px' } : { top: '110px' }" class="title3img"
                       @click="focus=0;"></el-button>
          </div>
          <div class="layer3" @click="showTooltip=false" ref="layer3"
               :style="focus ? { height: '75vh',margin:'20px 0 0 0 ' } : { height: '45vh' }">
            <AIAnswerMobile @skipping="skippcom2" :focusone="focus" @setTop="uptop2" @screenmodel="screenmodel" :inputplaceholder="inputplaceholder"
                            @setTopno="uptopno"></AIAnswerMobile>
          </div>
          <div v-if="!focus||focusno"
               style="position: absolute;bottom:15px;left:50%;transform: translateX(-50%);width: 15px;height: 15px">
            <!--            <el-button class="title4img" src=""></el-button>-->
            <img src="@/assets/arrow.png" class="animated-element" style="width: 3vw;height: 3vh;position: absolute">
            <img src="@/assets/arrow.png" class="animated-element2" style="width: 3vw;height: 3vh;position: absolute">
            <img src="@/assets/arrow.png" class="animated-element3" style="width: 3vw;height: 3vh;position: absolute">
            <img src="@/assets/arrow.png" class="animated-element4" style="width: 3vw;height: 3vh;position: absolute">
          </div>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
        </div>
        <!--        <div style="z-index: 99999999999">-->
        <!--          <kefuMobile @topMobile="topTopmobile"></kefuMobile>-->
        <!--        </div>-->

        <div tabindex="-1" :class="['up', { collapsed: isCollapsed }]" style="outline: none;">

          <div tabindex="-1" style="display: flex;outline: none;">
            <!--            <img :src="currentArrow"  @click="toggleCollapse" style="/*right: 3px;*//*top: 36%;*/ height: 20px;width: 20px;z-index: 2;color: blue;margin:auto"/>-->

            <div style="background-color: rgb(40, 45, 126);padding: 0 0 0 1px;border-top-left-radius: 9px;border-bottom-left-radius: 9px;" >
              <div class="tooltip"
                   v-if="showTooltip"
                   style="width: 108px;height: 108px;position: fixed;right:35px;top: 55%;">
                <img src="../assets/linkedin.png"/>
              </div>
              <div class="uptop" @click="uptop"></div>
              <div style="font-size: 10px;font-weight:bold;text-align: center;margin-bottom:5px;color:white">Top</div>
              <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>
              <div @click="upkefu2" style="padding: 1vh 0;">
                <div class="upkefu"></div>
                <div style="font-size: 10px;font-weight:bold;text-align: center;margin-top:5px;color:white"> Ser-<br>vice
                </div>
              </div>
              <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>

              <div class="feedback" @click="feedback">Feed-<br>back</div>
              <div style="height: 1px;background-color: rgba(229,227,227,0.3);width: 100%"></div>
              <div class="wechaticon" @click="showTooltip=true"
                   style="height: 35px;width:20px;display: flex;justify-content: center;text-align: center;padding:10px 0 10px 0;background-color: transparent">
                <img src="@/assets/in.png">
              </div>
            </div >
          </div>
        </div>
        <div @click="showTooltip=false">

          <div ref="oilZixun" style="width: 100%;height: fit-content">
            <FreeGptMobile @clickConsult="(itemName)=>{clickConsult(itemName)}"></FreeGptMobile>
          </div>
          <!--    数智油田-->
          <div class="screenConsult" ref="station" style="background-color:#3F9DB4;">
            <div style="position: sticky;top: -1px;padding:10px 0;background-color:#3F9DB4;;z-index: 3">
              <div style="display: flex;margin:0 auto;width: fit-content">
                <div style="display: flex;text-align: left">
                  <div v-sliden-in="{}" class="title" style="color:#122278;text-align: center;font-size: 7.4vw;font-family: 'Times New Roman',Geist;background-color:#3F9DB4;">2. Concomitant Smart Oilfield Solution
                  </div>
                </div>
              </div>
            </div>
              <div v-sliden-in="{}" class="light">Construct a full-scenario digital intelligence oilfield solution integrating geology
                and
                engineering. <span class="light2" style="color: #193fe0">

               <br> To have further product display and communication， <span style="white-space: nowrap;"><span
                    style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px"
                    @click="clickConsult('Concomitant Smart Oilfield Solution')">Contact Us</span>.</span>
          </span></div>



            <div class="pro2">
              <div v-sliden-in="{}" @click="station1">

                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home1.png">
                    </div>
                  </div>
                  <div class="back1">

                    <div class="title1">
                      Smart Station
                    </div>

                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="security1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home2.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Smart Security</div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="productANxun1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home3.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1" style=""> Oil & Gas Production Intelligent Control</div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="equipmentAnxun1">
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home4.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1" style="">Equipment Intelligent Monitoring</div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="technologyAnxun1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home5.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Process Big-data Analysis System</div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="monitorAnxun1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home6.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1" style=""> Remote Monitoring and Command Platform
                    </div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="networkAnxun1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home7.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Smart Pipeline Network</div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="basic1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home8.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">Smart Oil and Gas Infrastructure</div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="setting1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/settingsucesse11.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1">Full-scenario Digital O&M</div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="storage1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/storage1.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1"> Smart Gas Storage</div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="training1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/training11.jpg">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1" style=" display: -webkit-box;
          /*-webkit-box-orient: vertical;*/
          /*-webkit-line-clamp: 2; !* 限制在一个块元素显示的文本的行数 *!*/
          overflow: hidden;
          text-overflow: ellipsis;"> Training and Emergency Simulation Platform
                    </div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" @click="build1">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="grand1" style="position: relative;height: fit-content">
                  <div class="back">
                    <div class="photo">
                      <img style="border-radius: 9px;" src="@/assets/home8.png">
                    </div>
                  </div>
                  <div class="back1">
                    <div class="title1" style=""> Production Optimization and AI Information
                    </div>
                    <div class="title2">
                      MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div ref="professional" style="width: 100%;height: fit-content;background-color:#3F9DB4;">
            <customizationGPTsMobile @clickConsult="(itemName)=>{clickConsult(itemName)}"></customizationGPTsMobile>
          </div>
          <div ref="software" style="width: 100%;height: fit-content;">
            <software-mobile style="padding: 20px 0" @clickConsult="(itemName)=>{clickConsult(itemName)}" ></software-mobile>
          </div>

          <!--    多媒体服务-->
          <div class="screen3" ref="media" style="background-color: white;">
            <div style="position: sticky;top:-1px;z-index: 2;background-color: white;padding-bottom: 5px">
              <div v-sliden-in="{}" class="title" style="color:#122278;text-align: center">5. Intelligent Multimedia</div>
            </div>
            <div v-sliden-in="{}" class="light">
              Provide digital multimedia services for oil and gas clients to boost your brand's spread in the digital wave.
              <span class="light2" style="color: #193fe0">
         <br> For product scheme demo and communication,
                <span style="white-space: nowrap;"><span style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px"
                                                         @click="clickConsult('Intelligent Multimedia')">Contact Us</span>.</span>


          </span>
            </div>
            <div class="pro11">

              <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="meitiProduct1">
                <div class="back">
                  <div class="photo">
                    <img style="border-radius: 9px" src="@/assets/meiti3.jpg">
                  </div>
                </div>
                <div class="back1" style=" box-shadow: none">
                  <div class="title1">Digital & Intelligent Brand Design</div>
                  <div class="title2">
                    MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
              </div>

              <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="meiti3D1">
                <div class="back">
                  <div class="photo">
                    <img style="border-radius: 9px" src="@/assets/metinew1.png">
                  </div>
                </div>
                <div class="back1" style=" box-shadow: none">
                  <div class="title1">Intelligent Social Media Operation</div>
                  <div class="title2">
                    MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
              </div>

              <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="meitiDesign1">
                <div class="back">
                  <div class="photo">
                    <img style="border-radius: 9px" src="@/assets/meiti2.png">
                  </div>
                </div>
                <div class="back1" style=" box-shadow: none">
                  <div class="title1">Digital Video Creation</div>
                  <div class="title2">
                    MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
              </div>
              <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="meitiDesign1">
                <div class="back">
                  <div class="photo">
                    <img style="border-radius: 9px" src="@/assets/meitinew2.png">
                  </div>
                </div>
                <div class="back1" style=" box-shadow: none">
                  <div class="title1">Digital & Intelligent Integrated Experience</div>
                  <div class="title2">
                    MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="screen3">
            <div class="pro3">
              <div>
                <div class="grand1" style="">

                  <div class="cards"
                       style=" border-radius: 9px;height: 200px;font-family: 'Times New Roman';text-align: center;justify-content: center;display: flex;align-content: center">
                    <div style="margin:auto">
                      <div ref="observeElement1" class="list-item-L2R" data-index="0"
                           :class="{ visible: isVisible[0] }">
                        <div style="font-size: 40px;font-weight: bolder">Contact</div>
                      </div>
                      <div ref="observeElement1" class="list-item" data-index="0" :class="{ visible: isVisible[0] }">
                        <div style="font-size: 65px;font-weight: bolder">US</div>
                      </div>

                    </div>
                  </div>
                  <div ref="observeElement2" class="list-item-R2L" data-index="1" :class="{ visible: isVisible[1] }">
                    <div class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
                         @click="upkefu2()">
                      <div style="text-align: center;padding: 25px 0 20px 0">
                        <img src="@/assets/kefu.png" style="height: 60px;width: 60px">
                      </div>
                      <div style="font-family: 'Times New Roman';color: #ade0e1;font-size: 25px;text-align: center;font-weight: bolder">
                        Support
                      </div>
                      <div style="margin: 10px auto;text-align: center">
                        <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
                        <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
                      </div>

                    </div>
                  </div>
                  <div ref="observeElement3" class="list-item-L2R" data-index="2" :class="{ visible: isVisible[2] }">
                    <div class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
                         @click="feedback1()">
                      <div style="text-align: center;padding: 25px 0 20px 0">
                        <img src="@/assets/communicate.png" style="height: 60px;width: 60px">
                      </div>
                      <div style="font-family: 'Times New Roman';color: #acdfe0;font-size: 25px;text-align: center;font-weight: bolder">
                        Suggestions
                      </div>
                      <div style="margin: 10px auto;text-align: center">
                        <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
                        <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
                      </div>

                    </div>
                  </div>
                  <div ref="observeElement4" class="list-item-R2L" data-index="3" :class="{ visible: isVisible[3] }">
                    <div class="cards" style="background-color: #015477;border-radius: 9px;height: 200px"
                         @click="showPartner()">
                      <div style="text-align: center;padding: 25px 0 20px 0">
                        <img src="@/assets/hand.png" style="height: 60px;width: 60px">
                      </div>
                      <div style="font-family: 'Times New Roman';color: #ade0e1;font-size: 25px;text-align: center;font-weight: bolder">
                        Partnership
                      </div>
                      <div style="margin: 10px auto;text-align: center">
                        <!--                      <i class="el-icon-right" style="color:white;font-size: 37px;"></i>-->
                        <img src="@/assets/rightArrow.png" style="height: 30px;width: 30px">
                      </div>

                    </div>
                  </div>
                  <!--                  <div class="card" style="background-color: #015477;width: 80%;height: 80%;margin:auto">-->

                  <!--                  </div>-->
                </div>
              </div>
            </div>

          </div>
          <div>
          </div>
          <div class="  screen6" ref="companyskip" style="">
            <!--      三大优势-->
            <div class="pro3">
              <div v-sliden-in="{}" class="title" style="color:#122278" >Anvision Introduce</div>
              <div v-sliden-in="{}" class="title2">Oil & Gas Generic AI Platform is built and operated by Anvision, adhering to the
                principle of serving all parties in the Oil & Gas industry. Make Digitalization Simple and Practical.
                <span class="AnvisionMore" style="color: #fb6400;font-weight: bolder" v-if="!this.AnvisionMore"
                      @click="handleClick">More >>
                </span>
                <div v-if="AnvisionMore">As an industry-leading oilfield digital intelligence technology service company
                  under the Anton Group, Anvision provides full-stack solutions, products and services for the Oil & Gas
                  industry by focusing on customers, perfectly combining digital intelligence technology with Oil & Gas
                  technology, and providing on-site concomitant services in the Oil & Gas industry in the simplest way,
                  at the lowest cost, in the fastest cycle, and the easiest to implement.
                  <span class="AnvisionMore" style="color: #f96400;width: fit-content;font-weight: bold"
                        @click="()=>{this.AnvisionMore=false}"><i style="font-weight: bold"
                                                                  class="el-icon-arrow-up"></i>
                  </span>
                </div>
              </div>

              <div class="foot">
                <div class="left1">
                  <div @click="hidden3" style="display: flex">
                    <span class="title1" :style="hid2?{ color: '#fd6400' } : { color: '#122277' }">Anvision Company</span>
                    <span  v-if="hid2===0"
                           style="width:fit-content;margin:15px 0 0 auto;float: right;color: #122277"><i
                        style="font-weight: bold"
                        class="el-icon-arrow-up"></i> </span>
                    <span  v-if="hid2===1"
                           style="width:fit-content;margin:15px 0 0 auto;float: right;color: #122277"><i
                        style="font-weight: bold"
                        class="el-icon-arrow-down"></i> </span>
                  </div>
                  <div class="content" :class="{ 'content-visible': hid2 === 0 }">
                    <div v-if="hid2===0" class="title2">
                      Anvision specializes in diverse on-site applications within oil and gas fields, delivering
                      dependable project execution, rapid turnaround times, and cost-effective solutions. <br>We offer
                      tailored services to meet the stringent digitization needs of oil and gas fields.
                    </div>
                    <div v-if="hid2===0"
                         :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }"
                         class="title3">
                      Oil & Gas Digitalization<br>Simple and Practical
                    </div>
                  </div>
                </div>

                <div class="left1">
                  <div @click="hidden" style="display: flex">
                    <span class="title1" :style="hid?{ color: '#fd6400' } : { color: '#122277' }">Anvision Oil & Gas Intelligence Company</span>
                    <span  v-if="hid===0"
                           style="width:fit-content;margin:15px 0 0 auto;float: right;color: #122277"><i
                        style="font-weight: bold"
                        class="el-icon-arrow-up"></i> </span>
                    <span v-if="hid===1"
                          style="width:fit-content;margin:15px 0 0 auto;float: right;color: #122277"><i
                        style="font-weight: bold"
                        class="el-icon-arrow-down"></i> </span>
                  </div>
                  <div class="content1" :class="{ 'content-visible1': hid === 0 }">
                    <div v-if="hid===0" class="title2">
                      Anvision Oil & Gas Intelligence Company focuses on providing AI technology solutions for the oil
                      industry, and is committed to deeply integrating AI technology into the oil business. <br>Our
                      professional team has rich industry experience and advanced AI technology, which can provide
                      efficient and accurate solutions for oil companies, helping them achieve digital transformation
                      and intelligent upgrading.
                    </div>
                    <div v-if="hid===0" class="title3"
                         :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }">
                      Oil & Gas "Generic" Intelligence
                    </div>
                  </div>
                </div>
                <div class="left1">
                  <div @click="hidden4" style="display: flex"><span class="title1"
                                                                    :style="hid4?{ color: '#fd6400' } : { color: '#122277' }">Digital Voyager Software Company</span>
                    <span  v-if="hid4===0"
                           style="width:fit-content;margin:15px 0 0 auto;float: right;color: #122277"><i
                        style="font-weight: bold"
                        class="el-icon-arrow-up"></i> </span>
                    <span  v-if="hid4===1"
                           style="width:fit-content;margin:15px 0 0 auto;float: right;color: #122277"><i
                        style="font-weight: bold"
                        class="el-icon-arrow-down"></i> </span>
                  </div>
                  <div class="content2" :class="{ 'content-visible2': hid4 === 0 }">
                    <div v-if="hid4===0" class="title2">
                      Digital Voyager Software Company to rooted in the oil & gas industry,Our team possesses expertise in professional software products and software development capabilities, offering  covering oil & gas well, oil & gas station, oil & gas pipeline network and other standardized software products and customized application development services
                    </div>
                    <div v-if="hid4===0" class="title3"
                         :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }">
                      Launch oil & gas digital-intelligence blueprint
                    </div>
                  </div>
                </div>
                <div class="left1">
                  <div @click="hidden1" style="display: flex"><span class="title1"
                                                                    :style="hid1?{ color: '#fd6400' } : { color: '#122277' }">Oil Mates Multimedia Company</span>
                    <span  v-if="hid1===0"
                           style="width:fit-content;margin:15px 0 0 auto;float: right;color: #122277"><i
                        style="font-weight: bold"
                        class="el-icon-arrow-up"></i> </span>
                    <span  v-if="hid1===1"
                           style="width:fit-content;margin:15px 0 0 auto;float: right;color: #122277"><i
                        style="font-weight: bold"
                        class="el-icon-arrow-down"></i> </span>
                  </div>
                  <div class="content2" :class="{ 'content-visible2': hid1 === 0 }">
                    <div v-if="hid1===0" class="title2">
                      Oil Mates Multimedia Services are committed to serving the multimedia needs of the Oil & Gas
                      industry and making the Oil & Gas world more vivid and stylish. <br>Provide scientific
                      visualization, marketing promotion and brand optimization services to meet customers' multimedia
                      needs in technology promotion, brand promotion, training, competitions and scientific research
                      reports.
                    </div>
                    <div v-if="hid1===0" class="title3"
                         :style="{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', backgroundColor: bgColor, backgroundImage: textGradient }">
                      Visual Generic Oil & Gas
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
          <!--底部-->
          <div class="screen7" ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>

        <consultTip
            :thisTitle="title"
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
  </div>
</template>
<script>
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import headtip from "@/views/headTip.vue";
import headOther from "@/views/headOther.vue";
import bottomTip from "@/views/bottomTip.vue";
import technology from "@/views/Anxun/technology.vue";
import network from "@/views/Anxun/network.vue";
import AIAnswer from "@/views/AIAnswer.vue";
import {getAuthorize} from "@/api/user";
import AIAnswerMobile from "@/views/AIAnswerMobile.vue";
import {shareUrl} from "@/utils/vxshare";

import {vSlidenIn} from '@/utils/vSlidenIn'
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import HomeNavigation from "@/views/component/homeNavigation.vue";
import FreeGpts from "@/views/component/freeGpts.vue";
import FreeGptMobile from "@/views/component/FreeGptMobile.vue";
import customizationGPTs from "@/views/component/customizationGPTs.vue";
import IntelligentMedium from "@/views/component/IntelligentMedium.vue";
import customizationGPTsMobile from "@/views/component/customizationGPTsMobile.vue"
import software from "@/views/component/software.vue";
import SoftwareMobile from "@/views/component/softwareMobile.vue";
// import softwareMobile from "@/views/component/softwareMobile.vue";
export default {
  directives: {
    slidenIn: vSlidenIn, // 注册指令
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep,
  },
  name: "Home",
  components: {
    SoftwareMobile,
    IntelligentMedium,
    FreeGpts,
    HomeNavigation,
    AIAnswer,
    AIAnswerMobile,
    bottomTip,
    // kefu,
    // kefuMobile,
    PartnerTip,
    consultTip,
    headtip,
    headOther,
    customizationGPTs,
    FreeGptMobile,
    customizationGPTsMobile,
    software,
    // softwareMobile
  },

  data() {
    return {
      inputplaceholder:'I need the Smart Station Solutions',
      // placeholderlist:['I\'d like to use AI models to predict Estimated Ultimate Recovery (EUR)','I need the Smart Oilfield Smart Station Solutions'],
      placeholderlist:['I\'d like to use AI models to predict EUR','I need the Smart Station Solutions'],
      isCollapsed: false,
      isVisible: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
      observeElements: null,
      isCanvisible: [false, false, false, false, false, false, false, false, false, false, false, false],

      hid: 1,
      hid1: 1,
      hid2: 1,
      hid4: 1,
      colors: ['red', 'blue', 'green', 'purple', 'orange'],
      gradients: [
        'linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,)',
        'linear-gradient(to right, green, blue, red, indigo, violet,orange, yellow)',
        'linear-gradient(to right, blue, red, indigo, violet,orange, yellow, green)',
        'linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,)',
        'linear-gradient(to right, green, blue, red, indigo, violet,orange, yellow)',
        'linear-gradient(to right, blue, red, indigo, violet,orange, yellow, green)',
      ],
      index: 0,
      bgColor: 'linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,)',
      textGradient: 'linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,)',
      AnvisionMore: false,
      // swiper: null,
      showTooltip: false,
      changeVisable: 1,
      dialogVisibleInfo: false,
      registerIn: false,
      forgetIn: false,
      loginIn: false,
      isMobile: false,
      titleEn: "",
      contentEn: "",
      languageSelect: "",
      isClicked: false,
      userInfo: "",
      isLogin: "",
      isTextDisplayed: false,
      isSendingMessage: false,
      identification: 0,
      messages: [{
        ok: 0,
        id: '00000',
        sender: "ai",
        introduce: "Hello, welcome to visit Oil & Gas Generic AI, we focus on providing efficient and accurate artificial intelligence solutions for the oil & gas industry.\n" +
            "<br>What is your need I cand help you?🤔",
        resource: "",
      },
        // {
        //   id: "",
        //   ok: 1,
        //   sender: "ai",
        //   answer: "解释类GPTs, 基于油气开发目标解释, 不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况, 还为开发过程中的决策和调整提供了有力支持 解释类GPTs, 基于油气开发目标解释, 不仅有助于工程作业人员实时",
        //   title:"解释类GPTs",s
        //   url:"https://cn.oilgasgpts.com/gptTranslate",
        //   evaluationI: "0",
        //   copy: 0,
        //   question: this.question,
        // }
      ],
      newMessage: "",
      focus: 0,
      screendata: 0,
      focusno: 0,
      Message: "",
      token: "",
      baseURL: this.$utils.baseURL,
      avatar: require("@/assets/user.jpg"),

      communicateHistory: [],
      denyformShow: false,
      denyForm: {
        id: "",
        question: "",
        answer: "",
      },
      primary: "",
      submitForm: {
        id: "",
        question: "",
        answer: "",
        primary: "",
      },
      textarea: "",
      deny: 0,
      count: 0,
      uniqueId: "",
      question: "",
      answer: "",
      options: [
        {
          value: "1",
          label: "新建对话",
        },
        {
          value: "2",
          label: "历史记录",
        },
      ],
      optionvalue: "1",
      showPopover: false,
      timer: null,
      articles: [],
      scrollPosition: 0,
      model: false,
      observe: null,
      input: 0,
      avator: require("../assets/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      title: '数智油田',
      dialogconsult: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: '0123456789ABCDEF',
      carouselItems: [
        {
          img: require("../assets/logo1.png"),
          title: "智能钻井",
          text:
              "着陆段地质层分类预警：基于六个维度数据特征, 建立最小AI模型, 准确率提升20%。" +
              "<br>" +
              "水平段上下穿层预警：水平段穿层实时判断、上下切角实时预警建立最小模型。",
        },
        {
          img: require("../assets/logo2.png"),
          title: "智能压裂",
          text:
              "影响压裂效果的指标权重分析：影响压裂效果（EUR）的多维地质工程参数的指标权重分析。\n" +
              "<br>" +
              "压裂产量EUR预测：基于给定的多维地质工程参数, 对EUR的AI预测模型准确率78%。",
        },
        {
          img: require("../assets/logo3.png"),
          title: "智能采油气",
          text: "磕头机的生产冲次辅助决策：构建智能采油气机器人提供辅助决策。",
        },
        {
          img: require("../assets/logo4.png"),
          title: "地层监测",
          text: "基于光纤的井筒完整性监测：基于光纤DTS温度数据建立对井筒故障监测及位置预警的最小模型。",
        },
        {
          img: require("../assets/logo5.png"),
          title: "设备设施监测",
          text: "设备故障检测：基于开源的滚动轴承数据建立模型, 实时判断轴承故障类型、故障大小、故障位置",
        },
      ],
      groupedItems: [],
      scrollY: 0,
      scrollHeight: 0,
      scrollTrue: true,
      scrollback: false,
    };
  },
  computed: {
    currentArrow() {
      return this.isCollapsed ? require('@/assets/arrow2_left.png') : require('@/assets/arrow2_right.png');
    },
    network() {
      return network
    },
    technology() {
      return technology
    },

  },
  created() {
    localStorage.setItem("home", 1)
    this.intervalId = setInterval(this.changeColors, 2000);
// console.log("___________________",router.history.current.query)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    // console.log("用户分享")
    setTimeout(() => {
      // const imageUrl = 'https://i.postimg.cc/N0d3m2C9/GPTLOGO.png'; // Replace with the actual image URL
      //encodeURIComponent 将你的URL进行转译一下, 因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        // imageUrl: encodeURIComponent(imageUrl), // Adding the image URL to the shareData object
        pageTitle: 'Oil & Gas Generic AI - Professional And Open AI Cloud Platform For The Global Oil & Gas Industry-全球油气行业专业、开放的大模型人工智能云平台',
        pageDescription: 'The Anton Oil & Gas GPT cloud platform leverages Anton\'s leading and innovative integrated oilfield technical service advantages to provide customers with online subscription services such as oil & gas information GPT, corporate management knowledge GPT, oil & gas professional GPT and general GPT without investment. With a large amount of resource construction, you can enjoy the convenience brought by artificial intelligence.',
        pageKeywords: 'Oil & Gas Generic AI - Professional And Open AI Cloud Platform For The Global Oil & Gas Industry, Oil & Gas Generic, Oil & Gas Generic GPT cloud platform, Oil & Gas information GPT, Enterprise management knowledge GPT, Enterprise self-built GPT , Oil & Gas professional intelligent software, General GPT, Multimedia services',
      };
      shareUrl(shareData);
    }, 1000);
  },
  watch: {},

  //进入该页面时, 用之前保存的滚动位置赋值
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    // 移除事件监听器
    clearInterval(this.intervalId);

    if(this.model === false){
      console.log("-----------------1")
      this.$refs.total.removeEventListener('scroll', this.handleScroll);
    }


    console.log("-----------------2")
    // window.removeEventListener('resize', this.updateModel);
  },
  mounted() {
    this.updatePlaceholder();
    this.intervalId = setInterval(this.updatePlaceholder, 10000);

    this.gridss()
    this.observer = new IntersectionObserver((entries) => {
      console.log("==================entries", entries)
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 获取当前被观察元素的索引
          const index = parseInt(entry.target.dataset.index)
          this.$set(this.isVisible, index, true);
          this.observer.unobserve(entry.target);
        }

      });
    }, {threshold: 0.1});
    this.$nextTick(() => {
      console.log("this.$refs.observeElement1", this.$refs.observeElement1)

      if (this.model === false) {
        this.observer.observe(this.$refs.observeElementpc1);
        this.observer.observe(this.$refs.observeElementpc2);
        this.observer.observe(this.$refs.observeElementpc3);
        this.observer.observe(this.$refs.observeElementpc4);
        this.observer.observe(this.$refs.observeElementpcnew);
        this.observer.observe(this.$refs.observeElementpc5);
        this.observer.observe(this.$refs.observeElementpc6);
        this.observer.observe(this.$refs.observeElementpc7);
        this.observer.observe(this.$refs.observeElementpc8);
        this.observer.observe(this.$refs.observeElementpc9);
        this.observer.observe(this.$refs.observeElementpc10);
        this.observer.observe(this.$refs.observeElementpc11);
        this.observer.observe(this.$refs.observeElementpc12);
        this.observer.observe(this.$refs.observeElementpc13);
      } else {
        this.observer.observe(this.$refs.observeElement1);
        this.observer.observe(this.$refs.observeElement2);
        this.observer.observe(this.$refs.observeElement3);
        this.observer.observe(this.$refs.observeElement4);
        this.observer.observe(this.$refs.observeElement5);
        this.observer.observe(this.$refs.observeElement6_1);
        this.observer.observe(this.$refs.observeElement6_2);
        this.observer.observe(this.$refs.observeElement6_3);
        this.observer.observe(this.$refs.observeElement7);
        this.observer.observe(this.$refs.observeElement8);
        this.observer.observe(this.$refs.observeElement8_1);
        this.observer.observe(this.$refs.observeElement8_2);
        this.observer.observe(this.$refs.observeElement8_3);
        this.observer.observe(this.$refs.observeElement9);
        this.observer.observe(this.$refs.observeElement10);
        this.observer.observe(this.$refs.observeElement10_1);
        this.observer.observe(this.$refs.observeElement10_2);
        this.observer.observe(this.$refs.observeElement10_3);
        this.observer.observe(this.$refs.observeElement10_4);
        this.observer.observe(this.$refs.observeElement10_5);
        this.observer.observe(this.$refs.observeElement10_6);
        this.observer.observe(this.$refs.observeElement11);
        // this.observer.observe(this.$refs.observeElement111);
        // this.observer.observe(this.$refs.observeElement112);
        // this.observer.observe(this.$refs.observeElement113);
        this.observer.observe(this.$refs.observeElement12);
        this.observer.observe(this.$refs.observeElement12_1);
        this.observer.observe(this.$refs.observeElement12_2);
        this.observer.observe(this.$refs.observeElement12_3);
        this.observer.observe(this.$refs.observeElement12_4);
        this.observer.observe(this.$refs.observeElement12_5);
        this.observer.observe(this.$refs.observeElement12_6);
        this.observer.observe(this.$refs.observeElement13);
        this.observer.observe(this.$refs.observeElement13_1);
        this.observer.observe(this.$refs.observeElement13_2);
        this.observer.observe(this.$refs.observeElement13_3);
        this.observer.observe(this.$refs.observeElement13_4);
        this.observer.observe(this.$refs.observeElement13_5);
        this.observer.observe(this.$refs.observeElement13_6);
        this.observer.observe(this.$refs.observeElement13_7);
        this.observer.observe(this.$refs.observeElement13_8);
        this.observer.observe(this.$refs.observeElement13_9);
        this.observer.observe(this.$refs.observeElement13_10);
        this.observer.observe(this.$refs.observeElement13_11);
        this.observer.observe(this.$refs.observeElement13_12);
        this.observer.observe(this.$refs.observeElement14);
        this.observer.observe(this.$refs.observeElement14_1);
        this.observer.observe(this.$refs.observeElement14_2);
        this.observer.observe(this.$refs.observeElement14_3);
        this.observer.observe(this.$refs.observeElement15);
        this.observer.observe(this.$refs.observeElement15_1);
        this.observer.observe(this.$refs.observeElement15_2);
        this.observer.observe(this.$refs.observeElement16);
        this.observer.observe(this.$refs.observeElement16_1);
        this.observer.observe(this.$refs.observeElement16_2);
        this.observer.observe(this.$refs.observeElement16_3);
        this.observer.observe(this.$refs.observeElement16_4);
        // this.observer.observe(this.$refs.observeElement16_4);
      }


    })


    this.$refs.total.addEventListener('scroll', this.handleScroll);

    this.updateModel();
    // this.initSwiper();

    // 通用GPT
    if (sessionStorage.getItem("skip") === '1') {
      this.$nextTick(() => {
        this.skipp1();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("skip") === '2') {
      this.$nextTick(() => {
        this.skipp2();
      });
    }
    // 底部
    else if (sessionStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
    // 油气专业智能软件及GPT应用服务
    else if (sessionStorage.getItem("skip") === '4') {
      this.$nextTick(() => {
        this.skipp4();
      });
    }
    // 油气专业智能软件及GPT应用服务
    else if (sessionStorage.getItem("skip") === '5') {
      this.$nextTick(() => {
        this.skipp5();
      });
    }
    // 油气开发作业GPT应用项目服务
    else if (sessionStorage.getItem("skip") === '6') {
      this.$nextTick(() => {
        this.skipp6();
      });
    } else if (sessionStorage.getItem("skip") === '7') {
      this.$nextTick(() => {
        this.skipp7();
      });
    } else if (sessionStorage.getItem("skip") === '8') {
      this.$nextTick(() => {
        this.skipp8();
      });
    } else if (sessionStorage.getItem("skip") === '10') {
      this.$nextTick(() => {
        this.skipp10();
      });
    } else if (sessionStorage.getItem("skip") === '11') {
      this.$nextTick(() => {
        this.skipp11();
      });
    } else if (sessionStorage.getItem("skip") === '12') {
      this.$nextTick(() => {
        this.skipp12();
      });
    } else if (sessionStorage.getItem("skip") === '13') {
      this.$nextTick(() => {
        this.skipp13();
      });
    } else if (sessionStorage.getItem("skip") === '14') {
      this.$nextTick(() => {
        this.skipp14();
      });
    }
    // 添加滚动事件监听器
    this.updateGroupedItems();
  },

  methods: {
    updatePlaceholder() {
      // 更新 placeholder
      this.inputplaceholder = this.placeholderlist[this.currentIndex];

      // 循环更新索引
      this.currentIndex = (this.currentIndex + 1) % this.placeholderlist.length;
    },
    handleClick() {
      this.AnvisionMore = true;
      setTimeout(() => {
        this.AnvisionMore = false;
      }, 50000);
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    changeColors() {
      this.index = (this.index + 1) % this.colors.length;
      this.bgColor = this.gradients[this.index];
      this.textGradient = this.gradients[this.index];
    },
    gridss() {
      this.$nextTick(() => {
        const items = document.querySelectorAll('.grid1');
        console.log("items:::", items)

        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // 为每个元素添加 visible 类并设置延迟
              const index = Array.from(items).indexOf(entry.target);
              entry.target.style.transitionDelay = `${index * 0.1}s`; // 设置延迟
              entry.target.classList.add('visible'); // 添加 visible 类
            }
          });
        }, {threshold: 0.1});

        items.forEach(item => {
          observer.observe(item); // 观察每个元素
        });

      })
    },
    hidden3(){
      // console.log("heii",this.hid2)
      if(this.hid2===1){
        this.hid2 = 0
      }
      else{
        this.hid2 = 1
      }
      // this.hid2=!this.hid2
      setTimeout(() => {
        // alert("出入")
        this.hid2 = 1
      }, 50000);
    },
    hidden23() {

      this.hid2 = 0;
      setTimeout(() => {
        // alert("出入")
        this.hid2 = 1
      }, 50000);
    },
    hidden(){
      if(this.hid===1){
        this.hid = 0
      }
      else{
        this.hid = 1
      }
      setTimeout(() => {
        // alert("出入")
        this.hid = 1
      }, 50000);
    },
    hidden4(){
      if(this.hid4===1){
        this.hid4 = 0
      }
      else{
        this.hid4 = 1
      }
      setTimeout(() => {
        // alert("出入")
        this.hid4 = 1
      }, 50000);
    },
    hidden2() {
      this.hid = 0
      setTimeout(() => {
        // alert("出入")
        this.hid = 1
      }, 50000);
    },
    hidden1(){
      if(this.hid1===1){
        this.hid1 = 0
      }
      else{
        this.hid1 = 1
      }
      setTimeout(() => {
        // alert("出入")
        this.hid1 = 1
      }, 50000);
    },
    hidden12() {
      this.hid1 = 0
      setTimeout(() => {
        // alert("出入")
        this.hid1 = 1
      }, 50000);
    },
    updateModel() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.model = screenWidth <= 700;
    },
    // initSwiper() {
    //   // 确保 swiper-container 已经被渲染
    //   this.$nextTick(() => {
    //     // this.swiper = new Swiper(".swiper-container0", {
    //     //   slidesPerView: 1.1,
    //     //   spaceBetween: 10,
    //     //   loop: false,
    //     //   // autoplay: {
    //     //   //   delay:5000,
    //     //   //   stopOnLastSlide:false,
    //     //   //   disableOnInteraction:false,
    //     //   //   paginationType:"bullets",
    //     //   //   pagination:".swiper-pagination",
    //     //   // },
    //     //   // pagination: {
    //     //   //   el: ".swiper-pagination",
    //     //   //   clickable: true,
    //     //   //   type: 'bullets',
    //     //   // },
    //     //   // // 控制循环
    //     //   autoplay: 5000,
    //     //   pagination: ".swiper-pagination",
    //     //   paginationType: "bullets",
    //     //   // paginationClickable:true,
    //     //   paginationClickable: true,
    //     //   //   点击分页器控制swiper切换
    //     //   preventClicks: false,
    //     //   // 当swiper在触摸时阻止默认事件（preventDefault）, 用于防止触摸时触发链接跳转
    //     //   preventLinksPropagation: false,  //默认true, 阻止click冒泡。拖动Swiper时阻止click事件。
    //     // });
    //     // eslint-disable-next-line no-undef
    //     // this.swiper = new Swiper(".swiper-container1", {
    //     //   slidesPerView: 1.1,
    //     //   spaceBetween: 10,
    //     //   loop: false,
    //     //   // autoplay: {
    //     //   //   delay:5000,
    //     //   //   stopOnLastSlide:false,
    //     //   //   disableOnInteraction:false,
    //     //   //   paginationType:"bullets",
    //     //   //   pagination:".swiper-pagination",
    //     //   // },
    //     //   // pagination: {
    //     //   //   el: ".swiper-pagination",
    //     //   //   clickable: true,
    //     //   //   type: 'bullets',
    //     //   // },
    //     //   // // 控制循环
    //     //   autoplay: 5000,
    //     //   pagination: ".swiper-pagination",
    //     //   paginationType: "bullets",
    //     //   // paginationClickable:true,
    //     //   paginationClickable: true,
    //     //   //   点击分页器控制swiper切换
    //     //   preventClicks: false,
    //     //   // 当swiper在触摸时阻止默认事件（preventDefault）, 用于防止触摸时触发链接跳转
    //     //   preventLinksPropagation: false,  //默认true, 阻止click冒泡。拖动Swiper时阻止click事件。
    //     // });
    //     // this.swiper = new Swiper(".swiper-container2", {
    //     //   slidesPerView: 1.1,
    //     //   spaceBetween: 10,
    //     //   loop: false,
    //     //   // autoplay: {
    //     //   //   delay:5000,
    //     //   //   stopOnLastSlide:false,
    //     //   //   disableOnInteraction:false,
    //     //   //   paginationType:"bullets",
    //     //   //   pagination:".swiper-pagination",
    //     //   // },
    //     //   // pagination: {
    //     //   //   el: ".swiper-pagination",
    //     //   //   clickable: true,
    //     //   //   type: 'bullets',
    //     //   // },
    //     //   // // 控制循环
    //     //   autoplay: 5000,
    //     //   pagination: ".swiper-pagination",
    //     //   paginationType: "bullets",
    //     //   paginationClickable: true,
    //     //   //   点击分页器控制swiper切换
    //     //   preventClicks: false,
    //     //   // 当swiper在触摸时阻止默认事件（preventDefault）, 用于防止触摸时触发链接跳转
    //     //   preventLinksPropagation: false,  //默认true, 阻止click冒泡。拖动Swiper时阻止click事件。
    //     // });
    //     //
    //     // this.swiper = new Swiper(".swiper-container3", {
    //     //   slidesPerView: 1.1,
    //     //   spaceBetween: 10,
    //     //   loop: false,
    //     //   // autoplay: {
    //     //   //   delay:5000,
    //     //   //   stopOnLastSlide:false,
    //     //   //   disableOnInteraction:false,
    //     //   //   paginationType:"bullets",
    //     //   //   pagination:".swiper-pagination",
    //     //   // },
    //     //   // pagination: {
    //     //   //   el: ".swiper-pagination",
    //     //   //   clickable: true,
    //     //   //   type: 'bullets',
    //     //   // },
    //     //   // // 控制循环
    //     //   autoplay: 5000,
    //     //   pagination: ".swiper-pagination",
    //     //   paginationType: "bullets",
    //     // });
    //     this.swiper = new Swiper(".swiper-container4", {
    //       slidesPerView: 1.1,
    //       spaceBetween: 10,
    //       loop: false,
    //       loopPreventsSlide: false,
    //       autoplay: {
    //         delay: 5000,
    //         stopOnLastSlide: false,
    //         disableOnInteraction: false,
    //         paginationType: "bullets",
    //         pagination: ".swiper-pagination",
    //       },
    //       pagination: {
    //         el: ".swiper-pagination",
    //         clickable: true,
    //         type: 'fraction',
    //         // type: 'bullets',
    //       },
    //       navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev',
    //       }
    //       // // 控制循环
    //       // autoplay: 5000,
    //       // pagination: ".swiper-pagination",
    //       // paginationType: "bullets",
    //     });
    //     // this.swiper = new Swiper(".swiper-container5", {
    //     //   slidesPerView: 1.1,
    //     //   spaceBetween: 10,
    //     //   loop: false,
    //     //   // autoplay: {
    //     //   //   delay:5000,
    //     //   //   stopOnLastSlide:false,
    //     //   //   disableOnInteraction:false,
    //     //   //   paginationType:"bullets",
    //     //   //   pagination:".swiper-pagination",
    //     //   // },
    //     //   // pagination: {
    //     //   //   el: ".swiper-pagination",
    //     //   //   clickable: true,
    //     //   //   type: 'bullets',
    //     //   // },
    //     //   // // 控制循环
    //     //   autoplay: 5000,
    //     //   pagination: ".swiper-pagination",
    //     //   paginationType: "bullets",
    //     // });
    //     // this.swiper = new Swiper(".swiper-container6", {
    //     //   slidesPerView: 1.1,
    //     //   spaceBetween: 10,
    //     //   loop: false,
    //     //   // autoplay: {
    //     //   //   delay:5000,
    //     //   //   stopOnLastSlide:false,
    //     //   //   disableOnInteraction:false,
    //     //   //   paginationType:"bullets",
    //     //   //   pagination:".swiper-pagination",
    //     //   // },
    //     //   // pagination: {
    //     //   //   el: ".swiper-pagination",
    //     //   //   clickable: true,
    //     //   //   type: 'bullets',
    //     //   // },
    //     //   // // 控制循环
    //     //   autoplay: 5000,
    //     //   pagination: ".swiper-pagination",
    //     //   paginationType: "bullets",
    //     // });
    //     // this.swiper = new Swiper(".swiper-container7", {
    //     //   slidesPerView: 1.1,
    //     //   spaceBetween: 10,
    //     //   loop: false,
    //     //   // autoplay: {
    //     //   //   delay:5000,
    //     //   //   stopOnLastSlide:false,
    //     //   //   disableOnInteraction:false,
    //     //   //   paginationType:"bullets",
    //     //   //   pagination:".swiper-pagination",
    //     //   // },
    //     //   // pagination: {
    //     //   //   el: ".swiper-pagination",
    //     //   //   clickable: true,
    //     //   //   type: 'bullets',
    //     //   // },
    //     //   // // 控制循环
    //     //   autoplay: 5000,
    //     //   pagination: ".swiper-pagination",
    //     //   paginationType: "bullets",
    //     // });
    //     this.swiper = new Swiper(".swiper-container-pc", {
    //       autoplay: {
    //         delay: 5000,
    //         stopOnLastSlide: false,
    //         disableOnInteraction: false,
    //         paginationType: "bullets",
    //         pagination: ".swiper-pagination",
    //       },
    //       pagination: {
    //         el: ".swiper-pagination",
    //         clickable: true,
    //         type: 'bullets',
    //       },
    //       slidesPerView: 3,
    //       spaceBetween: 15,
    //       loop: false,
    //       navigation: {
    //         nextEl: '.swiper-button-next',
    //         prevEl: '.swiper-button-prev'
    //       },
    //       // autoplay: 5000,
    //       // pagination: ".swiper-pagination",
    //       paginationType: "bullets",
    //       paginationClickable: true,
    //     });
    //   });
    // },
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    urlChange(url) {
      if (url === "https://www.oilgasgpts.com/") {

        console.log("tia")
        this.$emit("skippcom", 15)


      } else {
        window.open(url)
      }

    },
    changeheadVisable() {
      if (this.changeVisable === 1) {
        this.changeVisable = 0
      } else {
        this.changeVisable = 1
      }
      console.log("父组件", this.changeVisable)
    },
    pushLogin() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.replace(res.data.url);
      });
      // this.$router.push("/login")
      // this.$emit("pushLogin")
    },
    handleClose() {
      this.denyformShow = true;
    },
    showDeleteButton(historyItem) {
      // 在鼠标悬停时显示删除按钮
      this.$set(historyItem, "showDeleteButton", true);
    },
    hideDeleteButton(historyItem) {
      // 在鼠标移出时隐藏删除按钮
      this.$set(historyItem, "showDeleteButton", false);
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    optionnew(item) {
      console.log("Selected value:", item.value);
    },
    dateIfAddZero(time) {
      return time < 10 ? "0" + time : time;
    },

    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("wertyuioerfghjkghjrtghjk", this.scrollHeight < 100)
    },
    skippcom(value) {
      const data = value
      console.log("---首页--------", data)
      if (data === 1) {
        this.position1()
      } else if (data === 2) {
        this.position7()
      } else if (data === 3) {
        this.position5()
      } else if (data === 4) {
        this.position2()
      } else if (data === 5) {
        this.position8()
      } else if (data === 6) {
        this.position6()
      } else if (data === 7) {
        this.position9()
      } else if (data === 8) {
        this.position3()
      } else if (data === 9) {
        this.position10()
      } else if (data === 10) {
        const element = this.$refs.companyskip;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.total.scrollTop = rect
        });
      }

    },
    skippcom2(value) {
      const data = value
      console.log("---首页--------", data)
      if (data === 1) {
        this.skipp2()
      } else if (data === 2) {
        this.skipp5()
      } else if (data === 3) {
        this.skipp10()
      } else if (data === 4) {
        this.skipp1()
      } else if (data === 5) {
        this.skipp6()
      } else if (data === 6) {
        this.skipp7()
      } else if (data === 7) {
        this.skipp14()
      } else if (data === 8) {
        this.skipp12()
      } else if (data === 9) {
        this.skipp13()
      } else if (data === 10) {
        const element = this.$refs.companyskip;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }

    },
    uptop() {

      if (this.model === false) {
        this.jumpTo(this.$refs.topback)
        // const element = this.$refs.total;
        // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        // this.$nextTick(() => {
        //   this.$refs.total.scrollTop = rect
        // });
      } else {
        // this.jumpTo(this.$refs.totalModel)
        const elementModel = this.$refs.totalModel;
        const rect = elementModel.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }
    },
    screenmodel(value) {
      if (value === 0) {
        this.screendata = 0
      } else {
        this.screendata = 1
      }

    },
    uptop2() {
      this.focus = 1
      this.focusno = 0
      console.log("实现2")
      console.log("键盘watch-------------", this.keyboardHeight)
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    uptopno() {
      this.focusno = 1
    },
    upkefu() {
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452739742062060&webPageTitle=在线咨询")
    },
    upkefu2() {
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3452803868579283&webPageTitle=在线咨询")
    },
    feedback() {
      window.open("/feedbackEn")
      // window.open("https://mall.antonoil.com/gpts_feedback ", "_blank");
    },
    feedback1() {
      this.$router.push("/feedbackEn")
      // window.open("https://mall.antonoil.com/gpts_feedback ", "_blank");
    },
    Consult(item) {
      console.log(item)
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    clickConsult(item) {

      console.log(item)
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    show() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },

    gpts() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground() {
      localStorage.setItem("skip", 0)
      window.open("/groundOil", "_blank");
    },
    oilgpt() {
      localStorage.setItem("skip", 0)
      window.open("/gptOil", "_blank");
    },
    // 钻井
    hole() {
      localStorage.setItem("skip", 0)
      window.open("/makeHole", "_blank");
    },
    oilDesign() {
      localStorage.setItem("skip", 0)
      window.open("/gptDesign", "_blank");
    },
    oilTranslate() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    oilTechnology() {
      localStorage.setItem("skip", 0)
      window.open("/gptTechnology", "_blank");
    },
    oilgpt2() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    // 压裂
    frack() {
      localStorage.setItem("skip", 0)
      window.open("/frackOil", "_blank");
    },
    oilgpt3() {
      localStorage.setItem("skip", 0)
      window.open("/gptHole", "_blank");
    },
    // 采油
    getOil() {
      localStorage.setItem("skip", 0)
      window.open("/getOil", "_blank");
    },

    // <!--设备检测-->
    equipment() {
      localStorage.setItem("skip", 0)
      window.open("/monitorEqu", "_blank");
    },

    // 地层监测
    monitor() {
      localStorage.setItem("skip", 0)
      window.open("/monitorOil", "_blank");
    },

    // 油田管理
    menage() {
      localStorage.setItem("skip", 0)
      window.open("/menageOil", "_blank");
    },

    station() {
      localStorage.setItem("skip", 0)
      window.open("/station", "_blank");
    },
    security() {
      localStorage.setItem("skip", 0)
      window.open("/security", "_blank");
    },
    productANxun() {
      localStorage.setItem("skip", 0)
      window.open("/product", "_blank");
    },
    equipmentAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/equipmentAnxun", "_blank");
    },
    technologyAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/technology", "_blank");
    },
    monitorAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/monitorAnxun", "_blank");
    },
    networkAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/pipeline", "_blank");
    },
    basic() {
      localStorage.setItem("skip", 0)
      window.open("/basic", "_blank");
    },
    setting() {
      localStorage.setItem("skip", 0)
      window.open("/digital", "_blank");
    }, storage() {
      localStorage.setItem("skip", 0)
      window.open("/storage", "_blank");
    },
    build() {
      localStorage.setItem("skip", 0)
      window.open("/build", "_blank");
    },
    training() {
      localStorage.setItem("skip", 0)
      window.open("/training", "_blank");
    },
    storage1() {
      localStorage.setItem("skip", 8)
      window.open("/storage", "_blank");
    },
    training1() {
      localStorage.setItem("skip", 8)
      window.open("/training", "_blank");
    },
    build1() {
      localStorage.setItem("skip", 8)
      window.open("/build", "_blank");
    },
    traningAi() {
      window.open("/traning", "_blank");
    },

    consult() {
      window.open("/consult", "_blank");
    },
    constructPlat() {
      window.open("/construct", "_blank");
    },
    business() {
      window.open("/business", "_blank");
    },
    company() {
      window.open("/company", "_blank");
    },
    computingPower() {
      window.open("/computingPower", "_blank");
    },
    traningAi1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company2")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },

    station1() {
      sessionStorage.setItem("skip", 7)
      window.open("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      window.open("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      window.open("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      window.open("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      window.open("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      window.open("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
       window.open("/pipeline")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      window.open("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      window.open("/digital")
    },

    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },

    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },
    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },
    expo() {
      this.$confirm('<a style="color: white"><a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas Exposition  website platform.</a><br><span style="color:orange">https://www.oilgasmall.com/', ' ', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,

        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.oilgasmall.com/", "_blank");
      }).catch(() => {

      });

    },
    job() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas JobAI website platform.</a><br><span style="color:orange">https://www.oilgasjobai.com/', ' ', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        localStorage.setItem("skip", 0)
        window.open("https://www.oilgasjobai.com/", "_blank");
      }).catch(() => {

      });

      //
      // this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas JobAI  website platform.', ' ', {
      // if (answer) {
      //   localStorage.setItem("skip", 0)
      //   window.open("https://oilgasjobai.com/", "_blank");
      // } else {
      //   // 用户取消跳转, 留在当前页面
      // }

    },
    info() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas Information  website platform.</a><br><span style="color:orange">https://www.oilgasinfoai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.oilgasinfoai.com/", "_blank");
      }).catch(() => {

      });

    },
    // 测试
    wen() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Open AI  website platform.</a><br><span style="color:orange">https://openai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://openai.com/", "_blank");
      }).catch(() => {

      });
    },
    dou() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Murf  website platform.</a>' +
          '<br><span style="color:orange">https://murf.ai/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://murf.ai/", "_blank");
      }).catch(() => {

      });

    },
    xun() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Lumen  website platform.</a>' +
          '<br><span style="color:orange">https://lumen5.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://lumen5.com/", "_blank");
      }).catch(() => {

      });

    },
    tong() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Midjourney  website platform.</a>' +
          '<br><span style="color:orange">https://www.midjourney.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.midjourney.com/", "_blank");
      }).catch(() => {

      });

    },
    // 测试156
    zhi() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the ChatGLM  website platform.</a>' +
          '<br><span style="color:orange">https://chatglm.cn/?lang=en', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://chatglm.cn/?lang=en", "_blank");
      }).catch(() => {

      });

    },
    teng() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Flair  website platform.</a>' +
          '<br><span style="color:orange">https://flair.ai/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://flair.ai/", "_blank");
      }).catch(() => {

      });

    },
    tian() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Gamma  website platform.</a>' +
          '<br><span style="color:orange">https://gamma.app/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://gamma.app/", "_blank");
      }).catch(() => {

      });

    },
    ge() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Cursor  website platform.</a>' +
          '<br><span style="color:orange">https://www.cursor.com', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open(" https://www.cursor.com", "_blank");
      }).catch(() => {

      });

    },
    wan() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Runwayml Cursor  website platform.</a>' +
          '<br><span style="color:orange">https://runwayml.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://runwayml.com/", "_blank");
      }).catch(() => {

      });
    },
    yi() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Scholarcy  website platform.</a>' +
          '<br><span style="color:orange">https://www.scholarcy.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        localStorage.setItem("skip", 0)
        window.open("https://www.scholarcy.com/", "_blank");
      }).catch(() => {

      });

    },

    meitiProduct() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil Mates Multimedia  website platform.</a><br><a style="color: orange">http://www.oilmates-omm.com/en/h-col-106.html</a>', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        window.open("http://www.oilmates-omm.com/en/h-col-106.html", "_blank");
      }).catch(() => {

      });

    },
    meiti3D() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil Mates Multimedia  website platform.</a><br><a style="color: orange">http://www.oilmates-omm.com/en/h-col-102.html</a>', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        window.open("http://www.oilmates-omm.com/en/h-col-102.html", "_blank");
      }).catch(() => {

      });

    },
    meitiDesign() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil Mates Multimedia  website platform.</a><br><a style="color: orange">http://www.oilmates-omm.com/en/h-col-104.html</a>', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        window.open("http://www.oilmates-omm.com/en/h-col-104.html", "_blank");
      }).catch(() => {

      });

    },
    meitiProduct1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil Mates Multimedia  website platform.</a><br><a style="color: orange">http://www.oilmates-omm.com/en/h-col-106.html</a>', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 14)
        const externalLink = 'http://www.oilmates-omm.com/en/h-col-106.html';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    meiti3D1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil Mates Multimedia  website platform.</a><br><a style="color: orange">http://www.oilmates-omm.com/en/h-col-102.html</a>', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 14)
        const externalLink = 'http://www.oilmates-omm.com/en/h-col-102.html';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    meitiDesign1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil Mates Multimedia  website platform.</a><br><a style="color: orange">http://www.oilmates-omm.com/en/h-col-104.html</a>', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 14)
        const externalLink = 'http://www.oilmates-omm.com/en/h-col-104.html';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    expo1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas Exposition  website platform.</a><br><span style="color:orange">https://www.oilgasmall.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 2)
        // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
        const externalLink = 'https://www.oilgasmall.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    job1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas JobAI  website platform.</a><br><span style="color:orange">https://www.oilgasjobai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 2)
        const externalLink = 'https://www.oilgasjobai.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    info1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Oil & Gas Information  website platform.</a><br><span style="color:orange">https://www.oilgasinfoai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 2)
        const externalLink = 'https://www.oilgasinfoai.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    wen1(){
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Open AI  website platform.</a>' +
          '<br><span style="color:orange">https://openai.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://openai.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },

    traning() {
      window.open("/traning", "_blank");
    },
    service() {
      window.open("/service", "_blank");
    },
    construct() {
      window.open("/construct", "_blank");
    },
    menageService() {
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    application() {
      window.open("/application", "_blank");
    },
    oilApplication() {
      window.open("/oilApplication", "_blank");
    },

    knowledge() {
      window.open("/gptKnowledge", "_blank");
    },
    data() {
      window.open("/gptData", "_blank");
    },
    wisdom() {
      window.open("/wisdom", "_blank");
    },
    plat() {
      window.open("/plat", "_blank");
    },
    design() {
      window.open("/design", "_blank");
    },
    automation() {
      window.open("/automation", "_blank");
    },

    traning1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/traning")
    },
    service1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/service")
    },
    construct1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/construct")
    },
    menageService1() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    application1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/application")
    },
    oilApplication1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/oilApplication")
    },

    knowledge1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/gptKnowledge")
    },
    data1() {
      this.$router.push("/gptData")
      sessionStorage.setItem("skip", 10)
    },
    wisdom1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/wisdom");
    },
    plat1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/plat")
    },
    design1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/design")
    },
    automation1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/automation")
    },
    jumpTo(element){

      console.log("top:::",(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0))
      console.log("offsettop:::",element.offsetTop )
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0)-80;
      const rect = element.offsetTop <80? element.offsetTop:(element.offsetTop-80);

      console.log(rect)
      window.scrollTo({
        top: rect,
        behavior: 'smooth'
      });
    },
    position1() {
      // const element = this.$refs.oilZixun;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒

      // const element = this.$refs.oilZixun;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      // console.log(rect)
      // window.scrollTo({
      //   top: rect-80,
      //   behavior: 'smooth'
      // });

      this.jumpTo(this.$refs.oilZixun)

    },
    position2() {
      this.jumpTo(this.$refs.generalMove)
      // const element = this.$refs.generalMove;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    position3() {
      this.jumpTo(this.$refs.aiPeixun)
      // const element = this.$refs.aiPeixun;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    position4() {
      this.jumpTo(this.$refs.companyZijian)
      // const element = this.$refs.companyZijian;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    position5() {
      this.jumpTo(this.$refs.menageService)
      // const element = this.$refs.menageService;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    position6() {
      this.jumpTo(this.$refs.consultMove)
      // const element = this.$refs.consultMove;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    position7() {
      this.jumpTo(this.$refs.professional)
      // const element = this.$refs.professional;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    position8() {
      this.jumpTo(this.$refs.moveProduct)
      // const element = this.$refs.moveProduct;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    position9() {
      this.jumpTo(this.$refs.mediaMove)
      // const element = this.$refs.mediaMove;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    position10() {
      this.jumpTo(this.$refs.companyZijian)
      // const element = this.$refs.companyZijian;
      // const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      //
      //
      // function scrollToElement(element, to, duration) {
      //   if (duration <= 0) return;
      //   const difference = to - element.scrollTop;
      //   const perTick = difference / duration * 10;
      //   setTimeout(() => {
      //     element.scrollTop = element.scrollTop + perTick;
      //     if (element.scrollTop === to) return;
      //     scrollToElement(element, to, duration - 10);
      //   }, 10);
      // }
      //
      // scrollToElement(this.$refs.total, rect - 80, 500); // 滚动持续时间为500毫秒
    },
    skipp1() {
      const element = this.$refs.general;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp2() {
      const element = this.$refs.zixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp4() {
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    skipp5() {
      const element = this.$refs.professional1;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp6() {
      const element = this.$refs.Development;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp7() {
      const element = this.$refs.station;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp8() {
      const element = this.$refs.technology;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp10() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp11() {
      const element = this.$refs.company;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },

    skipp12() {
      const element = this.$refs.traningConsult;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp13() {
      const element = this.$refs.companyConduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp14() {
      const element = this.$refs.media;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    dou1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Murf platform.</a>' +
          '<br><span style="color:orange">https://murf.ai/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://murf.ai/';
        this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    xun1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Lumen  website platform.</a>' +
          '<br><span style="color:orange">https://lumen5.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://lumen5.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });


    },
    tong1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Midjourney  website platform.</a>' +
          '<br><span style="color:orange">https://www.midjourney.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://www.midjourney.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

      }).catch(() => {

      });

    },
    zhi1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the ChatGLM  website platform.</a>' +
          '<br><span style="color:orange">https://chatglm.cn/?lang=en', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://chatglm.cn/?lang=en';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    teng1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Flair  website platform.</a>' +
          '<br><span style="color:orange">https://flair.ai/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://flair.ai/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    tian1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Gamma  website platform.</a>' +
          '<br><span style="color:orange">https://gamma.app/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://gamma.app/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    ge1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Cursor  website platform.</a>' +
          '<br><span style="color:orange">https://www.cursor.com', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://www.cursor.com';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    wan1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Runwayml  website platform.</a>' +
          '<br><span style="color:orange">https://runwayml.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://runwayml.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },
    yi1() {
      this.$confirm('<a style="color: white">You are about to leave the Anvision Oil & Gas Generic AI platform and enter the Yizhen TV  website platform.</a>' +
          '<br><span style="color:orange">https://aigc.yizhentv.com/', '', {
        confirmButtonText: 'continue',
        dangerouslyUseHTMLString: true,
        cancelButtonText: 'Cancel',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {

        sessionStorage.setItem("skip", 1)
        const externalLink = 'https://aigc.yizhentv.com/';
        // 导航到外部链接处理路由, 并传递外部链接作为参数
        this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
      }).catch(() => {

      });

    },

    updateGroupedItems() {
      const startIndex = this.currentIndex;
      this.groupedItems = [];

      for (let i = 0; i < this.itemsPerPage; i++) {
        const group = this.carouselItems.slice(
            startIndex + i,
            startIndex + i + this.itemsPerPage
        );
        this.groupedItems.push(group);
      }
    },
    leftnext() {
      console.log(this.currentIndex);
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateGroupedItems();
      }
    },
    rightnext() {
      if (this.currentIndex < 1) {
        this.currentIndex++;
        this.updateGroupedItems();
      }
    },
    onCarouselChange(index) {
      this.currentIndex = index;
      this.updateGroupedItems();
    },
  },
};
</script>
<style>

.image {
  width: 100%; /* 根据需要设置图片的宽度 */
  height: 100%; /* 高度自适应 */
  transition: transform 0.5s ease-out; /* 添加过渡效果 */
}

.image:hover {
  transform: scale(1.1); /* 鼠标悬浮时放大图片 */
}

.up {
  transition: transform 0.5s ease; /* 动画过渡 */
}

.collapsed {
  transform: translateX(50%); /* 向右移动 */
}

.list-item-pc {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc.visible {
  opacity: 1;
  transform: translateX(0);
}


.list-item-pc1 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease, transform 0.5s ease;

}

.list-item-pc1.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc2 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc2.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc3 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1.5s ease, transform 1.5s ease;

}

.list-item-pc3.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc4 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc4.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc5 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc5.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc6 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc6.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc7 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc7.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc8 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc8.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc9 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc9.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc10 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc10.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc11 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc11.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc12 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc12.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item-pc13 {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-pc13.visible {
  opacity: 1;
  transform: translateY(0);
}

.list-item {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item.visible {
  opacity: 1;
  transform: translateY(0);
}


.list-item-micromove {
  opacity: 0;
  transform: translateY(40px) scale(0.8);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-micromove.visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}




.list-item-L2R {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-L2R.visible {
  opacity: 1;
  transform: translateX(0);
}

.list-item-R2L {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 1s ease, transform 1s ease;

}

.list-item-R2L.visible {
  opacity: 1;
  transform: translateX(0);
}


@keyframes flow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}


swiper-wrapper {
  transition-timing-function: linear !important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  margin-top: 2vh;
}

.swiper-pagination {
  position: relative;
}

.swiper-pagination-bullet {
  width: 25px;
  border-radius: 9px;
}

.swiper-pagination-bullets {
  margin-top: 2vh;
}
.swiper-pagination-bullet-active {
  background-color: rgb(47, 110, 255);
}
.el-message-box {
  width: 45% !important;
  background-color: #3a3b73 !important;
  border: none !important;
  color: white !important;
}

.el-message-box__status {
  top: -10% !important;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.el-message-box__status + .el-message-box__message {
  padding: 8vh 0 3vh 0 !important;
  text-align: center;


}

.el-message-box__status.el-icon-warning {
  color: white !important;
  font-size: 8.5vh !important;
  top: 5% !important;
}

.el-message-box__message p {
  line-height: 6vh !important;
  font-size: 1.8vw !important;
  font-family: Times New Roman !Important;
}

.el-message-box__headerbtn .el-message-box__close {
  color: white !important;
}

.el-message-box__content {
  font-size: 1vw !important;
  line-height: 3vh !important;
}

.el-message-box__btns {
  text-align: center !important;

}

.el-button--primary {
  background-color: #f5f3f3 !important;
  border: none !important;
  font-size: 1.8vw !important;
  color: #3a3b73 !important;
  font-family: Times New Roman !Important;
  width: 70% !important;
  padding: 10px 0 !important;
  border-radius: 9px !important;
  margin-bottom: 35px !important;
}

@media screen and  (max-width: 757px) {
  .el-message-box {
    width: 95% !important;
    height: fit-content !important;
    margin: auto auto !important;
    background-color: #3a3b73 !important;
    border: none !important;
    color: white !important;
    border-radius: 9px !important;
  }

  .el-message-box__message p {
    line-height: 26px !important;
    font-size: 18px !important;
  }

  .el-button--primary {
    background-color: #f5f3f3 !important;
    border: none !important;
    font-size: 18px !important;
    font-family: Times New Roman !Important;
    color: #3a3b73 !important;
    width: 70% !important;
    padding: 10px 0 !important;
    border-radius: 9px !important;
    margin-bottom: 35px !important;
  }

  .el-message-box__status {
    font-size: 55px !important;
    top: 10% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;

  }

  .el-message-box__status.el-icon-warning {
    color: white !important;
    font-size: 55px !important;
  }

  .el-message-box__status + .el-message-box__message {
    padding: 65px 20px 25px 20px !important;
    font-size: 16px !important;
    line-height: 25px !important;
    text-align: center;

  }

  .el-message-box__headerbtn .el-message-box__close {
    color: white !important;
  }

  .el-message-box__content {
    font-size: 16px !important;
    line-height: 25px !important;
  }

  .el-message-box__btns {
    text-align: center !important;


  }

  .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
    margin-top: 0 !important;
  }

  .swiper-pagination-fraction {
    font-weight: bolder;
    margin-top: 20px !important;
    font-family: "Times New Roman";
    color: #122277;
  }

  .swiper-pagination {
    position: relative;
  }

  .swiper-button-prev, .swiper-button-next {
    z-index: 9999;
    top: 101% !important;
    width: 30px !important;
    height: 16px !important;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 30% !important;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 30% !important;
  }

  .swiper-pagination-bullet {
    width: 25px;
    height: 5px !important;
    border-radius: 9px;
  }

  .swiper-pagination-bullet-active {
    background-color: rgb(47, 110, 255);
  }
}

@keyframes jiantou {
  0% {
    transform: scale(0.5);
    margin-top: -30px;
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    margin-top: -15px;
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    margin-top: -0px;
    opacity: 0.3;
  }
}

.animated-element {
  //height: 10vh; animation: jiantou 3s linear infinite 0S;
}

.animated-element2 {
  //height: 10vh; animation: jiantou 3s linear infinite 0.8s;
}

.animated-element3 {
  //height: 10vh; animation: jiantou 3s linear infinite 2s;
}

.animated-element4 {
  //height: 10vh; animation: jiantou 3s linear infinite 3s;
}

.AnvisionMore:hover {
  cursor: pointer;
  color: white;
}

.img{
  height: unset;
}
</style>
<style lang="scss" scoped>
.grid1 {
  opacity: 0 !important;
  transform: translateY(20px);


}

.grid1.visible {
  opacity: 1 !important;
  transform: translateY(0);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}


.avator {
  border: 2px solid #fff;
}

.run-enter-active {
  animation: fadeInDownBig 0.5s linear both;
}

.rundown-enter-active {
  animation: fadeInUpBig 0.5s linear both;
}
.run1{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0s; /* 延迟1秒开始播放动画 */
}
.run2{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0.1s; /* 延迟1秒开始播放动画 */
}
.run3{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0.2s; /* 延迟1秒开始播放动画 */
}
.run4{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0.3s; /* 延迟1秒开始播放动画 */
}
.run5{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0.4s; /* 延迟1秒开始播放动画 */
}
.run6{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0.5s; /* 延迟1秒开始播放动画 */
}
.run7{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0.6s; /* 延迟1秒开始播放动画 */
}
.run8{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0.7s; /* 延迟1秒开始播放动画 */
}
.run9{
  //animation:down123 0.5s linear both ;
  opacity: 0;
  animation: runout 0.5s ease-in-out 1s forwards; /* 动画时长为2秒，动画效果为ease-in-out */
  animation-delay: 0.8s; /* 延迟1秒开始播放动画 */
}
.userName {
  color: #2f6eff;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total {
  background: white;
  //background: #1d3b71;
  //overflow-x: hidden;
  width: 100vw;
  //height: 100vh;
  position: relative;

  .orangetext {
    color: orange;
  }

  .topback {
    position: fixed;
    z-index: 9;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
    //background-image: url("../assets/back1.jpg");

    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
    /* Add any additional styling as needed */

    .up {
      z-index: 99;
      cursor: pointer;
      position: fixed;
      top: 40%;
      right: 0;
      padding: 5px;
      width: 3vw;
      height: fit-content;;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      background-color: #282d7e;

      //border-radius: 50%;
      //box-shadow: 5px 0 14px 0px #D4E4FF;

      .uptop {
        width: 2vw;
        height: 2vw;

        margin: 1vh auto;
        background-image: url("../assets/up.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
      }

      .upkefu {
        margin: 0.5vh auto;
        width: 2vw;
        height: 2vw;
        background-image: url("../assets/kefu2.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
      }

      .feedback {
        padding: 0.5vh 0;
        margin: 1vh auto;
        font-size: 0.8vw;
        text-align: center;
        color: white;
      }

    }

    .layer2 {
      margin-top: 3vh;
      text-align: center;

      .title1 {
        height: 6vh;
        font-size: 3.2vw;
        font-family: Times New Roman;
        font-weight: bold;
        color: #122279;
        line-height: 12.23733003708282vh;
      }

      .title2 {
        margin-top: 5vh;
        height: 6vh;
        font-size: 1.8vw;
        font-family: Times New Roman;
        font-weight: bold;
        color: #122279;
        line-height: 5.31520395550062vh;
      }

      .title3 {
        margin: 7vh auto 0 auto;
        padding: 2vh 1.5vw;
        border-radius: 9px;
        border: white 1px solid;
        ////box-shadow: 5px 0 14px 0px #D4E4FF;
        //background-color: white;
        background: white;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        text-align: center;
        row-gap: 1.2vh;
        column-gap: 0.8vw;
        width: 63vw;
        height: fit-content;
        font-size: 1.2vw;
        font-family: Times New Roman;
        font-weight: normal;
        color: #122279;
        line-height: 2.5vh;

        .hoverTitleup {
          border: #e5e6ea 1px solid;
          padding: 1vh 0.5vw;
          line-height: 2.5vh;
          height: 8vh;
          text-align: left;
          display: flex;
          //justify-content: center;
          align-items: center;
          font-size: 1.1vw;
          font-weight: bold;
          border-radius: 9px;
          background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
          ////box-shadow: 5px 0 14px 0px #D4E4FF;
          cursor: pointer;
          transition: transform 0.3s ease-out;
        }

        .hoverTitleup:hover {
          transform: translateY(-5px);
        }

        .light {
          //display: -webkit-box;
          display: none;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: normal;
          font-size: 0.9vw;
          margin: 1vh 0;
          text-align: left;
        }

      }
    }

    .layer3 {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 12vw;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      opacity: 1;
      //border: 5px solid blue;
    }
  }

  .dashedlayer {
    z-index: 8;
    position: absolute;
    top: 92.21260815822002vh;
    left: 0;
    width: 15vw;
    height: 8.52904820766378vh;
    background: linear-gradient(to right, #e9effb 30%, transparent 100%);
  }

  .dashedlayerblue {
    z-index: 9;
    position: absolute;
    top: 91.21260815822002vh;
    left: 0;
    width: 80vw;
    height: 8.52904820766378vh;
    background: linear-gradient(
            to bottom,
            rgba(202, 222, 251, 0.9) 0%,
            transparent 100%
    );
  }

  .layer4 {
    z-index: 2;
    position: absolute;
    top: 92.21260815822002vh;
    left: 4.44829578278452vw;
    width: 89.42807625649913vw;
    height: 8.52904820766378vh;
    background-image: url("../assets/hummer.png");
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .dashed {
    z-index: 1;
    position: absolute;
    top: 67.36711990111248vh;
    left: 10.86077411900635vw;
    width: 80.22068168688619vw;
    height: 75.24474660074166vh;
    background: linear-gradient(to bottom, #ebf1fd 50%, transparent 100%);
  }


  .screen3 {
    z-index: 10;
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 8vh 7.91854419410745vw;
    background: transparent;

    .title {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: fit-content;;
      opacity: 1;
      font-size: 3vw;
      //color: #53A3F7;
      font-family: Times New Roman;
      //font-family:Times New Roman;;
      font-weight: 700;
      line-height: 8vh;
    }

    .light {
      margin: 2vh auto;
      text-align: left;
      width: fit-content;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 6vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.96938186019642vw;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 6vh;
    }

    .internew {
      border-radius: 9px;
      height: fit-content;
      width: 70%;
      margin: 0 0 0 auto;;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 29.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          border-radius: 9px;
          width: 100%;
          height: 50vh;
          background-color: #015377;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
        }

        .photo {
          overflow: hidden;
          border-radius: 9px;
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 15vh;
          margin: 10vh auto 5vh auto;
          height: 15vh;

          .mengban {
            margin-top: 10vh;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              height: 100%;
              color: white;
              margin-top: unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          height: 8.2vh;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          //transform: translateY(-10px);

        }
      }
    }

    .pro2 {
      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 29.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          border-radius: 9px;
          width: 100%;
          height: 100%;
          background-color: white;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: 15%;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }


        .photo {
          overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 50.123vh;

          .mengban {
            display: flex;
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;

            height: 20%;
            background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
            opacity: 0.9;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              //position: absolute;
              //height: 100%;
              //color: white;
              //margin-top: unset
              opacity: 4;
              text-align: left;
              //display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1.8vw;
              line-height: 4vh;
              font-family: Times New Roman;
              font-weight: bold;
              color: white;
              margin: 2.2249690977vh auto 1.48vh 1vw;
            }
          }
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          height: 8.2vh;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          //transform: translateY(-10px);

        }
      }
    }


  }

  .screenConsult {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 7.91854419410745vw 5vh 7.91854419410745vw;
    background: white;

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 75vw;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .title {

      height: fit-content;;
      opacity: 1;
      font-size: 3vw;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: 700;
      line-height: 8vh;
    }

    .light {
      margin: 2vh auto;
      text-align: left;
      width: fit-content;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 6vh;
    }

    .pro1 {
      height: 45.45364647713226vh;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand1 {
        cursor: pointer;
        width: 100%;
        height: 45.45364647713226vh;
        padding: 0.98887515451174vh 0.32432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          width: 100%;
          height: 100%;
          background: white;;
          border: 2px solid #FFFFFF;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
        }

        .photo {
          overflow: hidden;
          border: 2px solid #FFFFFF;
          background-image: url("../assets/pro1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 21.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 5.22496909765142vh 1.2vw auto 1.2vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: 600;
          //color: #2168DB;
          line-height: 3.71940667490729vh;
        }

      }

      .grand1:hover {
        transform: scale(1.05);

        .title1 {
          color: #2168db;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          font-size: 0.80878105141537vw;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: 25.30329289428076vw;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 5px;
          display: flex;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh 0 3vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 19vw;
          height: 17.44004944375773vh;
        }

        .title1 {
          opacity: 4;
          margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;
          text-align: left;
          width: 12vw;

          height: 3vh;
          font-size: 0.97094165222415vw;
          white-space: nowrap; //换行
          font-family: Times New Roman;
          font-weight: bold;
          overflow: hidden;

          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.51940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          box-shadow: 5px 0 14px 0px #9abaf3;
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 32.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          //margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          margin: 0 auto;
          width: fit-content;
          height: 50.123vh;
          //height: 4.73176761433869vh;
          .mengban {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 15%;
            background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
            opacity: 0.9;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              position: absolute;
              height: 100%;
              color: white;
              margin-top: unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 3.1940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      //.grand2:hover {
      //  .back {
      //    background-color: rgb(135, 103, 215);
      //  }
      //
      //  .photo {
      //overflow: hidden;
      //    display: none;
      //  }
      //
      //  .title1 {
      //    position: absolute;
      //    left:1.8vw;
      //    top: 2.22496909765142vh;
      //    height: 2.71940667490729vh;
      //    color: white;
      //    font-size: 0.97094165222415vw;
      //  }
      //
      //  .title2 {
      //    word-break: normal;
      //    margin-top: 8.22496909765142vh;
      //    color: white;
      //    font-size: 0.80878105141537vw;
      //  }
      //
      //  .consult {
      //    position: absolute;
      //    left: 1.8vw;
      //    cursor: pointer;
      //    display: block;
      //    color: white;
      //  }
      //}
    }


  }

  .screenConsult1 {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 7.91854419410745vw 5vh 7.91854419410745vw;
    background: white;

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 75vw;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 3vw;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: 700;
      line-height: 8vh;
    }

    .light {
      margin: 2vh auto 3vh auto;
      text-align: left;
      width: fit-content;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 6vh;
    }


    .pro12 {
      height: fit-content;
      width: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 98%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;
        background-color: transparent;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: 15%;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 50.123vh;
        }

        .title1 {
          margin-left: 1vw;
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          //height: 100%;
          // text-align: justify;
          margin: auto 0 15px 0;
          font-size: 1vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro1 {
      height: 37.45364647713226vh;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        height: 37.45364647713226vh;
        padding: 0.98887515451174vh 0.32432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          width: 100%;
          height: 100%;
          background: white;;
          border: 2px solid #FFFFFF;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
        }

        .photo {
          overflow: hidden;
          border: 2px solid #FFFFFF;
          background-image: url("../assets/pro1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 21.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 5.22496909765142vh 0.5vw auto 0.5vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: 600;
          //color: #2168DB;
          line-height: 3.01940667490729vh;
        }

      }

      .grand1:hover {
        //transform: translateY(-10px);

        .title2 {
          font-size: 0.80878105141537vw;
        }
      }

    }

    .pro3 {
      height: fit-content;
      width: 100%;
      overflow-x: hidden;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 35vh;
        }

        //.title1 {
        //  opacity: 4;
        //  text-align: left;
        //  display: flex;
        //  justify-content: center;
        //  align-items: center;
        //  font-size: 1.8vw;
        //  font-family: Times New Roman;
        //  font-weight: bold;
        //  color: white;
        //  //line-height: 35px;
        //}
        //
        //.title2 {
        //  display: flex;
        //  justify-content: center;
        //  align-items: center;
        //  opacity: 4;
        //  //width: 100%;
        //  height: 100%;
        //  // text-align: justify;
        //  margin: auto 0 5px 0;
        //  font-size: 1vw;
        //  font-family: Times New Roman;
        //  font-weight: 500;
        //  //color: #2168DB;
        //  //line-height: 35px;
        //}
        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6vw;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          margin: 2.2249690977vh auto 1.48vh 1vw;
          //line-height: 35px;
          //opacity: 4;
          //margin: 2.2249690977vh auto 2.2249690977vh auto;
          //padding: 3vh 1vw 1vh 1vw;
          //text-align: center;
          //display: flex;
          //justify-content: center;
          //align-items: center;
          //height: 2.7194066749vh;
          //font-size: 1.8vw;
          //font-family: Times New Roman;
          //font-weight: bold;
          //line-height: 2.7194066749vh;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          //height: 100%;
          // text-align: justify;
          margin: auto 1vw 1.5249690977vh 0;
          font-size: 1.2vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 1vw;
      row-gap: 1vh;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0vh 0 0vh 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 5px;
          display: flex;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 1vh 0 1vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 25vw;
          border-radius: 9px;
          height: 17.44004944375773vh;
        }

        .title1 {
          opacity: 4;
          margin: 2vh 0 1vh 0.85540150202195vw;
          text-align: left;
          width: 12vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //word-break: break-all;
          height: 6vh;
          font-size: 0.97094165222415vw;
          //white-space: nowrap; //换行
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 0.15540150202195vw 0.94437577255871vh 1.54437577255871vh;
          ////text-align: justify;
          //word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          height: 8.1vh;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.7194066749vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          //transform: translateY(-10px);
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 1.52432120161756vw 0.98887515451174vh 1.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2.94437577255871vh 1.35540150202195vw;
          ////text-align: justify;
          //word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo {
          overflow: hidden;
          display: none;
        }

        .title1 {
          position: absolute;
          left: 2.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          position: absolute;
          left: 2.85540150202195vw;
          cursor: pointer;
          display: block;
          color: white;
        }
      }
    }

    .pro4 {
      height: fit-content;
      width: 100%;
      margin: auto;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 1vw;
      row-gap: 1vh;

      .grand2 {
        cursor: pointer;
        width: 100%;
        margin: auto;
        height: 35vh;
        padding: 0.98887515451174vh 0 0.98887515451174vh 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #D4E4FF;
          border-radius: 9px;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 5vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 8.73176761433869vh;
          height: 8.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin-top: 7.5vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          height: 14vh;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 1.5vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 1.2vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(252, 108, 36);
        }

        .photo {
          overflow: hidden;

        }

        .title1 {
          color: white;
          margin-top: 3.22496909765142vh;
          //position: absolute;
          //left: 1.85540150202195vw;
          //top: 2.22496909765142vh;
          //height: 2.71940667490729vh;
          //color: white;
          //font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          left: 50%;
          transform: translateX(-50%);
          color: white;
        }

      }
    }

  }

  .screen8 {

    width: 100%;
    height: fit-content;
    padding: 8vh 7.91854419410745vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 3vw;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: 700;
      line-height: 8vh;
    }

    .light {
      margin: 2vh auto;
      text-align: left;
      width: 75vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 6vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 6vh;
    }

    .poj {
      cursor: pointer;
      background-image: url("../assets/back.png");
      background-size: contain;
      background-position: right;
      background-repeat: no-repeat;
      background-color: #e5edfc;
      width: 80%;
      height: 35%;
      border: #e5edfc 1px solid;
      border-radius: 9px;
      margin: 5% 10%;
      position: relative
    }

    .poj:hover {
      background-color: #1d3b71;
      color: white;

      .pojimg {
        font-size: 1vw;
        font-weight: bold;
        position: absolute;
        bottom: 14%;
        right: 5%;
        color: white;

        .imgs {
          width: 1.2vw;
          object-fit: contain;
          content: url('../assets/more_btn_icon.png_white.png');
        }
      }
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 0 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: left;

          padding: 3vh 1.5vw 0 1.5vw;
          display: flex;
          height: fit-content;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 1.5vh
        }

        .title2 {
          opacity: 4;
          margin: 0 1.355401502vw 0.5vh 1.355401502vw;
          text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          height: 10.8vh;
          line-height: 2.7194066749vh;
        }

        .title3 {
          opacity: 4;
          grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
          margin: 2vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: grid;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          //transform: translateY(-10px);

        }
      }
    }

    .pro2 {
      border-radius: 9px;
      height: fit-content;
      width: 100%;
      margin: auto;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #ebf0fb;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
        }

        .photo {
          overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;

          .mengban {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 15%;
            background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
            opacity: 0.9;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              position: absolute;
              //height: 100%;
              color: white;
              margin-top: unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh auto 2.22496909765142vh auto;
          padding: 3vh 1vw 1vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          //transform: translateY(-10px);

        }
      }
    }

    //.pro2 {
    //  height: fit-content;
    //  width: 100%;
    //  z-index: 89156;
    //  padding: 0 8.09763142692085vw 0 8.09763142692085vw;
    //  display: grid;
    //  grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
    //  //column-gap: 1vw;
    //  row-gap: 2vh;
    //
    //
    //  .grand3 {
    //    cursor: pointer;
    //    width: 100%;
    //    min-height: fit-content;
    //    padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
    //    opacity: 1;
    //    position: relative;
    //
    //    .back {
    //      width: 100%;
    //      display: flex;
    //      transition: transform 0.5s ease;
    //      height: fit-content;
    //      //box-shadow: 5px 0 14px 0px #D4E4FF;
    //      border: 2px solid #FFFFFF;
    //      border-radius: 9px;
    //      position: relative;
    //      background-image: url("../assets/servicesBanner3.png");
    //      background-size: cover;
    //      background-position: center;
    //      background-repeat: no-repeat;
    //
    //      .details {
    //        position: absolute;
    //        font-family: 'Times New Roman';
    //        font-weight: 500;
    //        color: #2f6dfd;
    //        font-size: 0.80878105141537vw;
    //        bottom: 2.4437577255871vh;
    //        right: 1.05540150202195vw;
    //      }
    //    }
    //
    //    .photo {
    //overflow: hidden;
    //      display: flex;
    //      justify-content: center;
    //      text-align: center;
    //      width: 100%;
    //      height: fit-content;
    //      border-top-right-radius: 9px;
    //      border-top-left-radius: 9px;
    //      //box-shadow: -1px 1px 14px 0 #a2baf1;
    //    }
    //
    //    .title1 {
    //      opacity: 4;
    //      text-align: left;
    //
    //      padding: 3vh 1.5vw 0 1.5vw;
    //      display: flex;
    //      height: fit-content;
    //      font-size: 0.97094165222415vw;
    //      font-family: 'Times New Roman';
    //      font-weight: bold;
    //      //color: #2168DB;
    //      line-height: 3.4vh
    //    }
    //
    //    .title2 {
    //      opacity: 4;
    //      margin: 3vh 1.355401502vw 0.5vh 1.355401502vw;
    //      //text-align: justify;
    //      display: -webkit-box;
    //      -webkit-box-orient: vertical;
    //      -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
    //      overflow: hidden;
    //      text-overflow: ellipsis;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 0.8087810514vw;
    //      font-family: 'Times New Roman';
    //      font-weight: 500;
    //      height: 10.8vh;
    //      line-height: 2.7194066749vh;
    //    }
    //
    //
    //    .consult {
    //      position: absolute;
    //      display: none;
    //      font-family: 'Times New Roman';
    //      font-weight: 500;
    //      font-size: 0.80878105141537vw;
    //      bottom: 3.94437577255871vh;
    //      left: 1.85540150202195vw;
    //    }
    //  }
    //
    //  .grand3:hover {
    //    .back {
    //      //transform: translateY(-10px);
    //
    //    }
    //  }
    //}

  }

  .screen10 {
    width: 100%;
    height: fit-content;
    padding: 8vh 7.91854419410745vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    //background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    background-color: white;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 3vw;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: 700;
      line-height: 8vh;
    }

    .light {
      margin: 2vh auto;
      text-align: left;
      width: fit-content;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 6vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.96938186019642vw;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 6vh;
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 0 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-color: #e9eef9;
          //background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          margin: 3vh 1vw;
          justify-content: center;
          align-items: center;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          height: 6.8vh;
          line-height: 3.4vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          //transform: translateY(-10px);

        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-color: #e9eef9;
          //background-image: url("../assets/servicesBanner2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          margin: 3vh 1vw;
          justify-content: center;
          align-items: center;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          height: 6.8vh;
          line-height: 3.4vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          //transform: translateY(-10px);

        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          background-color: #e9eef9;
          border-radius: 9px;
          position: relative;
          //background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          margin: 3vh 1vw;
          justify-content: center;
          align-items: center;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          height: 6.8vh;
          line-height: 3.4vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          //transform: translateY(-10px);

        }
      }
    }

    .pro1 {
      display: grid;
      width: 100%;
      height: fit-content;
      margin: auto;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

      column-gap: 1vw;

      .card {
        height: 100%;

        .back {
          width: 100%;
          height: 100%;
          border: #1c1e77 1px solid;
          border-radius: 9px;

        }
      }

      .card:hover {
        .back {
          cursor: pointer;
          background-color: #1c1e77;
          color: white;

          .self-build1 {
            content: url("../assets/bigmodel_white.png");
          }

          .self-build2 {
            content: url("../assets/business_white.png");
          }

          .self-build3 {
            content: url("../assets/knowledgeicon_white.png");
          }

          .self-build4 {
            content: url("../assets/CPU_white.png");
          }

          .self-build11 {
            content: url("../assets/more_btn_icon.png_white.png");
          }
        }
      }
    }

    .pro3 {
      border-radius: 9px;
      background-color: white;
      height: fit-content;
      width: 100%;
      padding: 4.8442521631644vh 8.09763142692085vw 8.8442521631644vh 8.09763142692085vw;

      .title {
        margin: 0 auto;
        text-align: center;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 3vw;
        //color: #53A3F7;
        font-family: Times New Roman;
        font-weight: 700;
        line-height: 8vh;
      }

      .title2 {
        margin: 0 auto;
        text-align: center;
        width: 60.96938186019642vw;
        font-size: 1.5vw;
        font-family: Times New Roman;
        font-weight: 400;
        color: #2C3E50;
        height: 8.7873918418vh;
        line-height: 6vh;
      }

      .banner {

        border-radius: 9px;
        height: 30.53559950556242vh;
        //margin-top: 8.44993819530284vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        //column-gap: 1vw;
        padding: 1.11248454882571vh 0.69324090121317vw;

        .back1 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1vw;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv1.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back4 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1vw;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/environment.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back2 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;
          border: 1px solid white;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1vw;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv2.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back3 {
          border: 1px solid white;
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1vw;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }


          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv3.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }
      }
    }

    .pro4 {
      height: 87.72805933250927vh;
      width: 100%;
      padding: 13.8442521632vh 7.1854419410745vw 0 7.1854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Times New Roman;
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        height: 48.83559950556242vh;
        margin-top: 4.44993819530284vh;
        width: 100%;
        column-gap: 1.61756210283073vw;
        padding: 1.11248454882571vh 1.69324090121317vw;
        position: relative;

        .carousel-group {
          padding: 0 2.76874638937031vw;
          display: flex;
        }

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .disabled-button {
          opacity: 0.5; /* Adjust the opacity as needed */
        }

        .disabled-buttonright {
          opacity: 0.5; /* Adjust the opacity as needed */
          background: gray;
          /* Add any other styles you want for the disabled state */
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 1.15540150202195vw;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .carousel-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48.83559950556242vh;
          margin: 0 0.5108030040439vw;
          padding: 0.98887515451174vh 0.69324090121317vw;

          opacity: 1;

          .back {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #2f6eff linear-gradient(to bottom, #cfe1ff 0%, #f0f6ff 100%);
            box-shadow: 3px 0px 8px 0px #d4e4ff;
            border-radius: 2px 2px 2px 2px;
            position: relative;

            .backlogo {
              margin: 3.11248454882571vh auto;
              text-align: center;
              height: 8.31520395550062vh;
              width: 8.3152039555vh;
            }

            .backtitle {
              margin-top: 2.81248454882571vh;
              text-align: center;
              height: 1.85414091470952vh;
              font-size: 1.5761821366vw;
              font-family: Times New Roman;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.05414091470952vh;
            }

            .backtext {
              padding: 0 1.5248454882571vw;
              margin-top: 3.01248454882571vh;
              //text-align: justify;
              font-size: 0.8087810514vw;
              font-family: Times New Roman;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.7194066749vh;
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Times New Roman;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Times New Roman;
              font-weight: 400;
              color: #2168db;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 6.55414091470952vw;
            }
          }
        }

        .carousel-item:hover {
          .backtitle {
            color: #2168db;
          }

          .backconsult {
            display: flex;
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Times New Roman;
            font-weight: 400;
            align-items: center;
            justify-content: center;
            height: 2.95414091470952vh;
            left: 5.55414091470952vw;

            color: transparent;
            /* 使用 @keyframes 定义颜色变化的动画 */
            animation: changeColor 0.2s ease-out 0.3s forwards;
          }

          /* 定义 @keyframes 动画, 从透明色到蓝色 */
          @keyframes changeColor {
            from {
              color: rgba(33, 104, 219, 0); /* 透明色 */
            }
            to {
              color: rgba(33, 104, 219, 1); /* 蓝色 */
            }
          }

          .backconsultArrow {
            transform: translateX(2vw);
            transition: transform 0.3s ease-out;
          }
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .pro5 {
      height: 45.0061804697157vh;
      width: 100vw;
      padding: 8.8442521632vh 12.42056614673599vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        height: 9.02348578491965vh;
        font-size: 2.1060658579vw;
        text-align: center;
        font-family: Times New Roman;
        font-weight: 500;
        color: #2f6eff;
        line-height: 2.84301606922126vh;
      }

      .highlight1 {
        margin: 3.44993819530284vh auto auto auto;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 5.56242274412855vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2168db;
        border-radius: 23px 23px 23px 23px;
        opacity: 1;
        font-size: 1.27vw;
        font-family: Times New Roman;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.84301606922126vh;
        cursor: pointer;
      }

      .highlight1:hover {
        color: #2168db;
        background: rgba(255, 255, 255, 0.5);
        border: #2168db 1px solid;
      }
    }

    .pro12 {
      height: fit-content;
      width: 100%;
      overflow-x: hidden;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 98%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;
        background-color: transparent;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          //height: 15%;
          height: fit-content;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 50.123vh;
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          margin: 2.2249690977vh auto 2.2249690977vh 1vw;
          //line-height: 35px;
          //opacity: 4;
          //margin: 2.2249690977vh auto 2.2249690977vh auto;
          //padding: 3vh 1vw 1vh 1vw;
          //text-align: center;
          //display: flex;
          //justify-content: center;
          //align-items: center;
          //height: 2.7194066749vh;
          //font-size: 1.8vw;
          //font-family: Times New Roman;
          //font-weight: bold;
          //line-height: 2.7194066749vh;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          //height: 100%;
          // text-align: justify;
          margin: auto 1vw 1.5249690977vh 0;
          font-size: 1.2vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }

  .screenGeneral {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 8vh 7.91854419410745vw;
    background: transparent;

    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;;
      opacity: 1;
      font-size: 3vw;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: 700;
      line-height: 8vh;
    }

    .light {
      margin: 2vh auto;
      text-align: left;
      width: fit-content;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 6vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 66.96938186019642vw;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 6vh;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand2 {
        cursor: pointer;
        width: 15.13575967648758vw;
        height: 42.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo {
          overflow: hidden;
          display: none;
        }

        .title1 {
          position: absolute;
          left: 1.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 0.97094165222415vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: 15%;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 50.123vh;
        }

        //.title1 {
        //      opacity: 4;
        //      text-align: left;
        //      display: flex;
        //      justify-content: center;
        //      align-items: center;
        //      font-size: 1.8vw;
        //      font-family: Times New Roman;
        //      font-weight: bold;
        //      color: white;
        //      //line-height: 35px;
        //    }
        //
        //.title2 {
        //  display: flex;
        //  justify-content: center;
        //  align-items: center;
        //  opacity: 4;
        //  //width: 100%;
        //  //height: 100%;
        //  // text-align: justify;
        //  margin: auto 0 5px 0;
        //  font-size: 1.2vw;
        //  font-family: Times New Roman;
        //  font-weight: 500;
        //  //color: #2168DB;
        //  //line-height: 35px;
        //}

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          margin: 2.2249690977vh auto 1.48vh 1vw;
          //line-height: 35px;
          //opacity: 4;
          //margin: 2.2249690977vh auto 2.2249690977vh auto;
          //padding: 3vh 1vw 1vh 1vw;
          //text-align: center;
          //display: flex;
          //justify-content: center;
          //align-items: center;
          //height: 2.7194066749vh;
          //font-size: 1.8vw;
          //font-family: Times New Roman;
          //font-weight: bold;
          //line-height: 2.7194066749vh;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          //height: 100%;
          // text-align: justify;
          margin: auto 1vw 1.5249690977vh 0;
          font-size: 1.2vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro3 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;

          height: fit-content;
          background: #ebf0fb;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .color {
          width: 100%;
          height: fit-content;
          background: linear-gradient(98.44deg, #faefdc 0%, #e5ebf8 50%, #faefdc 100%);

          text-align: center;
          justify-content: center;
          align-items: center;
          display: flex;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;

          .photo {
            overflow: hidden;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: fit-content;
          }

        }


        .title1 {
          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          height: 6vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
          height: 8.2vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        //.back {
        //  background-color: #2F6EFF;
        //}

        .photo {
          overflow: hidden;
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          transform: scale(1.05);
        }

        //.title1 {
        //  position: absolute;
        //  left: 1.85540150202195vw;
        //  top: 2.22496909765142vh;
        //  height: 2.71940667490729vh;
        //  color: white;
        //  font-size: 1.8vw;
        //}
        //
        //.title2 {
        //  margin-top: 8.22496909765142vh;
        //  text-align: justify;
        //  color: white;
        //  font-size: 0.80878105141537vw;
        //}

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }


      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 45vh;
        padding: 0 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          min-height: fit-content;
          background: #ebf0fb;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .color {
          width: 100%;
          height: fit-content;
          background: linear-gradient(98.44deg, #ADD8E6 0%, #e5ebf8 50%, #ADD8E6 100%);
          text-align: center;
          justify-content: center;
          align-items: center;
          display: flex;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;

          .photo {
            overflow: hidden;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent; /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: fit-content;
          }

        }


        .title1 {
          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          height: 6vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
          height: 8.2vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        //.back {
        //  background-color: #2F6EFF;
        //}

        .photo {
          overflow: hidden;
          transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
          transform: scale(1.05);
        }

        //.title1 {
        //  position: absolute;
        //  left: 1.85540150202195vw;
        //  top: 2.22496909765142vh;
        //  height: 2.71940667490729vh;
        //  color: white;
        //  font-size: 1.8vw;
        //}
        //
        //.title2 {
        //  margin-top: 8.22496909765142vh;
        //  text-align: justify;
        //  color: white;
        //  font-size: 0.80878105141537vw;
        //}
        //
        //.consult {
        //  cursor: pointer;
        //  display: block;
        //  color: white;
        //}

      }

    }
  }

  .wechaticon {
    width: 50px;
    height: 50px;
    /* background-color: #f0f0f0; */
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .screenGeneral1 {
    z-index: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 8vh 7.91854419410745vw;
    background: white;

    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;;
      opacity: 1;
      font-size: 3vw;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: 700;
      line-height: 8vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.9693818602vw;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .light {
      margin: 2vh auto;
      text-align: left;
      width: fit-content;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 6vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 66.96938186019642vw;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 6vh;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand2 {
        cursor: pointer;
        width: 15.13575967648758vw;
        height: 35.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #ebf0fb;;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          height: 4.73176761433869vh;
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          height: 14vh;
          text-overflow: ellipsis;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          background-color: rgb(135, 103, 215);
        }

        .photo {
          overflow: hidden;
          display: none;
        }

        .title1 {
          position: absolute;
          left: 1.85540150202195vw;
          top: 2.22496909765142vh;
          height: 2.71940667490729vh;
          color: white;
          font-size: 1.8vw;
        }

        .title2 {
          margin-top: 8.22496909765142vh;
          //text-align: justify;
          color: white;
          font-size: 0.80878105141537vw;
        }

        .consult {
          cursor: pointer;
          display: block;
          color: white;
        }

      }
    }

  }

  .screen4 {
    z-index: 10;
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 8.09763142692085vw;
    background: white;

    .title {
      margin: 0 auto;
      text-align: center;
      height: 8.23733003708282vh;
      opacity: 1;
      font-size: 2vw;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: 700;
      line-height: 8vh;
    }

    .light2 {
      margin: 5vh auto 0 auto;
      text-align: center;
      width: 60.9693818602vw;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 4.9443757726vh;

    }

    .light {
      margin: 2vh auto;
      text-align: left;
      width: 75vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 6vh;

    }


    .pro2 {
      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #ebf0fb;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
        }

        .photo {
          overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;

          .mengban {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 13%;
            background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
            opacity: 0.9;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              position: absolute;
              //height: 100%;
              color: white;
              margin-top: unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin: 2.22496909765142vh auto 2.22496909765142vh auto;
          padding: 3vh 1vw 0 1vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          //transform: translateY(-10px);

        }
      }
    }


  }

  .screen6 {

    width: 100%;
    height: fit-content;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    z-index: 10;
    //left: 10.86077411900635vw;
    border-radius: 0;

    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;;
      opacity: 1;
      font-size: 3vw;
      color: white;
      font-family: Times New Roman;
      font-weight: 700;
      line-height: 8vh;
    }

    .light {
      margin: 0 auto;
      text-align: center;
      width: 75vw;
      height: fit-content;
      font-size: 1.5vw;
      font-family: Times New Roman;
      font-weight: 400;
      color: white;
      line-height: 6vh;
    }

    .foot {
      margin-top: 3vh;
      text-align: center;

      .left1 {
        //border-top: 1px solid linear-gradient(to right,yellow, green, blue, red, indigo, violet,orange,);
      }

      .line {
        height: 0.2vh;
        width: 100%;
        background: linear-gradient(to right, orange, yellow, green, blue, red, indigo, violet);
      }

      .title1 {
        display: flex;
        text-align: left;
        align-items: center;
        font-size: 2.5vw;
        padding: 2vh 0;
        height: fit-content;
        color: green;
        font-weight: bold;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        font-family: Times New Roman;
      }

      .content {
        max-height: 0;
        min-height: 0;
        overflow: hidden;
        transition: max-height 2s ease, min-height 2s ease;
      }

      .content-visible {
        max-height: 40vh; /* 足够高的值以适应内容 */
        min-height: 0;
      }

      .content1 {
        max-height: 0;
        overflow: hidden;
        transition: max-height 2s ease;
      }

      .content-visible1 {
        max-height: 35vh; /* 足够高的值以适应内容 */
      }

      .content2 {
        max-height: 0;
        overflow: hidden;
        transition: max-height 2s ease;
      }

      .content-visible2 {
        max-height: 500px; /* 足够高的值以适应内容 */
      }

      .title2 {
        opacity: 4;
        color: white;
        width: 100%;
        text-align: left;
        height: fit-content;
        font-size: 1.5vw;
        font-family: Times New Roman;
        font-weight: 500;
        line-height: 22px;
      }


      .title3 {
        margin: 2vh auto;
        justify-content: flex-end;
        display: flex;
        text-align: right;
        align-items: center;
        font-size: 2vw;
        padding: 0 2vw 2vh 2vw;
        height: fit-content;
        font-weight: bold;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        transition: background-color 1s ease, background-image 1s ease;
        color: transparent;
        background: linear-gradient(to right, orange, yellow, green, blue, red, indigo, violet);
        background-clip: text;
        animation: shine 3s infinite linear;
      }

      @keyframes shine {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 100% 0;
        }
      }

      .flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        text-align: center;
        width: fit-content;
        color: #999999;
        margin: 0;
      }

      span {
        padding: 0 10px;
        color: #999999;
        font-size: 12px;
      }

      p:nth-child(1),
      p:nth-child(3) {
        font-size: 12px;
        font-family: Times New Roman;
        font-weight: 400;
        line-height: 30px;
      }

      p.red {
        color: #ef3e4f;
        /* font-weight: bold; */
        font-size: 12px;
        line-height: 30px;
      }

      p.red a {
        color: #999999;
      }
    }

    .companyIntroduce {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 3vh;

      .grand3 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
          height: 35vh;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {
            overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 15vw;
            margin: auto auto auto auto;
            height: fit-content;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            height: 100%;
            position: absolute;
            right: 0;
            opacity: 0.2;
          }

          .title {
            width: 100%;

            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 580px;
              margin: 3vh auto 0 1.355401502vw;
              height: 35px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              padding: 1vh 1.355401502vw;
              text-align: left;
              font-size: 1.5vw;
              font-family: Times New Roman;
              font-weight: 500;
              line-height: 4.5vh;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 3vh;
              right: 1.355401502vw;
              align-items: center;
              width: 368px;
              height: 25px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }

      .grand3:hover {
        .back {
          //transform: translateY(-10px);

        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
          height: 35vh;
          ////box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {
            overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 15vw;
            margin: auto auto auto auto;
            height: fit-content;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            height: 100%;
            position: absolute;
            right: 0;
            opacity: 0.2;
            left: 50%;
            transform: translateX(-50%);
          }

          .title {
            width: 100%;

            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 158px;
              margin: 3vh 0 0 1.355401502vw;
              height: 30px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              padding: 1vh 1.355401502vw;
              text-align: left;
              font-size: 1.5vw;
              font-family: Times New Roman;
              font-weight: 500;
              line-height: 4.5vh;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 3vh;
              right: 1.355401502vw;
              align-items: center;
              width: 450px;
              height: 25px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }

      .grand2:hover {
        .back {
          //transform: translateY(-10px);

        }
      }

      .grand4 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          display: flex;
          transition: transform 0.5s ease;
          height: 35vh;
          ////box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {
            overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 15vw;
            margin: auto auto auto auto;
            height: fit-content;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            height: 90%;
            position: absolute;
            right: 0;
            opacity: 0.2;
          }

          .title {
            width: 100%;

            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 524px;
              margin: 3vh 0 0 1.355401502vw;
              height: 32px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              padding: 1vh 1.355401502vw;
              text-align: left;
              font-size: 1.5vw;
              font-family: Times New Roman;
              font-weight: 500;
              line-height: 4.5vh;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 3vh;
              right: 1.355401502vw;
              align-items: center;
              width: 275px;
              height: 25px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }

      .grand4:hover {
        .back {
          //transform: translateY(-10px);

        }
      }
    }

    .pro1 {
      background-color: white;
      height: 115.3374536464771vh;
      width: 100%;
      padding: 13.8442521631644vh 7.91854419410745vw 0 7.91854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Times New Roman;
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        margin-top: 6.79851668726823vh;
        width: 100%;
        height: 4.94437577255871vh;
        display: grid;
        grid-template-columns:
          minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
          minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        border-bottom: 1px #2f6eff solid;
        text-align: center;
        opacity: 1;

        .bannertitle {
          cursor: pointer;
          height: 2.59579728059333vh;
          font-size: 1.8vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2F6EFF;
          line-height: 2.84301606922126vh;

          .span {
            margin-top: 1.7vh;
            margin-left: 2.855863662623vw;
            width: 5.19930675909879vw;
            border-bottom: 5px #2f6eff solid;
          }
        }
      }

      .card {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 1.8vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 1.8vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card2 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt2.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card3 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt3.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card4 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt4.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 5.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card5 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt5.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card6 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt6.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line4 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 4.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .card7 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt7.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line4 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 4.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button1:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

          .button2:hover {
            color: #2168db;
            border: #2168db 1px solid;
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;

      padding: 3vh 8.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 1vw;
      row-gap: 2vh;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          ////box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 260px;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;
          padding: 3vh 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 4.5vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          //transform: translateY(-10px);

        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 260px;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          //transform: translateY(-10px);

        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: fit-content;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner3.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .details {
            position: absolute;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2f6dfd;
            font-size: 0.80878105141537vw;
            bottom: 2.4437577255871vh;
            right: 1.05540150202195vw;
          }
        }

        .photo {
          overflow: hidden;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 260px;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          //box-shadow: -1px 1px 14px 0 #a2baf1;
        }

        .title1 {
          opacity: 4;
          text-align: center;

          padding: 3vh 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 1vw;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 3.4vh
        }

        .title2 {
          opacity: 4;
          margin: 1.9443757726vh 1.355401502vw 2.9443757726vh 1.355401502vw;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8087810514vw;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 2.7194066749vh;
        }


        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand3:hover {
        .back {
          //transform: translateY(-10px);

        }
      }
    }

    .pro3 {
      border-radius: 9px;
      background-color: transparent;
      height: fit-content;
      width: 100%;
      padding: 4.8442521631644vh 8.09763142692085vw 0 8.09763142692085vw;

      .title {
        margin: 0 auto;
        //text-align: left;
        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 3vw;
        //color: #53A3F7;
        font-family: Times New Roman;
        font-weight: 700;
        line-height: 8vh;
      }

      .title2 {
        //margin: 2vh auto;
        text-align: left;
        //width: 75vw;
        height: fit-content;
        font-size: 1.5vw;
        font-family: Times New Roman;
        font-weight: 400;
        color: white;
        line-height: 6vh;
      }

      .banner {

        border-radius: 9px;
        height: 30.53559950556242vh;
        //margin-top: 8.44993819530284vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;
        padding: 1.11248454882571vh 0.69324090121317vw;

        .back1 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1vw;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv1.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back4 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;

          border: 1px solid white;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1vw;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv4.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back2 {
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;
          border: 1px solid white;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1vw;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }

          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv2.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
            padding: 3.21384425216316vh 1vw;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }

        .back3 {
          border: 1px solid white;
          border-radius: 9px;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;

          .backTitle1 {
            position: absolute;
            top: 3.21384425216316vh;
            left: 1vw;
            height: 3.21384425216316vh;
            font-size: 1vw;
            font-family: Times New Roman;
            font-weight: 500;
            color: #2168db;
            line-height: 2.84301606922126vh;
          }

          .backTitle2 {
            position: absolute;
            top: 7.51384425216316vh;
            left: 1vw;
            width: 88%;
            font-size: 0.80878105141537vw;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            color: #2168db;
            //text-align: justify;
          }


          .background {
            border-radius: 9px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../assets/adv3.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s ease-out;
            overflow: hidden;
          }

          &:hover {
            .background {
              transform: scale(1.12);
            }
          }
        }
      }
    }


    .pro5 {
      height: 35.0061804697157vh;
      width: 100vw;
      padding: 3vh 12.42056614673599vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        margin-top: 5vh;
        height: 9.02348578491965vh;
        font-size: 2.1060658579vw;
        text-align: center;
        font-family: Times New Roman;
        font-weight: 500;
        color: #2f6eff;
        line-height: 2.84301606922126vh;
      }

      .highlight1 {
        margin: 3.44993819530284vh auto auto auto;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 5.56242274412855vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2168db;
        border-radius: 23px 23px 23px 23px;
        opacity: 1;
        font-size: 1.27vw;
        font-family: Times New Roman;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.84301606922126vh;
        cursor: pointer;
      }

      .highlight1:hover {
        color: #2168db;
        background: rgba(255, 255, 255, 0.5);
        border: #2168db 1px solid;
      }
    }
  }

}


.totalModel {
  background-color: white;
  //background: #015478;
  //background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .up {
    cursor: pointer;
    position: fixed;
    top: 50%;
    z-index: 5;
    right: 0;
    //background-color: #22317f;
    padding: 1px;
    width: 30px;
    height: fit-content;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border-radius: 50%;
    //border: 5px solid #1295d9;
    .uptop {
      width: 15px;
      height: 15px;
      margin: 5px auto 0 auto;
      background-image: url("../assets/up.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .upkefu {
      margin: 0.5vh auto;
      width: 15px;
      height: 15px;
      background-image: url("../assets/kefu2.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .wechaticon {
      width: 200px;
      height: 50px;
      background-color: #f0f0f0;
      transition: background-color 0.3s; /* 平滑过渡效果 */
    }

    .wechaticon.show-tooltip .tooltip {
      /* 当showTooltip为true时显示提示框 */
      opacity: 1;
    }

    .wechaticon:hover {
      background-color: #3498db; /* 鼠标悬停时的背景颜色 */
    }

    .feedback {
      cursor: pointer;
      width: fit-content;
      height: fit-content;
      font-weight: bold;
      color: white;
      font-size: 10px;
      margin: 0 auto;
      text-align: center;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      //border: 5px solid #1295d9;
    }
  }

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 80vh;
    background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
    //background-position: center;
    //background-size: cover;
    //background-repeat: no-repeat;
    //background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      .title1 {
        margin: 0 auto;
        width: fit-content;
        height: 23px;
        font-size: 23px;
        font-family: Times New Roman;
        font-weight: bold;
        color: #111111;
        line-height: 8.23733003708282vh;
      }

      .title2 {
        margin: 35px auto 0 auto;
        width: fit-content;
        height: 17px;
        font-size: 12px;
        font-family: Times New Roman;
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 20px auto 0 auto;
        width: fit-content;
        height: fit-content;
        font-size: 8px;
        font-family: Times New Roman;
        font-weight: bold;
        color: #2f6eff;
        line-height: 22px;
      }
    }

    .title4img {
      position: absolute;
      bottom: 5px;
      cursor: pointer;
      height: 5.5vh;
      width: 2vw;
      left: 48%;
      transform: translateX(-50%);
      animation: down 2s infinite;
      background-image: url("../assets/colordown.png");
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
    }

    .title4img:hover {
      background-color: transparent !important;
    }

    @keyframes down {
      0% {
        transform: translateY(-0.5vh); /* 原始大小 */
      }
      50% {
        transform: translateY(0.5vh); /* 原始大小 */
      }
      100% {
        transform: translateY(-0.5vh); /* 原始大小 */
      }
    }

    .title3img {
      position: absolute;
      top: 145px;
      cursor: pointer;
      height: 1.5vh;
      width: 1.5vw;
      left: 46%;
      //z-index: ;
      transform: translateX(-50%);
      animation: pulse 2s infinite;
      background-image: url("../assets/focusdown.png");
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
    }

    .title3img :hover {
      background-color: transparent;
    }

    @keyframes pulse {
      0% {
        transform: translateY(0.5vh); /* 原始大小 */
      }

      100% {
        transform: translateY(0); /* 原始大小 */
      }
    }

    .layer3 {
      //position: absolute;
      opacity: 1;
      //left: 0;
      //bottom: 0;
      //background-color: #bfd9f9;
      width: 100%;
      justify-content: center;

      .communicity {
        //z-index: 8;
        cursor: pointer;
        font-size: 16px;
        font-family: Times New Roman;
        font-weight: 400;
        color: #2f6eff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

      .communicityGpt {
        //z-index: 8;
        cursor: pointer;
        font-size: 16px;
        font-family: Times New Roman;
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

    }
  }

  .screen3 {
    //z-index: 10;
    height: fit-content;
    padding: 25px 4.6875vw;
    background: rgba(255, 255, 255, 1);

    .title {
      margin: 0 auto;
      //text-align: center;
      height: fit-content;
      opacity: 1;
      //font-size: 20px;
      //color: #53A3F7;
      font-family: Times New Roman;;
      //font-family:Times New Roman;
      font-weight: bold;
      //line-height: 26px;
      color: #122278;
      font-size: 30px;
      text-align: left;
      line-height: 40px
    }

    .light {
      //margin: 10px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0
    }

    .light2 {
      //margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          margin-top: 5px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 22px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 60vw;
          //display: -webkit-box;
          //-webkit-box-orient: vertical;
          //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          //text-overflow: ellipsis;
          margin: 0 0 7px 0
          //white-space: nowrap;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          margin: auto 0 10px 0;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro11 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 260px;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 100%;
        }

        .title1 {
          text-align: left;
          //font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 58vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
          margin: 0 0 7px 0;
          font-size: 22px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          margin: auto 0 10px 0;
          // text-align: justify;
          font-size: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 100%;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 10px 0;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro3 {
      .grand1 {
        display: grid;
        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 10px;
        height: fit-content;

        .cards {

        }
      }
    }
  }

  .screenConsult {
    //z-index: 10;
    height: fit-content;
    padding: 25px 4.6875vw;
    background: rgba(255, 255, 255, 1);

    .title {
      margin: 0 auto;
      //text-align: center;
      height: fit-content;
      opacity: 1;
      //font-size: 20px;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: bold;
      //line-height: 26px;
      font-size: 30px;
      text-align: left;
      line-height: 40px;
    }

    .light {
      //margin: 10px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0;
    }

    .light2 {
      //margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          //background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          //border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;

          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 100%;

          ::v-deep .img {
            border-radius: 9px;
          }
        }

        .title1 {
          //text-align: left;
          font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 10px 0;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          //font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 55vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
          margin: 0 0 7px 0;
          font-size: 4.5vw;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          margin: auto 0 10px 0;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro3 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 110px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

    }
  }

  .screenConsult1 {
    //z-index: 10;
    height: fit-content;
    padding: 25px 4.6875vw;
    background: rgba(255, 255, 255, 1);

    .title {
      margin: 0 auto;
      //text-align: center;
      height: fit-content;
      opacity: 1;
      //font-size: 20px;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: bold;
      //line-height: 26px;
      color: #122278;
      font-size: 28px;
      text-align: left;
      line-height: 40px
    }

    .light {
      //margin: 10px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0
    }

    .light2 {
      //margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0
    }

    .pro3 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: fit-content;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          //background-color: white;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          //border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;

          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 250px;

          ::v-deep .img {
            border-radius: 9px;
          }
        }

        .title1 {
          //text-align: left;
          font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 10px 0;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }


    .pro4 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 260px;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color: white;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 100%;
        }

        .title1 {
          text-align: left;
          //font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
          //text-align: left;
          margin: 0 0 7px 0;
          font-size: 22px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          margin: auto 0 10px 0;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }

  .screenGeneral {
    //z-index: 10;
    height: fit-content;
    padding: 25px 4.6875vw;
    background: rgba(255, 255, 255, 1);

    .grid1 {
      opacity: 0 !important;
      transform: translateY(20px);


    }

    .grid1.visible {
      opacity: 1 !important;
      transform: translateY(0);
      transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    }
    .title {
      margin: 0 auto;
      //text-align: center;
      height: fit-content;
      opacity: 1;
      //font-size: 20px;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: bold;
      //line-height: 26px;
      font-size: 30px;
      text-align: left;
      line-height: 40px;
    }

    .light {
      //margin: 10px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0;
    }

    .light2 {
      //margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 2px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 9px 4px 0 4px;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: white;;
          padding: 10px 5px 6px 5px;
          //box-shadow: 1px 0 2px 0px rgba(115, 115, 115, 0.5);
          border: 1px solid #C9C9C9;
          border-radius: 9px;
          //display: flex;
        }

        .photo {
          overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 8px auto 6px auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 35px;
          height: 40px;
        }

        .title1 {
          opacity: 4;
          margin: 2px auto 8px auto;
          text-align: center;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: Times New Roman;
          font-weight: normal;
          color: #0a0a0a;
          line-height: 20px;
        }

        .title2 {
          width: 110px;
          opacity: 4;
          margin: 5px 3px 5px 0;
          height: 14px;
          word-wrap: break-word; //换行
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }
    }

  }

  .screen6 {
    //z-index: 10;
    height: fit-content;
    padding: 0px 15px 0 15px;
    background: white;

    .title {
      margin: 0 auto 15px auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 10px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      line-height: 20px;
    }

    .foot {
      margin-top: 15px;
      text-align: center;

      .left1 {
        border-top: 1px solid #e9e8e4;
      }

      .title1 {
        display: flex;
        text-align: left;
        align-items: center;
        font-size: 18px;
        padding: 15px 5px 10px 5px;
        height: fit-content;
        color: #2c3e4f;
        font-weight: bold;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        font-family: Times New Roman;
      }

      .content {
        max-height: 0;
        min-height: 0;
        overflow: hidden;
        transition: max-height 2s ease, min-height 2s ease;
      }

      .content-visible {
        max-height: 500px; /* 足够高的值以适应内容 */
        min-height: 0;
      }

      .content1 {
        max-height: 0;
        overflow: hidden;
        transition: max-height 2s ease;
      }

      .content-visible1 {
        max-height: 500px; /* 足够高的值以适应内容 */
      }

      .content2 {
        max-height: 0;
        overflow: hidden;
        transition: max-height 2s ease;
      }

      .content-visible2 {
        max-height: 500px; /* 足够高的值以适应内容 */
      }

      .title2 {
        opacity: 4;
        color: white;
        width: 100%;
        text-align: left;
        height: fit-content;
        font-size: 14px;
        font-family: Times New Roman;
        font-weight: 500;
        line-height: 22px;
      }


      .title3 {
        margin: 10px auto;
        justify-content: flex-end;
        display: flex;
        text-align: right;
        align-items: center;
        font-size: 16px;
        padding: 0 5px;
        height: fit-content;
        font-weight: bold;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        transition: background-color 1s ease, background-image 1s ease;
        color: transparent;
        background: linear-gradient(to right, orange, yellow, green, blue, red, indigo, violet);
        background-clip: text;
        animation: shine 3s infinite linear;
      }

      @keyframes shine {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 100% 0;
        }
      }

      .flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        text-align: center;
        width: fit-content;
        color: #999999;
        margin: 0;
      }

      span {
        padding: 0 10px;
        color: #999999;
        font-size: 12px;
      }

      p:nth-child(1),
      p:nth-child(3) {
        font-size: 12px;
        font-family: Times New Roman;
        font-weight: 400;
        line-height: 30px;
      }

      p.red {
        color: #ef3e4f;
        /* font-weight: bold; */
        font-size: 12px;
        line-height: 30px;
      }

      p.red a {
        color: #999999;
      }
    }

    .companyIntroduce {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;

      .grand3 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 280px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {
            overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 59px;
            margin: 10px auto auto 20px;
            height: 10px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            top: 30%;
            width: 100%;
            position: absolute;
            right: 0;
            opacity: 0.2;
          }

          .title {
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 273px;
              margin: 25px auto 10px auto;
              height: 18px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              margin-top: -2px;
              padding: 0 20px 35px 10px;
              text-align: left;
              height: fit-content;
              font-size: 12px;
              font-family: Times New Roman;
              font-weight: 500;
              line-height: 22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right: 10px;
              align-items: center;
              width: 223px;
              height: 14px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 280px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {
            overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 59px;
            margin: 10px auto auto 20px;
            height: 10px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            top: 8%;
            width: 100%;
            position: absolute;
            right: 0;
            opacity: 0.2;
          }

          .title {
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 89px;
              margin: 25px auto 10px auto;
              height: 17px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              margin-top: -2px;
              padding: 0 20px 35px 10px;
              text-align: left;
              height: fit-content;
              font-size: 12px;
              font-family: Times New Roman;
              font-weight: 500;
              line-height: 22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right: 10px;
              align-items: center;
              width: 280px;
              height: 14px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }


      .grand4 {
        cursor: pointer;
        width: 100%;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;


        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 270px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          //background-color: white;
          background-image: url("../assets/companyBackground2.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          .photo {
            overflow: hidden;

            justify-content: center;
            text-align: center;
            align-items: center;
            width: 97px;
            margin: 10px auto auto 20px;
            height: 20px;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            //box-shadow: -1px 1px 14px 0 #a2baf1;
          }

          .photoHidden {
            top: 8%;
            width: 100%;
            position: absolute;
            right: 0;
            opacity: 0.2;
          }

          .title {
            .title1 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              width: 275px;
              margin: 25px auto 10px auto;
              height: 17px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }

            .title2 {
              opacity: 4;
              color: white;
              width: 100%;
              margin-top: 10px;
              padding: 0 20px 35px 10px;
              text-align: left;
              height: fit-content;
              font-size: 12px;
              font-family: Times New Roman;
              font-weight: 500;
              line-height: 22px;
            }


            .title3 {
              position: absolute;
              display: flex;
              justify-content: center;
              text-align: right;
              bottom: 20px;
              right: 10px;
              align-items: center;
              width: 154px;
              height: 14px;
              border-top-right-radius: 9px;
              border-top-left-radius: 9px;
            }
          }
        ;


        }

      }


    }

    .pro1 {
      background-color: white;
      height: 115.3374536464771vh;
      width: 100%;
      padding: 13.8442521631644vh 7.91854419410745vw 0 7.91854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Times New Roman;
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        margin-top: 6.79851668726823vh;
        width: 100%;
        height: 4.94437577255871vh;
        display: grid;
        grid-template-columns:
          minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
          minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        border-bottom: 1px #2f6eff solid;
        text-align: center;
        opacity: 1;

        .bannertitle {
          cursor: pointer;
          height: 2.59579728059333vh;
          font-size: 0.97094165222415vw;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2F6EFF;
          line-height: 2.84301606922126vh;

          .span {
            margin-top: 1.7vh;
            margin-left: 2.855863662623vw;
            width: 5.19930675909879vw;
            border-bottom: 5px #2f6eff solid;
          }
        }
      }

      .card {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }
        }
      }

      .card2 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt2.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card3 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt3.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card4 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt4.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 5.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card5 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt5.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line2 {
          margin-top: 3.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 3.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card6 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt6.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line4 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 4.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }

      .card7 {
        margin: 2.22496909765142vh 1vw;
        background-image: url("../assets/gpt7.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 66.77268232385661vh;
        padding: 14.33868974042027vh 0 13.96786155747837vh 3.98613518197574vw;

        .line1 {
          height: 5.31520395550062vh;
          font-size: 2.71519352975159vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 2.84301606922126vh;
        }

        .line4 {
          margin-top: 2.31520395550062vh;
          width: 25.01444251877527vw;
          //text-align: justify;
          height: 18.41779975278121vh;
          font-size: 0.955401502vw;
          font-family: Times New Roman;
          font-weight: 400;
          //color: #2168DB;
          line-height: 3.95550061804697vh;
        }

        .line3 {
          margin-top: 4.31520395550062vh;
          width: 20.01444251877527vw;
          display: flex;
          height: 5.56242274412855vh;

          border-radius: 23px 23px 23px 23px;
          opacity: 1;

          .button1 {
            width: 10.39861351819757vw;
            height: 100%;
            background: #2168db;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            cursor: pointer;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 2.84301606922126vh;
          }

          .button2 {
            cursor: pointer;
            margin-left: 1.55979202772964vw;
            width: 10.39861351819757vw;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: transparent;
            border-radius: 23px 23px 23px 23px;
            opacity: 1;
            font-size: 0.97094165222415vw;
            font-family: Times New Roman;
            font-weight: 400;
            color: #2168db;
            border: #2168db 1px solid;
            line-height: 2.84301606922126vh;
          }

        }
      }
    }

    .pro2 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 15px;

      .grand1 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 220px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 15px 2px 12px 2px;
          text-align: center;
          height: fit-content;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Times New Roman;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding: 0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          //text-align: justify;
          height: fit-content;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 20px;
        }


        .consult {
          position: absolute;
          bottom: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right: 15px;
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 220px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 20px 2px 12px 2px;
          text-align: center;
          height: fit-content;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Times New Roman;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding: 0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          //text-align: justify;
          height: fit-content;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 20px;
        }


        .consult {
          position: absolute;
          bottom: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right: 15px;
        }
      }


      .grand3 {
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          transition: transform 0.5s ease;
          height: 220px;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
          background-image: url("../assets/servicesBanner1.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .photo {
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }


        .title1 {
          opacity: 4;
          margin: 15px 2px 12px 2px;
          text-align: center;
          height: fit-content;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Times New Roman;
          //font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0 5px;
          padding: 0 5px 45px 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;

          //text-align: justify;
          height: fit-content;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          line-height: 20px;
        }


        .consult {
          position: absolute;
          bottom: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          font-size: 12px;
          color: #2F6EFF;
          right: 15px;
        }
      }


    }

    .pro3 {
      margin-left: -20px;
      background-color: transparent;
      height: fit-content;
      width: 100vw;
      padding: 0 15px 10px 15px;
      position: relative;
      //background-image: url("../assets/adv.jpg");
      //background-size: cover;
      //background-position: center;
      //background-repeat: no-repeat;
      .back {
        width: 100%;
        height: 500px;
        background-image: url("../assets/advantage.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .title {
        text-align: center;
        height: fit-content;
        opacity: 1;
        //font-size: 20px;
        //color: #53A3F7;
        font-family: Times New Roman;
        font-weight: 700;
        //line-height: 26px;
        font-size: 30px;
        //text-align: left;
        line-height: 40px;
      }

      .title2 {
        //margin:0 2vw 10px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 15px;
        font-family: Times New Roman;
        font-weight: 400;
        color: #2C3E50;
        //line-height: 20px;
        //font-size: 17px;
        line-height: 23px;
        margin: 10px 0;
      }

      .banner {
        height: fit-content;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 15px;

        .back1 {
          cursor: pointer;
          position: relative;
          width: 100%;
          height: fit-content;
          //box-shadow: 5px 0 2px 0px #D4E4FF;
          overflow: hidden;
          opacity: 1;
          //background: #D4E2FF;
          .backTitle1 {
            //position: absolute;
            margin-top: 5px;
            color: #2168db;
            height: 32px;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 30px;
          }

          .span {
            margin: 2px auto;
            width: 150px;
            text-align: center;
            background-color: #ffffff;
            height: 2px;
          }

          .backTitle2 {
            margin: 5px auto 0 auto;
            height: fit-content;
            width: 95%;
            text-align: center;
            font-size: 12px;
            font-family: Times New Roman;
            font-weight: 400;
            line-height: 2.71940667490729vh;
            opacity: 1;
            //color: #2168db;
          }
        }

      }
    }

    .pro4 {
      height: 87.72805933250927vh;
      width: 100%;
      padding: 13.8442521632vh 7.1854419410745vw 0 7.1854419410745vw;

      .title {
        text-align: center;
        height: 8.77626699629172vh;
        font-size: 3.5060658579vw;
        font-family: Times New Roman;
        font-weight: 400;
        //color: #2F6EFF;
        line-height: 2.84301606922126vh;
      }

      .banner {
        height: 48.83559950556242vh;
        margin-top: 4.44993819530284vh;
        width: 100%;
        column-gap: 1.61756210283073vw;
        padding: 1.11248454882571vh 1.69324090121317vw;
        position: relative;

        .carousel-group {
          padding: 0 2.76874638937031vw;
          display: flex;
        }

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .disabled-button {
          opacity: 0.5; /* Adjust the opacity as needed */
        }

        .disabled-buttonright {
          opacity: 0.5; /* Adjust the opacity as needed */
          background: gray;
          /* Add any other styles you want for the disabled state */
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: #2168db;
          color: #fff;
          position: absolute;
          top: 50%;
          right: 1.15540150202195vw;
          //z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .carousel-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48.83559950556242vh;
          margin: 0 0.5108030040439vw;
          padding: 0.98887515451174vh 0.69324090121317vw;

          opacity: 1;

          .back {
            cursor: pointer;
            width: 100%;
            height: 100%;
            background: #2f6eff linear-gradient(to bottom, #cfe1ff 0%, #f0f6ff 100%);
            box-shadow: 3px 0px 8px 0px #d4e4ff;
            border-radius: 2px 2px 2px 2px;
            position: relative;

            .backlogo {
              margin: 3.11248454882571vh auto;
              text-align: center;
              height: 8.31520395550062vh;
              width: 8.3152039555vh;
            }

            .backtitle {
              margin-top: 2.81248454882571vh;
              text-align: center;
              height: 1.85414091470952vh;
              font-size: 1.5761821366vw;
              font-family: Times New Roman;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.05414091470952vh;
            }

            .backtext {
              padding: 0 1.5248454882571vw;
              margin-top: 3.01248454882571vh;
              ////text-align: justify;
              font-size: 0.8087810514vw;
              font-family: Times New Roman;
              font-weight: 500;
              //color: #2168DB;
              line-height: 2.7194066749vh;
            }

            .backconsult {
              display: none;
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Times New Roman;
              font-weight: 400;
              //color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 5.55414091470952vw;
            }

            .backconsultArrow {
              position: absolute;
              bottom: 1.81248454882571vh;
              text-align: center;
              font-size: 0.9665511265vw;
              font-family: Times New Roman;
              font-weight: 400;
              color: #2168db;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              height: 2.95414091470952vh;
              left: 6.55414091470952vw;
            }
          }
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .pro5 {
      height: fit-content;
      width: 100vw;
      margin-left: -20px;
      padding: 25px 4.6875vw;
      background-image: url("../assets/bottom.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .highlight {
        text-align: center;
        height: fit-content;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Times New Roman;
        font-weight: 700;
        line-height: 26px;
      }

      .highlight1 {
        margin: 20px auto auto auto;
        cursor: pointer;
        font-size: 17px;
        font-family: Times New Roman;
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 10px 4.6875vw;
        height: 40px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden;
      }

    }
  }

  .screen7 {

    width: 100%;
    bottom: 0;
    height: fit-content;
    background-color: #333333;

    .foot {
      padding-top: 10px;
      text-align: center;
      border-top: 1px solid #373b4f;

      .flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        text-align: center;
        width: fit-content;
        color: #999999;
        margin: 0;
      }

      span {
        padding: 0 10px;
        color: #999999;
        font-size: 12px;
      }

      p:nth-child(1),
      p:nth-child(3) {
        font-size: 12px;
        font-family: Times New Roman;
        font-weight: 400;
        line-height: 30px;
      }

      p.red {
        color: #ef3e4f;
        /* font-weight: bold; */
        font-size: 12px;
        line-height: 30px;
      }

      p.red a {
        color: #999999;
      }
    }

    .yuming {
      color: white;
    }

    .left {
      display: flex;
      color: #ffffff;

      .left1 {
        width: 9.51415366839977vw;
        font-size: 0.8709416522vw;
        font-family: Times New Roman;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.33745364647713vh;
      }

      .left2 {
        margin-left: 4.15944540727903vw;
        width: 20.51415366839977vw;
        font-size: 0.8709416522vw;
        font-family: Times New Roman;
        font-weight: 400;
        color: #ffffff;
        line-height: 4.33745364647713vh;
      }
    }

    .right {
      margin-top: -1vh;
      display: flex;
      color: #ffffff;

      .left1 {
        width: 7.23916811091854vw;
        font-size: 0.8709416522vw;
        font-family: Times New Roman;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 3.33745364647713vh;

        .up {
          width: 100%;
          height: auto;
          background-image: url("../assets/attention.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }

      .left2 {
        margin-left: 4.15944540727903vw;
        width: 7.23916811091854vw;
        font-size: 0.8709416522vw;
        font-family: Times New Roman;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 3.33745364647713vh;

        .up {
          width: 100%;
          height: auto;
          background-image: url("../assets/attention.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

}

.news1 {
  //background-color: white;

  .communicate::-webkit-scrollbar-track-piece {
    background-color: #f8f8f800;
  }

  .communicate::-webkit-scrollbar {
    width: 6px;
    transition: all 2s;
  }

  .communicate::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 100px;
  }

  .communicate::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }

  .communicate::-webkit-scrollbar-corner {
    background-color: rgba(255, 255, 255, 0);
  }

  .talk_con {
    //background-image: url("../assets/aiBg.png");
    background: transparent;
    border-radius: 9px;
    //border: #000000 2px solid;
    //height: 88vh;
    position: absolute;
    width: 100%;
    height: 62vh;
    margin: 0 auto;
    //border: #1c0101 5px solid;
  }

  .talk_show {
    width: 60%;
    height: 70vh;
    margin: 0 20% 0 20%;
    padding-top: 2%;
    //border:black 2px solid;
    /* overflow:auto; */
    overflow-y: scroll;
    /* overflow: hidden; */
  }

  .content {
    background-color: transparent;
    width: 100%;
    margin: 0 auto 0 auto;
    height: 90%;
    border-radius: 15px;
    //border: 5px solid black;
    .tip {
      margin-top: 15px;
      display: grid;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 10px;
    }

    .tips {
      cursor: pointer;
      width: 100%;
      border-radius: 5px;
      background: linear-gradient(to right, rgb(230, 250, 250) 0%, #f1e4d3 100%);
      height: fit-content;
      padding: 10px;

      .circle {
        width: 15px;
        height: 15px;
        background-image: url("../assets/Circle.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
      }

      .highlight {
        font-size: 12px;
        color: #333333;
        font-weight: bold;
        margin: -2px 10px;
      }

      .title {
        font-size: 12px;
        font-weight: bold;
        color: #666666;
        margin: 10px 0 0 0;
        text-align: justify;
      }
    }
  }

  .communicate {
    height: 100%;
    padding: 0 5% 5% 5%;
    border-top: transparent 2px solid;
    width: 100%;
    background: transparent;
    font-size: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    //border: 5px solid black;
  }

  //.arrow-content {
  //  padding: 10px;
  //  margin:5px 1% auto auto;
  //  position: relative;
  //  display: inline-block;
  //  text-align: left;
  //  color: #000;
  //  border-radius: 5px;
  //  background-color: rgba(46, 128, 5, 0.6);
  //}
  .triangle1 {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 8px 8px; /* 调整边框宽度, 0表示无顶部边框 */
    border-color: transparent transparent transparent rgba(46, 128, 5, 0.6); /* 调整边框颜色 */
    top: 15px; /* 控制三角形位置 */
    right: -14px; /* 控制三角形位置 */
    transform: translateY(-50%); /* 让三角形垂直居中 */
  }

  .right {
    text-align: left;
  }

  .talk_sub {
    position: absolute;
    cursor: pointer; /* 鼠标变成手型 */
    width: 6vh;
    height: 25px;
    float: left;
    margin-top: -4vh;
    bottom: 15px;
    right: 5px;
    padding: 8px 10px;
    margin-left: 88%;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    background-image: url("../assets/send.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .talk {
    display: inline-block;
    background-color: white;
    border-radius: 5px;
    color: #000;
    margin: 8px 10% auto 5px;
    width: 100%;
    padding: 5px 10px;
    word-wrap: break-word;
    position: relative; /* Add this */
  }

  /* 添加尖嘴（三角形） */
  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 8px 8px; /* 调整边框宽度, 0表示无顶部边框 */
    border-color: transparent #fffcfc transparent transparent; /* 调整边框颜色 */
    top: 15px; /* 控制三角形位置 */
    left: -15px; /* 控制三角形位置 */
    transform: translateY(-50%); /* 让三角形垂直居中 */
  }

  .select {
    background-image: url("../assets/button.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    margin-top: auto;
    bottom: 5px;
    border: none;
  }

  .select1 {
    width: 25px;
    height: 20px;
    margin-top: auto;
    bottom: 0px;
    border: none;
  }

  ::v-deep .talkcontent .el-input__inner {
    width: 100%;
    background-color: #e8e8e8;
    text-align: center;
    border-color: #c0c4cc;
    color: #000;
  }

  ::v-deep .el-textarea .el-input__count {
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 20px;
    bottom: 5px;
    left: 10px;
  }

  ::v-deep .el-textarea__inner {
    border: none !important;
    font-size: 20px !important;
    height: 86px;
    width: 98%;
  }

  .talkcontent {
    margin-left: 1%;
    min-height: 82px;
    white-space: pre-wrap; /* 使文本根据内容自动换行 */
    outline: none;
    width: 98%;
    max-height: 82px;
    padding: 3px;
    float: left;
    text-indent: 10px;
    border-radius: 8px;
    overflow: auto;
    //border: black 5px solid; background-color: white; /* 半透明白色, alpha值为0.5 */
  }

  .atalk {
    margin: 5px;
    padding: 5px;
    display: flex;
    //background-color: white;
    position: relative;
    align-items: flex-start; /* Align items at the top */
  }

  .btalk {
    margin: 2px;
    right: 0 !important;
    display: flex;
    flex-direction: column;
    flex-grow: 8;
  }

  .AIphotoMove {
    min-width: 25px;
    background-image: url("../assets/AIphoto.png");
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20%;
    margin-top: 5px;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1); /* 原始大小 */
    }
    50% {
      transform: scale(1.1); /* 放大到120% */
    }
    100% {
      transform: scale(1); /* 恢复到原始大小 */
    }
  }

  .AIphoto {
    min-width: 25px;
    background-image: url("../assets/AIphotoNo.png");
    width: 25px;
    height: 25px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20%;
    margin-top: 5px;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

  }


  .userPhoto {
    //background-image: url("../assets/userPhoto.png"); min-width: 30px; margin-top: 3px; position: relative;
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-left: 8px;
    font-size: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin-top: 5px;
  }

  .arrow-content {
    background-color: #0267ff;
    border-radius: 5px;
    color: white;
    margin: 8px 5px auto 10px;
    padding: 5px 10px;
    display: inline-block;
    min-height: 30px;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .title {
    color: black;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .whotalk {
    min-height: 88px;
    outline: none;
    max-height: 25px;
    padding: 5px;
    width: 80% !important;
    border-radius: 8px;
    border: none;
    //border-right: black 1px solid; width: 10%;
  }

  .talk_input {
    overflow: visible;
    display: flex; /* 启用Flexbox布局 */
    flex-direction: column; /* 子元素垂直排列 */
    align-items: center; /* 拉伸子元素以填充容器 */
    justify-content: center;;
    left: 50%;
    transform: translateX(-50%);
    height: fit-content;
    border-radius: 5px;
    //box-shadow: 0 2px 0 2px rgba(100, 98, 98, 0.1);
    //border: rgba(44, 62, 80, 0.1) 1px solid;
    background-color: transparent;
    position: absolute;
    bottom: 2%;
    width: 90%;
  }

  .input_container {
    height: fit-content;
    //border: black 1px solid;
    background-color: transparent;
    display: flex; /* 使用 Flexbox 布局 */
    align-items: center; /* 垂直居中 */
    width: 100%;
  }

  .talk_word {
    border: gray 1px solid !important;
    border-radius: 9px !important;
    resize: vertical; /* 允许垂直调整大小 */
    min-height: 25px;
    height: auto;
    overflow-y: hidden;
    max-height: 200px;
    outline: none;
    width: 85vw;
    padding: 10px;
    text-indent: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

  }

  .talk_button {
    display: flex;
    justify-content: space-between; /* 子元素之间的间隔平均分布 */
    cursor: pointer; /* 鼠标变成手型 */
    width: fit-content;

    padding: 10px;
    margin: auto 0 0 0;
  }
}
</style>
