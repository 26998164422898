<template>
  <div>
    <div v-if="model===false">

    </div>
    <div v-else>
      <div class="totalModel" ref="totalModel">
        <div class="screen1">
          <kefuMobile @topMobile="topTopmobile"></kefuMobile>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->     <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div v-zoom-in="{duration:800}" class="title1" style="font-weight: bolder;text-align: center;font-size: 25px;line-height: 30px;color:#F44336">Software Customized Services</div>
            <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size: 18px;line-height: 28px;color:#2c3e50">Provide customized development services based on individual oilfield enterprise needs, achieving efficient management and intelligent optimization.</div>
          </div>
          <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')" style="font-weight: bolder;width: 150px">
            Product Inquiry
          </div>
<!--          <div v-zoom-in="{duration:800}" class="title4">*The generalization ability of the Oil & Gas AI model is limited, so it may need to be adapted and optimized according to specific scenes and needs in practical applications. <br>①&nbsp;Design-oriented services are provided by Antonoil's offline technical personnel<br>②&nbsp;GPT development services are provided by Antonoil's AI professionals</div>-->

          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!-- <div class="screenmake0">
          <div class="heighLight">油藏地质模型架构</div>
          <div class="heightLight2">以油藏地质研究及各个应用场景数据为中心，通过解释脑形成知识成果，智能优化油藏地质研究成果，从而达到油藏地质知识智能解释处理、为智能钻井压裂等应用场景提供油藏地质知识的目的。</div>
          <div class="grid">
            <div class="card1">
              <div class="back">
                <div class="imgDesign">
                  <img src="@/assets/gptModel12.png">
                </div>
              </div>
            </div>
          </div>

        </div> -->
        <div class="screenmake2">
          <div v-sliden-in="{duration:800}" class="heighLight">   <span>Software Customized Services</span></div>
          <div class="grid">
            <div v-sliden-in="{duration:800}" class="card">
              <div class="back" >
                <!--              <div class="back" @click="detail">-->
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Customized Software Design and Development Services</div>
                  <div class="Title2">
                    Tailor-made software applications to meet customer business needs, ensuring efficient delivery and excellent performance.
                  </div>
<!--                  <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">-->
<!--                    developing...-->
<!--                  </div>-->
                  <!--                  <div class="backconsult"  @click="detail">-->
                  <!--                    developing...-->
                  <!--                  </div>-->
                  <!--                  <div class="backconsultArrow">-->
                  <!--                    <i style="font-size: 12px;margin-left: 33px;" class="el-icon-right"></i>-->
                  <!--                  </div>-->
                </div>
              </div>


            </div>
            <div v-sliden-in="{duration:800}" class="card1" style="width: 100%">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Software Deployment and Maintenance Services</div>
                  <div class="Title2">
                    Provide full-process product support from installation and deployment to daily maintenance, ensuring stable system operation and high availability.
<!--                <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">-->
<!--                  developing...-->
<!--                </div>-->
                  </div>    </div>
              </div>
            </div>
            <div v-sliden-in="{duration:800}" class="card2" style="width: 100%">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="Title1">Software Development Talent Outsourcing Services</div>
                  <div class="Title2">
                    Provide professional software development team support to meet customer needs in project development and technical maintenance for short-term or long-term requirements.
                  </div>
<!--                  <div class="backconsult"  style="font-weight: bolder;color: #eb7c31">-->
<!--                    developing...-->
<!--                  </div>-->
                </div>
              </div>


            </div>

          </div>

        </div>
<!--        <div class="screenmake3">-->
<!--          <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>-->
<!--          <div class="grid">-->
<!--            <el-carousel indicator-position="outside">-->
<!--              <el-carousel-item v-for="item in 1" :key="item">-->
<!--                <div class="card">-->
<!--                  <div class="back">-->
<!--                    <div v-zoom-in="{duration:800}" class="imgDesign">-->
<!--                      <img src="@/assets/gpt16.png">-->
<!--                    </div>-->
<!--                    <div v-zoom-in="{duration:800}" class="titleDesign">-->
<!--                      <div style="font-size: 14px; text-align: center;">Fracturing Project For Shale Gas Wells Of An Oil & Gas Field Branch In XX</div>-->
<!--                      <div class="title1">In 2022, Anton provided fracturing engineering services for an Oil & Gas field branch, increasing the average EUR of shale gas wells in Changning block by 18% synchronously, and in Yuxi block by more than 10% year-on-year; and the average daily production of wells tested under the new process of tight gas fracturing amounted to 409,800 m3, which is 25% higher than that of the conventional process.</div>-->
<!--                      &lt;!&ndash; <div class="title2">1.精细三维地应力预测及裂缝检测</div>-->
<!--                      <div class="title2">2.新井精细跟踪服务</div>-->
<!--                      <div class="title2">3.老井生产动态跟踪分析及评价</div> &ndash;&gt;-->
<!--                    </div>-->
<!--                  </div>-->


<!--                </div>-->


<!--              </el-carousel-item>-->
<!--            </el-carousel>-->
<!--          </div>-->

<!--        </div>-->
        <bottomTip></bottomTip>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
               <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import PartnerTip from "@/components/partnerTip.vue";
import consultTip from "@/components/consultTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import {shareUrl} from "@/utils/vxshare";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
  },
  name: "",

  props: [],

  components: {bottomTip, consultTip, PartnerTip, headtip,kefuMobile},

  data() {

  return {changeVisable:1,
      title:'',
      pageTitle: 'Design GPTs_Design large model artificial intelligence application in the Oil & Gas industry-Oil & Gas Communication GPTs',
      pageDescription: 'The Oil & Gas GPT Cloud Platform provides design-oriented GPTs that are tailored to formulate scientific, reasonable, and feasible design plans based on various factors such as geological conditions, stratigraphic structure, engineering requirements, production targets, and more, in the target area. This aims to achieve efficient, safe, and economic development of Oil & Gas resources.',
      pageKeywords: 'Design GPTs, design-type GPTs, fracturing design plan parameter optimization, drilling engineering plan design, Oil & Gas field development design, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud platform, Oil & Gas communication large model, Oil & Gas communication GPT large model',
      model:false,
      dialogconsult:false,
      dialogVisible:false,
    }

  },

  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
        topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    detail(){
      window.open("/assisted", "_blank");
    },
    showPartner() {
      this.dialogconsult = true;
    },

    hideDialog() {
      this.dialogVisible = false;
    },
    hide() {
      this.dialogconsult = false;
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Design GPTs_Design large model artificial intelligence application in the Oil & Gas industry-Oil & Gas Communication GPTs',
        pageDescription: 'The Oil & Gas GPT Cloud Platform provides design-oriented GPTs that are tailored to formulate scientific, reasonable, and feasible design plans based on various factors such as geological conditions, stratigraphic structure, engineering requirements, production targets, and more, in the target area. This aims to achieve efficient, safe, and economic development of Oil & Gas resources.',
        pageKeywords: 'Design GPTs, design-type GPTs, fracturing design plan parameter optimization, drilling engineering plan design, Oil & Gas field development design, Oil & Gas communication, Oil & Gas communication GPT, Oil & Gas communication GPT cloud platform, Oil & Gas communication large model, Oil & Gas communication GPT large model',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>

.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 687px;
    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
    background-image: url("../../assets/gptModel11.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */
    .titlle3{
      position: absolute;
      bottom:80px;
      left:50%;
      transform: translateX(-60%);
      font-size: 12px;
       font-family: 'Times New Roman';
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9.1px;
      width: fit-content;
      margin: 0 15px;
      padding: 5px 4.6875vw;
      line-height: 15px;
      height: 35px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;


    }
    .title4{
      position: absolute;
      left:5%;
      bottom:-115px;
      margin: 35px auto 0 auto;
      width: 90%;
      height: fit-content;
      font-size: 12px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #111111;
      line-height: 18px;
      border-radius: 15px;
      padding: 15px;
      background:white;
      border: #e8eefa 1px solid;

    }
    .layer2 {
      position: absolute;
      left:0;

      .title1 {
        margin:15px auto 0 auto;
        width: fit-content;
        //text-align: center;
        height: fit-content;
        font-size: 22px;
        font-family: 'Times New Roman';
        font-weight: 500;
        color: #111111;
        line-height: 22px;
      }

      .title2 {
        margin: 16px auto;
        width:85%;
        //text-align: center;
        height: fit-content;
        font-size: 12px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #111111;
        line-height: 20px;
      }
    }

  }
  .screenmake2{
    padding:20px 4.6875vw 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: #ebf1fd;
    .heighLight {
      text-align: center;
      height: 20px;
      opacity: 1;
      font-size: 21px;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;

    }

    .grid{
      margin-top:2.78739184177998vh ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/software21.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 225px;
              display: flex;
              justify-content: center;
              text-align: center;
              height:fit-content;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
               font-family: 'Times New Roman';
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              //display: -webkit-box;
              //-webkit-box-orient: vertical;
              //-webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              //overflow: hidden;
              //text-overflow: ellipsis;
              margin: 8px 12px 35px 12px;
              //text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card2{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/software23.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 185px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 14px;
               font-family: 'Times New Roman';
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              //display: -webkit-box;
              //-webkit-box-orient: vertical;
              //-webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              //overflow: hidden;
              //text-overflow: ellipsis;
              margin: 8px 12px 35px 12px;
              //text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card4{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            height: 170px;
            opacity: 1;
            background-image: url("../../assets/software14.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 185px;
              display: flex;
              justify-content: center;
              text-align: center;
              height: fit-content;
              font-size: 14px;
               font-family: 'Times New Roman';
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              //display: -webkit-box;
              //-webkit-box-orient: vertical;
              //-webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              //overflow: hidden;
              //text-overflow: ellipsis;
              margin: 8px 12px 35px 12px;
              //text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
      .card1{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;
            overflow:hidden;
            width: 100%;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 185px;
            opacity: 1;
            background-image: url("../../assets/software22.png");
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            .Title1{
              margin-top: 200px;
              display: flex;
              justify-content: center;
              text-align: center;
              height:fit-content;
              font-size: 14px;
               font-family: 'Times New Roman';
              font-weight: 500;
              color:#111111;
              line-height: 2.7194066749vh;
            }
            .Title2{
              //display: -webkit-box;
              //-webkit-box-orient: vertical;
              //-webkit-line-clamp: 5; /* 限制在一个块元素显示的文本的行数 */
              //overflow: hidden;
              //text-overflow: ellipsis;
              margin: 8px 12px 35px 12px;
              //text-align: justify;
              height: fit-content;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              line-height: 22px;
            }
            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
              color: #2168DB;
            }
            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 12px;
              font-family: 'Times New Roman';
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    padding:20px 15px;
    background: #212a3c;
    .heighLight{
      text-align: center;
      height:21px;
      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: 'Times New Roman';
      font-weight: 700;
      line-height: 26px;
    }
    .heightLight2{
      margin:19px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 12px;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }
    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 280px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #4D85F7 ;
        .back{
          border-radius: 5px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 500px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:214px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              margin-top:15px;
              font-size: 12px;
              line-height: 20px;
            }
            .title2{
              margin-top: 15px;
              line-height: 22px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }
          }
        }
      }

      ::v-deep .el-carousel__container{
        height: 450px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;
        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
</style>
