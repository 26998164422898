<script >
import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    slidenIn:vSlidenIn
  },
  methods:{
    clickConsult(itemName){
      console.log("1231232132131aaaa")
      this.$emit("clickConsult",itemName)
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },
    knowledge1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/gptKnowledge")
    },
    data1() {
      this.$router.push("/gptData")
      sessionStorage.setItem("skip", 10)
    },
    wisdom1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/wisdom");
    },
    plat1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/plat")
    },
    design1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/design")
    },
    automation1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/automation")
    },

    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    traningAi1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company2")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
  }
}
</script>

<template>
  <div class="customizationGPTs" style="height: fit-content;background-color:white;">
    <div v-sliden-in="{}" class="customizationTitle">
      3. Customized Artificial Intelligence Services
    </div>
    <div class="card" style="display: flex;height: fit-content">
<!--      <div style=";width:10%;">-->
<!--        <div style="display: flex;z-index: 2;background-color:white;;position: sticky;top:70px">-->
<!--        <span style="margin: 25px auto ;font-weight: 700;font-size: 35px">-->
<!--          1-->
<!--         </span>-->
<!--        </div>-->
<!--      </div>-->
      <!--    油气场景GPT应用开发-->
      <div ref="professional1" style="background-color:white;;width:100%" class="screen3">
        <div class="Customizedtitle" >
          <div v-sliden-in="{}" class="title" style="color:black;font-size: 26px;text-align: left">① AI Development for Oil & Gas Engineering</div>
        </div>
          <div v-sliden-in="{}" class="light">
            Based on engineering technology enterprises such as Anton.
            <span class="light2" style="color: #193fe0"><br>For use in more diverse business scenarios,
                 <span style="white-space: nowrap;"><span
                     style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('AI Development for Oil & Gas Engineering')">Contact Us</span>.</span></span>
          </div>
        <div class="pro1">
          <!--              <div class="swiper-container1">-->
          <!--                <div class="swiper-wrapper">-->
          <!--                  <div class="swiper-slide" @click="oilgpt1">-->
            <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="oilgpt1">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/pro1.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1">Engineering Scheme Design Software</div>
                <div class="title2">
                  MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
            <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="oilgpt21">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/pro7.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> Geological Analysis Software</div>
                <div class="title2">
                  MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
            <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="oilgpt31">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/pro2.jpg">
                </div>
              </div>
              <div class="back1" style=" box-shadow: none">
                <div class="title1"> Production Process Optimization Software</div>
                <div class="title2">
                  MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>
        </div>

      </div>
    </div >
    <div class="card" style="display: flex;height: fit-content">
<!--      <div style=";width:10%;">-->
<!--        <div style="display: flex;z-index: 2;background-color:white;;position: sticky;top:70px">-->
<!--        <span style="margin: 25px auto ;font-weight: 700;font-size: 35px">-->
<!--          4.2-->
<!--         </span>-->
<!--        </div>-->

<!--      </div>-->
      <!--    油气开发作业GPT应用服务-->
      <div ref="Development" class="screenConsult1" style="background-color: transparent ;width:100%">
        <div class="Customizedtitle">
          <div v-sliden-in="{}" style="display: flex;margin:0 auto;" ref="upload">
            <div style="display: flex;text-align: left">
              <div class="title" style="color:black;font-size: 26px;text-align: left" >② AI Field Application Services
              </div>
            </div>
          </div>
        </div>
        <div v-sliden-in="{}" class="light">
          GPT-assisted on-site operations in the oil and gas industry.
          <span class="light2" style="color: #193fe0"> <br>For better usage effect in on-site operations and quick implementation,
                 <span style="white-space: nowrap;"><span
                     style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('AI Field Application Services')">Contact Us</span>.</span></span>

        </div>

        <div class="pro4">

          <div class="grand1" v-sliden-in="{}" style="position: relative;height: fit-content" @click="ground1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px;" src="@/assets/pro1.png">
              </div>
            </div>
            <div class="back1">
              <div class="title1">Intelligent Reservoir<br>Geology</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>

          <div class="grand1" v-sliden-in="{}" style="position: relative;height: fit-content" @click="hole1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px;" src="@/assets/pro2.png">
              </div>
            </div>
            <div class="back1">
              <div class="title1"> Intelligent <br>Drilling</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>

          <div class="grand1" v-sliden-in="{}" style="position: relative;height: fit-content" @click="frack1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px;" src="@/assets/pro3.png">
              </div>
            </div>
            <div class="back1">
              <div class="title1"> Intelligent <br>Fracturing</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>

          <div class="grand1" v-sliden-in="{}" style="position: relative;height: fit-content" @click="getOil1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px;" src="@/assets/pro4.png">
              </div>
            </div>
            <div class="back1">
              <div class="title1">Intelligent <br>Recovery</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>

          <div class="grand1" v-sliden-in="{}" style="position: relative;height: fit-content" @click="monitor1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px;" src="@/assets/pro5.png">
              </div>
            </div>
            <div class="back1">
              <div class="title1"> Formation <br>Monitoring</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>

          <div class="grand1" v-sliden-in="{}" style="position: relative;height: fit-content" @click="equipment1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px;" src="@/assets/pro6.png">
              </div>
            </div>
            <div class="back1">
              <div class="title1"> Equipment And <br>Facility Monitoring</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>

          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="swiper-pagination"></div>-->
          <!--              </div>-->

        </div>


      </div>
    </div >
    <div class="card" style="display: flex;height: fit-content">
<!--      <div style=";width:10%;">-->
<!--        <div style="display: flex;z-index: 2;background-color:white;;position: sticky;top:70px">-->
<!--        <span style="margin: 25px auto ;font-weight: 700;font-size: 35px">-->
<!--          4.3-->
<!--         </span>-->
<!--        </div>-->
<!--      </div>-->
      <!--    企业运营提效GPTs-->
      <div class="screenConsult1" style="background-color:white;;width: 100%;" ref="menageServices">
        <div class="Customizedtitle">
          <div v-sliden-in="{}" class="title" style="color:black;font-size: 26px">③ Enterprise Management GPTs</div>
        </div>
          <div v-sliden-in="{}" class="light">
            Trained based on the specific enterprise environment.
            <span class="light2" style="color: #193fe0"><br> Customize training based on the enterprise's management environment. Only case and demonstration services are offered.
For further communication,
               <span style="white-space: nowrap;"><span
                   style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('Enterprise Management GPTs')">Contact Us</span>.</span></span>
          </div>
        <div class="pro4">
          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="knowledge1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/knowledgenew1.jpg">
              </div>
            </div>
            <div class="back1" style=" box-shadow: none">
              <div class="title1">Enterprise Management GPT</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>
          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="data1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/knowledgenew2.png">
              </div>
            </div>
            <div class="back1" style=" box-shadow: none">
              <div class="title1"> Digital intelligence Integrated Solution
              </div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
            <!--                    </div>-->
          </div>
          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="wisdom1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/knowledgenew3.jpg">
              </div>
            </div>
            <div class="back1" style=" box-shadow: none">
              <div class="title1"> Data-driven Decision-making Platform</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>
          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="design1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/knowledgenew4.png">
              </div>
            </div>
            <div class="back1" style=" box-shadow: none">
              <div class="title1">Smart Employee Community</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>
          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="plat1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/knowledgenew5.png">
              </div>
            </div>
            <div class="back1" style="box-shadow: none">
              <div class="title1"> Smart E-commerce Platform</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>
          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="automation1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/knowledgenew6.png">
              </div>
            </div>
            <div class="back1" style=";box-shadow: none">
              <div class="title1"> Management Process Automation Platform
              </div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>
        </div>


      </div>
    </div >
    <div class="card" style="display: flex;height: fit-content">
<!--      <div style=";width:10%;">-->
<!--        <div style="display: flex;z-index: 2;background-color:white;;position: sticky;top:70px">-->
<!--        <span style="margin: 25px auto ;font-weight: 700;font-size: 35px">-->
<!--          4.4-->
<!--         </span>-->
<!--        </div>-->

<!--      </div>-->
      <!--    油气企业自建GPT云平台服务-->
      <div class="screen3" style="background-color:white;;width:100%" ref="companyConduct">
        <div class="Customizedtitle">
          <div v-sliden-in="{}" class="title" style="color:black;font-size: 26px;text-align: left">④ Enterprise self-built AI platform Services
          </div>
        </div>
        <div v-sliden-in="{}" class="light">
          Assist client construct an AI cloud platform in a private environment.
          <span class="light2" style="color: #193fe0"><br>For building GPT cloud platform needs, <span style="white-space: nowrap;"><span
              style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px"
              @click="clickConsult('Enterprise self-built AI platform Services')">Contact Us</span>.</span></span>
        </div>

        <div class="pro11">

          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="constructPlat1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/zijian1.png">
              </div>
            </div>
            <div class="back1" style=" box-shadow: none">
              <div class="title1" style="font-size: 22px"> Oil & Gas Enterprise Large Model Cloud Platform
              </div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>

          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="business1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/zijian2.png">
              </div>
            </div>
            <div class="back1" style=" box-shadow: none">
              <div class="title1"> AI Development for Business Scenarios</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>

          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="company1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/zijian3.png">
              </div>
            </div>
            <div class="back1" style=" box-shadow: none">
              <div class="title1"> Data Governance</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>

          <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="computingPower1">
            <div class="back">
              <div class="photo">
                <img style="border-radius: 9px" src="@/assets/zijian4.png">
              </div>
            </div>
            <div class="back1" style=" box-shadow: none">
              <div class="title1"> Computing Power Optimization</div>
              <div class="title2">
                MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div >
    <div class="card" style="display: flex;height: fit-content">
<!--      <div style=";width:10%;">-->
<!--        <div style="display: flex;z-index: 2;background-color:white;;position: sticky;top:70px">-->
<!--        <span style="margin: 25px auto ;font-weight: 700;font-size: 35px">-->
<!--          4.5-->
<!--         </span>-->
<!--        </div>-->

<!--      </div>-->
      <!--    人工智能全员普及培训人工智能全员普及培训-->
      <div class="screen3" ref="traningConsult" style="background-color: transparent ;width:100%">
        <div class="Customizedtitle">
          <div v-sliden-in="{}" class="title" style="color:black;font-size: 26px;text-align: left">⑤ AI Training & Transformation
          </div>
        </div>
          <div v-sliden-in="{}" class="light" style="">
            Formulation, design and consultation of AI transformation planning.
            <span class="light2" style="color: #193fe0"><br>For training and transformation consulting needs,
                <span style="white-space: nowrap;"><span
                    style="color: white;cursor: pointer;background-color: #333f88;padding: 1px 2px;border-radius: 5px" @click="clickConsult('AI Training & Transformation')">Contact Us</span>.</span></span>
          </div>

        <div class="pro1">
            <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="traningAi1">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/puji2.jpg">
                </div>
              </div>
              <div class="back1" style="box-shadow: none">
                <div class="title1"> AI Application<br>Training</div>
                <div class="title2">
                  MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>

            <div v-sliden-in="{}" class="grand1" style="position: relative;height: fit-content" @click="consult1">
              <div class="back">
                <div class="photo">
                  <img style="border-radius: 9px" src="@/assets/puji11.png">
                </div>
              </div>
              <div class="back1" style="box-shadow: none">
                <div class="title1"> AI Transformation<br>Consulting</div>
                <div class="title2">
                  MORE<i style="margin-left: 5px" class="el-icon-arrow-right"></i>

                </div>
              </div>
            </div>

        </div>

      </div>
    </div >

  </div>
</template>

<style scoped lang="scss">
.customizationGPTs{
  background-color:white;;
  width: 100%;
  .customizationTitle{
    background-color:white;
    margin: 0 auto;
    text-align: center;
    //height: -moz-fit-content;
    height: fit-content;
    opacity: 1;
    //font-size: 25px;
    //font-family: Roboto, sans-serif;
    font-weight: bold;
    //line-height: 26px;
    position: sticky;
    padding:10px 0;
    //background-color:white;;
    z-index: 4;
    top: -1px;
    //position: sticky;


    //margin: 0 auto;
    color: rgb(18, 34, 120);
    //text-align: center;
    //height: fit-content;
    //opacity: 1;
    font-family: Times New Roman;
    //font-weight: bold;
    //font-size: 30px;
    font-size: 7.4vw;
    ////text-align: left;
    line-height: 40px;
  }
  .screen3 {
    //z-index: 10;
    height: fit-content;
    padding: 25px 4.6875vw;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .title {
      margin: 0 auto;
      //text-align: center;
      height: fit-content;
      opacity: 1;
      //font-size: 20px;
      //color: #53A3F7;
      font-family: Times New Roman;;
      //font-family:Times New Roman;
      font-weight: bold;
      //line-height: 26px;
      color: #122278;
      font-size: 30px;
      text-align: left;
      line-height: 30px
    }

    .light {
      //margin: 10px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0
    }

    .light2 {
      //margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color:white;;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          margin-top: 5px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 22px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 60vw;
          //display: -webkit-box;
          //-webkit-box-orient: vertical;
          //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          //text-overflow: ellipsis;
          margin: 0 0 7px 0
          //white-space: nowrap;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          margin: auto 0 10px 0;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro11 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 260px;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color:white;;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 100%;
        }

        .title1 {
          text-align: left;
          //font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 58vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
          //margin: 0 0 7px 0;
          font-size: 22px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          margin: auto 0 10px 0;
          // text-align: justify;
          font-size: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color:white;;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          //border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          border: #dddddd 3px solid;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 100%;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 10px 0;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro3 {
      .grand1 {
        display: grid;
        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 10px;
        height: fit-content;

        .cards {

        }
      }
    }
  }
  .screen3::before{
    content: "";
    position: absolute;
    left: -5vw;
    width: 4px;
    height: 95%;
z-index
: 1;
    background: #d8d8d8;
  }
  .screenConsult1 {
    //z-index: 10;
    height: fit-content;
    padding: 10px 4.6875vw;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .title {
      margin: 0 auto;
      //text-align: center;
      height: fit-content;
      opacity: 1;
      //font-size: 20px;
      //color: #53A3F7;
      font-family: Times New Roman;
      font-weight: bold;
      //line-height: 26px;
      color: #122278;
      font-size: 28px;
      text-align: left;
      line-height: 30px
    }

    .light {
      //margin: 10px 2vw;
      //text-align: justify;
      width: fit-content;
      height: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #2C3E50;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0
    }

    .light2 {
      //margin: 20px auto 0 auto;
      text-align: center;
      width: fit-content;
      //font-size: 14px;
      font-family: Times New Roman;
      font-weight: 400;
      color: #FF6400;
      //line-height: 20px;
      font-size: 17px;
      line-height: 23px;
      margin: 10px 0
    }

    .pro3 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;


      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        height: fit-content;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          //background-color:white;;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          //border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;

          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;

          border-radius: 9px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 250px;

          ::v-deep .img {
            border-radius: 9px;
          }
        }

        .title1 {
          //text-align: left;
          font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin: auto 0 10px 0;
          font-size: 12px;
          font-family: Times New Roman;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }


    .pro4 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 260px;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color:white;;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background: linear-gradient(to top, rgba(27, 30, 118) 0%, rgba(27, 30, 118) 50%, transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }

        .photo {
          overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 100%;
        }

        .title1 {
          text-align: left;
          //font-size: 18px;
          font-family: Times New Roman;
          font-weight: bold;
          color: white;
          text-overflow: ellipsis;
          width: 50vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          //-webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          //white-space: nowrap;
          //line-height: 35px;
          //text-align: left;
          //margin: 0 0 7px 0;
          font-size: 22px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 15px;
          font-family: Times New Roman;
          font-weight: 500;
          margin: auto 0 10px 0;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }
  }
  .screenConsult1::before{
    content: "";
    position: absolute;
    left: -5vw;
    width: 4px;
    height: 100%;
    z-index
    : 1;
    background: #d8d8d8;
  }
  .screenConsult {
    //z-index: 10;
    height: fit-content;
    padding: 20px 15px;
    background: rgba(255, 255, 255, 1);
    position: relative;
    .title {
      margin: 0 auto;
      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Roboto,sans-serif;
      font-weight: bold;
      line-height: 26px;
    }

    .light {
      margin: 15px auto;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .light2 {
      margin: 20px auto 0 auto;
      text-align: justify;
      width: fit-content;
      font-size: 16px;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #FF6400;
      line-height: 25px;
    }

    .pro1 {
      height: fit-content;
      width: 100%;
      overflow-x: auto;
      //z-index: 89156;

      .swiper-container {
        width: 100%;
        height: fit-content;
      }
      .swiper-slide {
        text-align: center;
        //font-size: 18px;
        //background-color: #d4e4ff;
        /* Center slide text vertically */
        display: flex;
        //margin-top: 50px;
        justify-content: center;
        align-items: center;
      }
      .swiper-pagination {
        position: relative;
        margin-top: 10px;
        background-color: black;
        //z-index: 10;
      }

      .swiper-pagination-bullet {
        background: black; /* 设置分页器圆点的背景颜色 */
        opacity: 0.5; /* 设置透明度 */
        width: 10px; /* 圆点宽度 */
        height: 10px; /* 圆点高度 */
        border: 1px solid #ccc; /* 圆点边框 */
        border-radius: 50%; /* 圆点边框圆角 */
        margin: 0 5px; /* 圆点间距 */

      }

      .swiper-pagination-bullet-active {
        opacity: 1; /* 设置激活状态的透明度 */
      }
      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 0.98887515451174vh 0  0 0;
        opacity: 1;
        height: 250px;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          //background-color:white;;
          ////box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          //border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          //background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          ////box-shadow: 5px 0 14px 0 #d4e4ff;

          // border-radius:6px;
          display: flex;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;

          border-radius: 9px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          //background-color: #d4e4ff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          height: 100%;
          ::v-deep .img{
            border-radius: 9px;
          }
        }

        .title1 {
          opacity: 4;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          color: white;
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          margin:auto 0 0 auto;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-radius: 6px;
        padding: 15px 0  0 0;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: fit-content;
          //background: #ffffff linear-gradient(to right, #cfe1ff 0%, #f0f6ff 100%);
          background-color:white;;
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border-radius: 9px;
          border: white 2px solid;
          // border-radius:6px;
          // display: flex;
        }
        .back1 {
          bottom: 0;
          position: absolute;
          width: 100%;
          color: white;
          height: fit-content;
          background:linear-gradient( to top , rgba(27, 30, 118) 0%,  rgba(27, 30, 118) 50%,transparent 100%);
          //box-shadow: 5px 0 14px 0 #d4e4ff;
          border: white 2px solid;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
        }
        .photo { overflow: hidden;
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 100%;
          border-radius: 9px;
          height: 260px;
        }

        .title1 {
          text-align: left;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          //text-overflow: ellipsis;
          text-overflow: ellipsis;

          width: 40vw;
          overflow: hidden;
          height: fit-content;
          //white-space: nowrap;/
          //line-height: 35px;
        }

        .title2 {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 4;
          //width: 100%;
          height: 100%;
          // text-align: justify;
          font-size: 16px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          margin:auto 0 0 auto;
          //color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .pro3 {
      height: fit-content;
      width: 100%;
      //z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2 {
        cursor: pointer;
        width: fit-content;
        min-height: 180px;
        opacity: 1;
        position: relative;
        padding: 9px 6px;

        .back {
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 2px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo { overflow: hidden;
          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 110px;
          height: 30px;
        }

        .title1 {
          opacity: 4;
          margin-top: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18px;
          font-size: 18px;
          font-family: Roboto,sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 18px;
        }

        .title2 {
          opacity: 4;
          margin: 15px 8px 20px 8px;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 16px;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto,sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

    }
  }
  .screenConsult::before{
    content: "";
    position: absolute;
    left: -5vw;
    width: 4px;
    height: 100%;
    z-index
    : 1;
    background: #d8d8d8;
  }
}
.Customizedtitle{
  top:97px;z-index: 3;background-color:white;;padding-bottom: 5px
}
</style>
