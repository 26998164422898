<script >
import {vMoveInDuration} from "@/utils/vMoveInDuration";
export default {
  directives:{
    moveInDuration:vMoveInDuration
  },
  data(){
    return{
      cilentHeight:window.innerHeight,
    }
  },
  methods:{
    ToBigModel(){
      window.open("https://www.oilgasinfoai.com/AiPop")
    },
    ToInfo(){
      window.open("https://www.oilgasinfoai.com/")
    },
    NavigatTo(refName){
      console.log("this.$parent", this.$parent.$parent.$refs[refName])
      // 使用 this.$refs 访问目标元素
      let distanceY = this.$parent.$parent.$refs[refName].getBoundingClientRect().top;
      console.log('------',distanceY)
      console.log('window.scrollY------',window.scrollY)
      // 使用 scrollIntoView 方法滚动到该元素s
      document.getElementById("totalModel").scrollBy({
        top: distanceY-window.scrollY, // 向下滚动100像素
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<template>
<div class="navigationContainer">
  <div class="part0"  style="background-color: white;border:#adade0 dashed 1px;display: flex;height: 130px;padding: 10px;gap: 5px">
    <div class="part0_2" @click="ToBigModel" style="width: 50%;height: 100%;background-color: black;display: flex;">
            <span style="
    font-weight: bolder;
    margin: auto;
    color: white;
    font-size: 5vw;
     line-height: 6vw">
      Free Oil & Gas<br> INFO GPT
            </span>
    </div>
    <div class="part0_1" @click="ToInfo"  style="width: 50%;height: 100%;background-color: black;display: flex">
            <span style="
    font-weight: bolder;
    margin: auto;
    color: white;
    font-size: 5vw;
    line-height: 6vw">
              Free Oil & Gas<br>INFO
      </span>
    </div>
  </div>
  <div class="part3" style="width: 100%;display: grid;height:70px;grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr)">
    <div  @click="NavigatTo('station')" class="part3_1" style=" ">
      <span>
              Concomitant Smart<br>Oilfield Solution
      </span>
    </div>
    <div @click="NavigatTo('professional')" class="part3_3" style=" ">
      <span>
              Customized AI
      </span>

    </div>
    <div @click="NavigatTo('software')" class="part3_2" style=" ">
      <span>
              Smart Oilfield<br>Software
      </span>

    </div>

    <div @click="NavigatTo('media')" class="part3_4" style=" ">
      <span>
              Intelligent Multimedia
      </span>

    </div>
  </div>
<!--  <div style="display: flex;width: 100%;height: 230px">-->
<!--    <div class="part2" @click="NavigatTo('oilZixun')" v-move-in-duration="{duration:2000,direction:'x',offset:'-100'}">-->
<!--      <div class="freeGPTs" style="display: flex" >-->
<!--        <div style="margin: auto">-->
<!--          <div class="title" style="height: 7vh;margin: auto">-->
<!--            <span style="text-align: center">Concomitant Smart Oilfield</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="part1">-->
<!--      <div class="part1_2" >-->
<!--        <div class="part1_2_2" @click="NavigatTo('station')" v-move-in-duration="{duration:2000,direction:'Y',offset:'-100'}" style="height: 100%;width: 50%;display: flex;">-->
<!--          <div class="mediumGPTs" style="width: 100%;height: 100%;display: flex">-->
<!--            <div class="title" style="margin: auto">-->
<!--              <span >Smart Oilfield Software</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="part1_2_1" @click="NavigatTo('media')" v-move-in-duration="{duration:2000,direction:'x',offset:'100'}" style="height: 100%;width: 50%;display: flex">-->
<!--          <div class="solutinsGPTs" style="width: 100%;height: 100%;display: flex">-->
<!--            <div class="title" style="margin: auto">-->
<!--              <span>Intelligent Multimedia</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--      <div class="part1_1" @click="NavigatTo('professional')" v-move-in-duration="{duration:2000,direction:'Y',offset:'100'}">-->
<!--        <div class="customizationGPTs">-->
<!--          <div class="title">-->
<!--            <span >Customized Artificial Intelligence</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->


</div>
</template>

<style scoped lang="scss">
.part3{
  margin-top: 2vh;
  background-size: cover;
  padding: 0 2vw;
  gap: 1vw;
  span{
    font-weight: bolder;
    display: flex;
    margin: auto;
    color: white;
    font-size: 3vw;
  }
  .part3_1{
    display: flex;
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.4), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.4)
        ), url("../../assets/homeNavigation2.png");
    background-size: cover;
  }
  .part3_2{
    display: flex;
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.4), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.4)
        ),  url("../../assets/homeNavigation6.png");
    background-size: cover;
  }
  .part3_3{
    display: flex;
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.4), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.4)
        ),  url("../../assets/homeNavigation3.png");
    background-size: cover;
  }
  .part3_4{
    display: flex;
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.4), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.4)
        ),  url("../../assets/homeNavigation4.png");
    background-size: cover;
  }
}
.part0{

  .part0_1{
    //background-image: url("");
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.2), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.2)
        ), url("../../assets/zhinengzixun.png");
    background-size: cover;
  }
  .part0_2{
    background-image:
        linear-gradient(
                rgba(0, 0, 0, 0.2), /* 蒙版的颜色 */
                rgba(0, 0, 0, 0.2)
        ), url("../../assets/deeplearning.png");
    background-size: cover;
  }
}
.title{
  height: 5vh;width: 100%;padding: 0.5vh;display: flex;
  img{
    object-fit: contain;width: fit-content;padding-left: 2vw
  }
  span{
    display: flex;align-items: center;padding-left:1vw;font-weight: bolder;color: white;font-size: 4vw;line-height: 4vw;text-align: center;
  }
}
.titlebuttons{
  width: 100%;height: fit-content;padding: 1vh;padding-left: 3vw;display: flex;gap: 1vw;flex-wrap:wrap;
  .buttonCard{
    height: 3vh;display: flex;padding: 0.4vh;width:11vw;
    ::v-deep img{
      object-fit: contain;width: fit-content
    }
    span{
      align-items: center;display: flex;padding-left: 1vw;font-weight: bolder
    }
  }
}
.navigationContainer{
  //background-color: blue;
  .title{
    margin: auto;
    width: fit-content;
  }
  //display: flex;
  width: 100%;
  height: fit-content;
  .part1{
    width: 65%;height: 100%;padding: 10px 10px 10px 5px;column-gap: 5px;
    .part1_1{
      background-image:
          linear-gradient(
                  rgba(0, 0, 0, 0.39), /* 蒙版的颜色 */
                  rgba(0, 0, 0, 0.39)
          ), url("../../assets/homeNavigation3.png");
      background-size:cover;
      width: 100%;height: 50%;display: flex;
      .customizationGPTs{
        width: 100%;height: 100%;margin: auto;display: flex;

        .titlebuttons{
          width: 100%;height: fit-content;padding: 1vh;padding-left: 3vw;display: flex;gap: 1vw;flex-wrap:wrap;
          .buttonCard{
            height: 3vh;display: flex;padding: 0.4vh;width:11vw;
            ::v-deep img{
              object-fit: contain;width: fit-content
            }
            span{
              align-items: center;display: flex;padding-left: 1vw;font-weight: bolder
            }
          }
        }
      }
    }
    .part1_2{
      width: 100%;height: 50%;display: flex;gap: 5px;padding-bottom: 5px;
      .part1_2_1{
        background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.39), /* 蒙版的颜色 */
                    rgba(0, 0, 0, 0.39)
            ),url("../../assets/homeNavigation4.png");
        background-size:cover;
      }
      .part1_2_2{
        background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.39), /* 蒙版的颜色 */
                    rgba(0, 0, 0, 0.39)
            ),url("../../assets/homeNavigation6.png");
        background-size:cover;
      }
    }
  }
  .part2{
    width: 35%;height: 100%;display: flex; padding: 10px 0px 10px 10px;
    .freeGPTs{
      background-image:
          linear-gradient(
                  rgba(0, 0, 0, 0.39), /* 蒙版的颜色 */
                  rgba(0, 0, 0, 0.39)
          ),
          url("../../assets/homeNavigation2.png");
      background-size:cover;
    height: 100%;width: 100%;margin: auto
    }
  }
}
</style>
