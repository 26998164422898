<template>
  <div>
    <div v-if="model === false" class="layer1">
      <div class="leftLayer" @click="back"></div>
      <div class="rightLayer">
        <div class="communicitynet" @click="Chinese">
          <!--          <div class="net"></div> &nbsp;-->
          &nbsp;中文</div>
        <div class="communicity" @click="gpts">GPT Community</div>
        <div class="communicity" @click="show">Join Now</div>
        <div v-if="userId" class="loginUser">
          <div style="display: flex">
            <div class="communicitynet" @click="Chinese">
              <!--              <div class="net"></div> &nbsp;&nbsp;-->
              中文</div>
            <el-dropdown class="userInfo">
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <el-avatar :size="30" :src="avator" class="avator"></el-avatar>
                <div class="userName">
                  <span></span>
                  {{ userName }}
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    style="display: flex; height:32px;text-align: left;font-size: 1vw;font-family: arial, sans-serif, 'Microsoft Yahei'"
                    @click.native="outLogin()"
                >
                  <img
                      alt=""
                      class="icon hover"
                      src="@/assets/outHoverIcon.png"
                      style="width: 20px; height: 20px; margin: auto 5px auto 0"
                  />
                  Login out
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div v-else class="login" @click="login">登录/注册</div>
      </div>
    </div>
    <div v-else>
      <div class="layerModel">
        <div v-if="showsearch" class="searchnew" style="z-index: 9999999999999999999999999;">
          <div style="float: right;margin: 5px"><i class="el-icon-circle-close" style="font-size: 20px;font-weight:bold;color: #163eee"
                                                   @click="showsearch=false"></i></div>
          <div class="search">
            <el-input v-model="keyWords" style="border: none" placeholder="Please enter the GPT or content keyword" @input="getSearch"></el-input>

            <i class="el-icon-search"
               style="margin-right: 15px;z-index: 99;font-size: 16px;color:gray;font-weight: bold;height: 45px;display: flex;align-items: center;justify-content: center"></i>
          </div>

          <div v-if="search.length>0" class="searchBack">
            <li v-for="item in search" :key="item">
              <div class="searchContent" @click="routerSkip(item.link)">
                <div class="title1" v-html="item.title"></div>
                <div class="title2" v-html="item.contend"></div>
              </div>

            </li>

          </div>
          <div v-else class="searchBackno">
            The information you are looking for about <span style="color: #eb7c31;margin: 0 0.5vw">“{{ keyWords }}”</span>has not been retrieved yet, Try changing the keywords！
          </div>
        </div>
        <div class="leftLayer" @click="back"></div>

        <div class="rightLayer">
          <div v-if="userId" class="loginUser">
            <div class="login" style="margin:3px  -10px 0 0 " @click="showsearch=true"><i style="color: black" class="el-icon-search"/></div>
            <div class="communicitynet" >
              <!--              <div class="net"></div> &nbsp;&nbsp;-->
              <el-dropdown @command="handleCommand" style="font-size: 1vw;font-weight: bolder">
              <span class="el-dropdown-link" style="font-size: 14px;color: black;margin:0px 0 5px 10px">
                EN<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="CN" style="font-size: 16px;color: black;">中文</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-dropdown class="userInfo">
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <el-avatar :size="30" :src="avator" class="avator"></el-avatar>
                <div class="userName">
                  <span></span>
                  {{ userName }}
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item  style="display: flex; height:32px;text-align: left;font-size: 16px;font-family: arial, sans-serif, 'Microsoft Yahei'"
                                   class="hoverItem">
                  <router-link style="display: flex" class="flex" to="/infoMobile">
                    <div>
                      <img
                          alt=""
                          class="icon not"
                          style="width: 20px; height: 20px; margin: 7px 5px auto 0"
                          src="@/assets/userIcon.png"
                      />
                    </div>
                    <span style="margin-top: 0">Personal Center</span>
                    <span style="position: absolute;right:0px;margin-left: 10px;font-size: 10px"><i class="el-icon-arrow-right"></i></span>

                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 16px;height: 32px"
                    @click.native="outLogin()"
                >
                  <img
                      alt=""
                      class="icon hover"
                      src="@/assets/outHoverIcon.png"
                      style="width: 20px; height: 20px; margin: auto 5px auto 0"
                  />
                  Login out
                  <span style="position: absolute;right:0px;margin-left: 10px;font-size: 10px"><i class="el-icon-arrow-right"></i></span>

                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <img :src="three" class="communicitynet"
                     style="height: 23px;width: 20px;border-radius:0;display: flex;justify-content: center;align-items: center">
              </div>

              <el-dropdown-menu slot="dropdown">

                <el-dropdown-item
                    class="hoverItem"
                    style="display: flex; height:32px;text-align: left;font-size: 16px;font-family: arial, sans-serif, 'Microsoft Yahei'">
                  <div @click="show">
                    <span style="margin-top: 0">Join Now</span>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 16px;height: 32px"
                >
                  <div @click="gpts">GPT Community</div>


                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
          <div v-else style="display: flex">
            <div class="login" style="margin:3px  -10px 0 0 " @click="showsearch=true"><i style="color: black"  class="el-icon-search"/></div>
            <div class="communicitynet" >
              <!--              <div class="net"></div> &nbsp;&nbsp;-->
              <el-dropdown @command="handleCommand" style="font-size: 1vw;font-weight: bolder">
              <span class="el-dropdown-link" style="font-size: 14px;color: black;margin:0px  0 5px 10px">
                EN<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="CN" style="font-size: 16px;color: black;">中文</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div style="color: black;margin-top:3px"  class="login" @click="login"> Sign In</div>
            <el-dropdown>
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <img :src="three" class="communicitynet"
                     style="height: 23px;width: 20px;border-radius:0;display: flex;justify-content: center;align-items: center">
              </div>

              <el-dropdown-menu slot="dropdown">

                <el-dropdown-item
                    class="hoverItem"
                    style="display: flex; height:32px;text-align: left;font-size: 16px;font-family: arial, sans-serif, 'Microsoft Yahei'">
                  <div @click="show">
                    <span style="margin-top: 0">Join Now</span>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 16px;height: 32px"
                >
                  <div @click="gpts">GPT Community</div>


                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

        </div>
      </div>
      <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
      <div class="layer2">
        <div class="title1" style="font-size: 4vw;text-align: center;line-height: 7vw">
          <div>Oil & Gas Generic AI</div>
          <div>Professional And Open AI Cloud Platform</div>
<!--          <div v-zoom-in="{duration:800}" style="color: #ee4336">Making Oil & Gas  Intelligence,Simple And Easy</div>-->
        </div>
<!--        <div  class="title2" style="margin:auto;color: #f04336;font-size: 20px">-->
<!--          <span style="color:#282d7d;">Professional And Open AI Cloud Platform</span>-->
<!--          <br>-->
<!--          <div v-zoom-in="{duration:800}" style="margin-top: 10px;font-size: 20px">-->
<!--            Making Oil & Gas  Intelligence,Simple And Easy</div></div>-->

        <div class="title3" v-if="!focus">
          <home-navigation-mobile></home-navigation-mobile>
          <div>
<!--            <div v-sliden-instep="{duration:800}" class="hoverTitleup"  @click="position1">-->
<!--              <div style="display: flex;align-items: center">-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner1.png">-->
<!--                </div><div class="title1">-->
<!--                Oil & Gas INFO GPTs</div>-->
<!--              </div>-->
<!--  -->
<!--              &lt;!&ndash;            <div class="light">We provide users with free online Oil & Gas information services based on AI large model technology.</div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div v-sliden-instep="{duration:800}" class="hoverTitleup" @click="position7">-->
<!--              <div style="display: flex;align-items: center">-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner2.png">-->
<!--                </div><div class="title1">-->
<!--                Oil & Gas GPT App Development</div>-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="light">The establishment of the Oil & Gas professional GPTs AI model is mainly based on the application of engineering technology companies such as Anton, and is open to industry companies for sharing.</div>&ndash;&gt;-->
<!--  -->
<!--            </div>-->
<!--            <div v-sliden-instep="{duration:800}" class="hoverTitleup" @click="position5">-->
<!--              <div style="display: flex;align-items: center">-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner3.png">-->
<!--                </div><div class="title1">-->
<!--                Enhance Operation Efficiency GPTs</div>-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="light">Enterprise management GPTs AI is based on specific enterprise environment training.</div>&ndash;&gt;-->
<!--  -->
<!--            </div>-->
<!--  -->
<!--            <div v-sliden-instep="{duration:1200}" class="hoverTitleup" style="margin-right: 0" @click="position2">-->
<!--              <div style="display: flex;align-items: center">-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner4.png">-->
<!--                </div><div class="title1" >-->
<!--                Office Support<br>GPTs</div>-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="light">General GPTs Online Service，based on AI big model capabilities, provide employees with AI tools for learning improvement, creation generation, and workplace, assisting users to improve work efficiency.</div>&ndash;&gt;-->
<!--  -->
<!--            </div>-->
<!--            <div v-sliden-instep="{duration:1200}" class="hoverTitleup" @click="position8">-->
<!--              <div style="display: flex;align-items: center">-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner5.png">-->
<!--                </div><div class="title1">-->
<!--                Assist On-site Operations GPT</div>-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="light">GPT is used to assist Oil & Gas development field operations.</div>&ndash;&gt;-->
<!--  -->
<!--  -->
<!--            </div>-->
<!--            <div v-sliden-instep="{duration:1200}" class="hoverTitleup" @click="position6">-->
<!--              <div style="display: flex;align-items: center;height: 100%">-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner6.png">-->
<!--                </div><div class="title1">-->
<!--                Smart Oilfield GPT App</div>-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="light">Use large-model AI technology to build a full-scenario digital smart oilfield solution that combines geology and engineering to empower the comprehensive upgrade of digital smart oilfields.</div>&ndash;&gt;-->
<!--  -->
<!--            </div>-->
<!--  -->
<!--            <div v-sliden-instep="{duration:1600}" class="hoverTitleup" @click="position9">-->
<!--              <div style="display: flex;align-items: center">-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner7.png">-->
<!--                </div>-->
<!--                <div class="title1">-->
<!--                  Intelligent Multimedia</div>-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="light">Help corporate customers provide Intelligent multimedia services for the Oil & Gas industry, making the Oil & Gas world more lively and stylish.</div>&ndash;&gt;-->
<!--  -->
<!--            </div>-->
<!--            <div v-sliden-instep="{duration:1600}" class="hoverTitleup" @click="position3">-->
<!--              <div style="display: flex;align-items: center">-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner8.png">-->
<!--                </div>-->
<!--                <div class="title1">-->
<!--                  AI Training & Transformation</div>-->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="light">Help customers achieve artificial transformation planning, project design, and expert consulting services.</div>&ndash;&gt;-->
<!--  -->
<!--            </div>-->
<!--            <div v-sliden-instep="{duration:1600}" class="hoverTitleup" @click="position10">-->
<!--              <div style="display: flex;align-items: center" >-->
<!--                <div style="height: 25px;width: 25px;margin-right:5px"><img loading="lazy"-->
<!--                                                                            src="@/assets/banner9.png">-->
<!--                </div>-->
<!--                <div class="title1"> Self-built GPT Cloud Platform</div>-->
<!--  -->
<!--              </div>-->
<!--              &lt;!&ndash;            <div class="light">Help enterprise customers build an AI cloud platform in a private environment, relying on the platform to accumulate model results, computing resources, and data for each application scenario, to maximize the sharing of models and make full use of computing resources.</div>&ndash;&gt;-->
<!--  -->
<!--            </div>-->
          </div>
        </div>
      </div>

    </div>

    <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
  </div>
</template>


<script>
import {
  logOut,
  userDetail,
  getAuthorize,
  // getLoginStatus,
  // deleteUserAgent,
} from "@/api/user";
import Fingerprint2 from "fingerprintjs2";
import PartnerTip from "@/components/partnerTip.vue";
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vZoomIn} from "@/utils/vZoomIn";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import homeNavigationMobile from "@/views/component/homeNavigationMobile.vue";
// import AIAnswer from "@/views/AIAnswer.vue";
export default {
  directives: {
    slidenIn: vSlidenIn, // 注册指令
    zoomIn: vZoomIn,
    slidenInstep:vSlidenInstep
  },
  name: "headtip",
  props: { focus: {
      type: Number,
      required: true
    },},
  components: {PartnerTip,homeNavigationMobile},
  data() {
    return {
      model: false,
      showsearch: false,
      keyWords: "",
      search: [
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
      ],
      value: '选项1',
      three: require("@/assets/three.png"),
      options: [{
        value: '选项1',
        label: 'EN'
      }, {
        value: '选项2',
        label: '中文'
      },],
      userName: "",
      avator: require("@/assets/user.jpg"),
      userId: localStorage.getItem("userId"),
      dialogconsult: false,
      userInfo:'',
      baseURL: this.$utils.baseURLEN,
      baseURLEN: this.$utils.baseURL,
    };
  },
  created() {
    this.getSearch()
    console.log("userId", this.userId);

  },
  watch: {
    "$store.state.userId"(newValue, oilValue) {
      this.userId = newValue;
      if (newValue && newValue != oilValue) {
        this.getUser();
        this.ifComplete()
      }
    },
  },
  mounted() {
    const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true;
    } else {
      this.model = false;
    }
    let murmur;
    let that = this;
    var options = {
      excludes: {
        enumerateDevices: true,
        platform: true,
        addBehavior: true,
        cpuClass: true,
        deviceMemory: true,
        hardwareConcurrency: true,
        colorDepth: true,
        screenResolution: true,
        availableScreenResolution: true,
        indexedDb: true,
        webgl: true,
        plugins: true,
        canvas: true,
        pixelRatio: true,
      },
    };
    console.log(options, "options");
    Fingerprint2.get(options, (components) => {
      const values = components.map(function (component, index) {
        if (index === 0) {
          return component.value.replace(/\bNetType\/\w+\b/, "");
        }
        return component.value;
      });
      murmur = Fingerprint2.x64hash128(values.join(""), 31);
      that.murmur = murmur;
      localStorage.setItem("murmur", murmur);
      that.isLogin();
    });
    this.userId = localStorage.getItem("userId");
    if (this.userId) {
      this.getUser();
      this.ifComplete()
    }
  },
  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.totalModel;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    },
    getSearch() {
      console.log(this.search.length)
      this.$axios
          .get("https://cn.oilgasgpts.com/search/getThirdByName_IntroduceEn?keyword=" + this.keyWords, {
            // .get("http://192.168.200.50:16542/search/getThirdByName_Introduce?keyword=" + this.keyWords, {
            headers: {
              Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUyNjZiN2QxLTNhOWUtNDBiOC05MGJlLTNjMTU1OGM3YmI3YiJ9.OsIDjD4UL8AZHu5j5KO-26IQwJu6uVvu59KNM18vuLmjzXMJFl-lX2vyyw_4L1AXC6wot6lpxhv_JZUVe1LokQ",
            },
          })
          .then((res) => {
            console.log("search", res.data)
            this.search = []
            var searchdata = res.data.data.thirdPageList

            searchdata.forEach((item, index) => {
              if(res.data.data.keywordList[0]!==""){
                item.leighttitle = this.highlightText(item.title,  res.data.data.keywordList);
                item.leightcontend = this.highlightText(item.introduce,  res.data.data.keywordList);
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.leighttitle,
                  contend: item.leightcontend,
                });
              }
              else{
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.title,
                  contend: item.introduce,
                });
              }
            })
            console.log("成都", this.search.length)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    highlightText(text, keywords) {
      const regex = this.createHighlightRegex(keywords);
      return text.replace(regex, (match) => `<span style="color: #eb7c31">${match}</span>`);
    },
    createHighlightRegex(keywords) {
      const escapedKeywords = keywords.map(keyword => keyword.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'));
      const regexPattern = escapedKeywords.join('|');
      return new RegExp(regexPattern, 'gi');
    },
    routerSkip(link) {
      window.open(link, "_blank");
    },
    position1(){
      sessionStorage.setItem('skip',2)
      location.reload()
    },
    position2(){
      sessionStorage.setItem('skip',1)
      location.reload()
    },
    position3(){
      sessionStorage.setItem('skip',12)
      location.reload()
    },
    position5(){
      console.log("dfd")
      sessionStorage.setItem('skip',10)
      location.reload()
    },
    position4(){
      sessionStorage.setItem('skip',13)
      location.reload()
    },

    position6(){
      sessionStorage.setItem('skip',7)
      location.reload()
    },
    position7(){
      sessionStorage.setItem('skip',5)
      location.reload()
    },
    position8(){
      sessionStorage.setItem('skip',6)
      location.reload()
    },
    position9(){
      sessionStorage.setItem('skip',14)
      location.reload()
    },
    position10(){
      sessionStorage.setItem('skip',13)
      location.reload()
    },
    isLogin() {
      let murmur = localStorage.getItem("murmur");
      let idass = this.$store.state.idass;
      let data;
      console.log(idass, "idass");
      if (idass && idass != "undefined") {
        data = {
          userAgent: murmur,
          idass: idass,
        };
      } else {
        data = {
          userAgent: murmur,
        };
      }
      // getLoginStatus(JSON.stringify(data))
      this.$axios
          .post("https://www.oilgasinfoai.com/auth/oauth/getLoginStatus", data)
          .then((res) => {
            console.log(res, "res11111");
            let options = this.$route.query;
            if (res.data.data.isLogin == true && this.$store.state.access_token) {
              this.getUser();
              this.ifComplete()
            } else if (
                res.data.data.isLogin == true &&
                !this.$store.state.access_token &&
                !options.code
            ) {
              this.login();
            } else if (
                res.data.data.isLogin == false &&
                res.data.data.type == 2
            ) {
              console.log("isLogin Flase type 2");
              //this.outLogin();
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.userId = "";
              this.userName = "";
            } else if (
                res.data.data.isLogin == false &&
                res.data.data.type == 1
            ) {
              console.log("isLogin Flase type 1");
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.userId = "";
              this.userName = "";
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.login();
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getUser() {
      userDetail(this.userId).then((res) => {
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        localStorage.setItem("userId", res.data.gptsUserId);
        this.$store.state.user = res.data;
        this.$store.state.userId = res.data.gptsUserId;
        this.userId = res.data.gptsUserId;
        this.userName = this.userInfo.gptsUserName;
        this.ifComplete()
      });
    },
    ifComplete(){
      this.$axios
          .get("/info/gpts_user/judgment_info/" + this.userId)
          .then((res) => {
            if(res.data.msg=="信息已完善"){
              localStorage.setItem("infoComplete",1)
            }
            else{
              localStorage.setItem("infoComplete",0)
            }

            console.log("--------ren",res.data)

          })
          .catch((err) => {
            console.log(err);
          });

    },
    outLogin() {
      // localStorage.removeItem("userId");
      // window.location.reload();
      logOut()
          .then((res) => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
            // deleteUserAgent(localStorage.getItem("murmur"))
            let data = {
              userAgent: localStorage.getItem("murmur"),
            };
            this.$axios
                .post(
                    "https://www.oilgasinfoai.com/auth/oauth/deleteUserAgent",
                    data
                )
                .then(() => {
                  localStorage.removeItem("murmur");
                  window.location.href = res.data;
                  this.$router.push("/")
                });
          })
          .catch((error) => {
            console.error("退出登录失败:", error);
            this.$message.error("退出登录失败，请重试"); // 提示用户退出登录失败
            // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
          });
    },

    Chinese() {
      const routeName = this.$route.name;

      console.log(routeName)
      if(this.value==="选项2")
      {
        if (routeName === "Home") {
          window.open(this.baseURL, "_blank");
        } else if (routeName === "feedbackEn") {
          window.open(this.baseURL+"/feedback", "_blank");
        }   else if(routeName==="Privacy"){
          window.open(this.baseURL, "_blank");
        }else {
          window.open(this.baseURL+"/"+ routeName, "_blank");
        }

      }
      else{
        if(routeName==="Home"){
          window.open(this.baseURLEN, "_blank");
        }

        else{
          window.open(this.baseURLEN+routeName, "_blank");
        }

      }
    },
    login() {
      // this.$router.push("/login")
      // let href = "https://www.oilgasgpts.com/";
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.href = res.data.url;
      });
    },
    handleCommand(command){
      if (command=='CN'){
        // window.open(this.baseURL, "_blank");
        if (this.$route.name=='Home'){
          window.open(this.baseURL, "_blank");
        }
        else {
          window.open(this.baseURL+"/"+ this.$route.name, "_blank");
        }

      }
    },
    back() {
      window.open(this.baseURLEN, "_blank");
    },
    gpts() {
      window.open("https://www.oilgascommunity.com/sortList?id=39", "_blank");
    },
    show() {
      this.$emit("showDialog");
    },
    hide() {
      this.dialogconsult = false;
    },
  },
};
</script>
<style>
.el-popper[x-placement^=bottom] .popper__arrow {
  left:80% !important;
  //margin-top: 0px!important;
  border-bottom-color: transparent !important;
}
.el-popper[x-placement^=bottom]{
  margin-top: 0px!important;
}
</style>

<style lang="scss" scoped type="text/scss">
.el-dropdown-menu__item, .el-menu-item{
  padding: 0 10px !important;
}
.el-dropdown-menu{
  padding: 0 !important;
}

.avator {
  border: 2px solid #fff;
}
.searchnew {
  background-color: #d7d7d7;
  position: fixed;
  width: 100vw;
  top:0;
  left:0;
  height: 100vh;

  z-index: 9999999999999999999999999;

  .searchBack {
    //background-color: rgba(102, 102, 102, 0.2);
    //display: grid;
    //grid-template-columns: minmax(0, 1fr);
    position: fixed;
    height:90vh;
    overflow-y: auto;
    padding: 0 15px;
    row-gap: 15px;
    list-style-type: none;
  }

  .searchBackno {
    padding: 15px 25px;
    font-weight: bold;
    text-align: center;
  }

  .searchContent {
    cursor: pointer;
    //background: #e5ebfa;
    border: white 1px solid;
    margin-bottom: 15px;
    background-color: #f2f2f2;
    //box-shadow: 5px 0 14px 0px #e5ebfa;
    padding: 10px;
    height: 100%;
    border-radius: 9px;
    //color: white;

    .title1 {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
    }

    .title2 {
      font-size: 12px;
      margin-top: 5px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
    }
  }

  .search {
    margin: 35px 5vw 25px 5vw;
    display: flex;
    border-radius: 9px;
    width: 90vw;
    background-color: white;

    ::v-deep .el-input {
      border-radius: 9px;
      width: 80vw;

      font-size: 14px;
    }

    ::v-deep .el-input__inner {
      border-radius: 9px;
      height: 45px !important;
      border: white 1px solid !important;
      background-color: transparent !important;
    }

  }
}
.userName {
  color: #2f6eff;
  font-size: 0.9vw;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layer1 {
  display: flex;
  justify-content: space-between;
  padding: 0 1.61756210283073vw;

  .leftLayer {
    cursor: pointer;
    width: 10.2790294627383vw;
    height: 6.54993819530284vh;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicitynet {
      z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family:Times New Roman;
      font-weight: 400;
      color: #2f6eff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 9.1px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: #2f6eff 1px solid;
      background-color:  rgba(255, 255, 255, 0.5);
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
      display: flex;
      .net{
        background-image: url("../assets/net.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 20px;
        height: 20px;
      }
    }

    .communicity {
      z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family:Times New Roman;
      font-weight: 400;
      color: #2f6eff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 9.1px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: #2f6eff 1px solid;
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
    }

    .communicity:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    .loginUser {
      z-index: 8;
      //border: #2F6EFF 1px solid;
      border-radius: 9.1px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family:Times New Roman;
      color: #2f6eff;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 4.56242274412855vh;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .icon.hover {
          display: none;
        }
      }
    }

    .login {
      z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family:Times New Roman;
      font-weight: 400;
      color: #ffffff;
      line-height: 3.33745364647713vh;
      text-align: center;
      border-radius: 9.1px;
      width: fit-content;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      background: #2f6eff;
      border: #2f6eff 1px solid;
    }

    .login:hover {
      color: #2f6eff;
      background: rgba(255, 255, 255, 0.5);
      border: #2f6eff 1px solid;
    }
  }
}

.layerModel {
  display: flex;
  justify-content: space-between;
  //background-color: #102078;
  padding: 15px 5px 10px 5px;
  ::v-deep .el-input__suffix{
    top:-2px !important;
  }
  .userName {
    color: black;
    font-size: 14px;
    text-align: left;
    margin-left: 10px;
    max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .leftLayer {
    cursor: pointer;
    width: 100px;
    height: 30px;
    margin:-8px 0 0 10px;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    margin-top: -0.89443757725587vh;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicity {
      z-index: 8;
      cursor: pointer;
      font-size: 14px;
      font-family:Times New Roman;
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 9.1px;
      padding: 15px 0;
      height:45px;
      overflow: hidden; /* Add overflow property */
    }
    .communicitynet {
      z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family:Times New Roman;
      font-weight: 400;
      color: white;
      line-height: 25px;
      text-align: center;
      border-radius: 9.1px;
      width: fit-content;
      padding: 5px;
      height: 35px;

      ::v-deep .el-input {
        border-radius: 9px;
        width: 75px;
        font-size: 14px;
        color: black;
      }
      ::v-deep .el-select .el-input .el-select__caret{
        color: black;
      }
      ::v-deep .el-input__inner {
        border-radius: 9px;
        color: black;
        height: 5vh !important;
        border: none !important;
        background-color: transparent !important;
      }
      .net{
        background-image: url("../assets/netModel.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 14px;
        height: 14px;
      }
    }


    .loginUser {
      z-index: 8;
      //border: #2F6EFF 1px solid;
      border-radius: 9.1px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family:Times New Roman;
      color:white;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 45px;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .icon.hover {
          display: none;
        }
      }
    }

    .login {
      z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family:Times New Roman;
      font-weight: bold;
      color: #ffffff;
      line-height: 15px;
      text-align: center;
      border-radius: 9.1px;
      width: fit-content;
      padding: 0 5px 3px;
      height: 30px;
    }


  }
}
.layer2 {

  .title1 {
    margin: 0px auto 0px auto;
    width: fit-content;
    height: fit-content;
    font-size: 23px;
    font-family:Times New Roman;
    font-weight: bold;
    color: #122279;
    line-height:22px;
  }

  .title2 {
    margin: 0 15px 0 15px;
    text-align: center;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    font-family:Times New Roman;
    font-weight: bold;
    color: #122279;
    line-height: 22px;
  }

  .title3 {
    //padding: 0 10px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    column-gap: 5px;
    //row-gap: 17px;
    text-align: center;
    margin: 0px auto 0px auto;
    width: 100%;
    height: fit-content;
    font-size: 8px;
    font-family:Times New Roman;
    font-weight: bold;
    color: #122279;
    line-height: 12px;
    .light{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* 限制在一个块元素显示的文本的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: normal;
      line-height: 10px;
      font-size: 10px;
      margin:  0;
      text-align: left;
    }
    .title1{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 14px;
      font-size: 10px;
      //transform: scale(0.9);
      font-weight: bold;
      margin: 5px 0;
      text-align: left;
    }
    .hoverTitleup {
      border: white 1px solid;
      box-shadow: 5px 0 14px 0px #D4E4FF;
      background-color: white;
      padding: 5px;
      border-radius: 9px;
      //display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: transform 0.3s ease-out;

    }
  }
  .title4 {
    text-align: left;
    margin: 5px auto auto 25px;
    width: fit-content;
    height: fit-content;
    font-size: 10px;
    font-family:Times New Roman;
    color: #2f6eff;
    line-height:22px;
  }
}
.layer3 {
  position: absolute;
  opacity: 1;
  left: 0;
  width: 100%;
  justify-content: center;

  .communicity {
    z-index: 8;
    cursor: pointer;
    font-size: 16px;
    font-family:Times New Roman;
    font-weight: 400;
    color: #2f6eff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 9.1px;
    width: fit-content;
    margin: 0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }

  .communicityGpt {
    z-index: 8;
    cursor: pointer;
    font-size: 16px;
    font-family:Times New Roman;
    font-weight: 400;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 9.1px;
    width: fit-content;
    margin: 0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    background-color: #2f6eff;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }

}
</style>
