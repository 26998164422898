export default {
  // baseURL: 'http://18.166.209.132:18060'
  //  baseURL:'http://54.223.240.188:8051', //正式环境ip/
   //  baseURL: 'https://oilgasjobai.com',
   // // // 正式
   baseURL: 'https://www.oilgasgpts.com',
   baseURLEN: 'https://cn.oilgasgpts.com'
   //测试
   // baseURL: 'http://71.131.202.255:8788',
   // baseURLEN: 'http://71.131.202.255:8787'
}
