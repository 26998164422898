<script>
export default {
  props:{
    title1:{
      type:String,
      default:'AI预测'
    },
    path:{
      type:String,
      default: "/frack"
    }
  },
  methods:{
    RoutTo(path){
      window.location.href=path
    }
  }
}
</script>

<template>
  <div id="hot_" style="word-wrap: break-word;  /* 自动换行 */" @click="RoutTo(path)">
    <div id="title1v1" class="title1" style="background-color: white;padding: 3px 10px;border-radius: 9px;height: 8vw;margin:1vw;display: flex;align-items: center;font-size: 3.3vw" v-html="title1">

    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
