<script>
import {vMoveInDuration} from "@/utils/vMoveInDuration";
export default {
  directives:{
    moveInDuration:vMoveInDuration
  },
  methods:{
    clickConsult(itemName){
      this.$emit("clickConsult",itemName)
    },

    meitiProduct(){
      this.$emit("meitiProduct",true)
    }
  }
}
</script>

<template>
  <div class="MediumContainer">
    <div class="titleDesign">
      <div class="title" v-move-in-duration="{duration:1000,direction:'y',offset:'100'}">5. Intelligent Multimedia</div>
      <div class="light" v-move-in-duration="{duration:1000,direction:'y',offset:'100'}" style="width: fit-content;margin: auto">
        Provide digital multimedia services for oil and gas clients to boost your brand's spread in the digital wave.
        <span class="light2" style="color:#fb6400;">
            <br>For product scheme demo and communication,
          <span style="color:white;background-color: #282d7d;padding: 5px;border-radius: 9px;font-weight: bolder;font-size: 1.1vw" @click="clickConsult('Intelligent Multimedia')" >Contact Us</span>.</span>
      </div>
    </div>
    <div class="itemDesign" >
      <div v-move-in-duration="{duration:1000,direction:'y',offset:'100'}" class="part1">
        <div @click="meitiProduct()" class="part1_1">
          <div class="card">
            <div class="imgDesign">
              <img src="@/assets/meiti3.jpg">
            </div>
            <div class="content">
            <span>
              Digital & Intelligent Brand Design
            </span>
              <div class="more">
                <span>MORE</span>
                <img  src="@/assets/arrow_blue.png">
              </div>
            </div>
          </div>
        </div>
        <div @click="meitiProduct()" class="part1_2">
          <div class="card">
            <div class="imgDesign">
              <img src="@/assets/metinew1.png">
            </div>
            <div class="content">
            <span>
              Intelligent Social Media Operation
            </span>
              <div class="more">
                <span>MORE</span>
                <img  src="@/assets/arrow_blue.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-move-in-duration="{duration:1000,direction:'y',offset:'100'}" class="part2">
        <div @click="meitiProduct()" class="part2_1">
          <div class="card">
            <div class="imgDesign">
              <img src="@/assets/meiti2.png">
            </div>
            <div class="content">
            <span >
              Digital Video Creation
            </span>
              <div class="more">
                <span>MORE</span>
                <img  src="@/assets/arrow_blue.png">
              </div>
            </div>
          </div>
        </div>
        <div @click="meitiProduct()" class="part2_2">
          <div class="card">
            <div class="imgDesign">
              <img src="@/assets/meitinew2.png" >
            </div>
            <div class="content">
            <span>
              Digital & Intelligent Integrated Experience
            </span>
              <div class="more">
                <span>MORE</span>
                <img  src="@/assets/arrow_blue.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.MediumContainer{
  .titleDesign{
    color: black;
    .title{
      margin: 0 auto;
      text-align: center;
      height: 8.2373300371vh;
      opacity: 1;
      font-size: 3vw;
      font-family: "Times New Roman";
      font-weight: 700;
      line-height: 4.2373300371vh;
    }
    .light{
      font-size: 1.5vw;
      padding: 0 2vw 2vw 2vw;
      line-height: 6vh;
      //font-weight: bolder;
    }
  }
  .itemDesign{
    display: flex;
    height: 82vh;
    .card{
      display: flex;
      width: 100%;
      height: 100%;
      .imgDesign{
        width: 70%;
        height: 100%;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
        }
      }
      .content{
        width: 30%;
        height: 100%;
        background-color: #eeeeee;
        display: flex;
        position: relative;
        span{
          margin: auto;
          font-size: 1.2vw;
          font-weight: bolder;
          color: #3a4591;
          //width: 80%;
          text-align: center;
        }
        .more{
          display: flex;
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 1vw 1vw;
          img{
            margin: auto;
            width: 1.5vw;
            height: 1.5vw;
          }
          span{
            color: #3a4590;
            font-size: 1.0vw;
            font-weight: unset;
          }
        }
      }
    }
    .part1{
      padding: 1vh 1vw;
      width: 50%;
      .part1_1{
        width: 100%;
        height: 50%;
        margin-bottom: 1vh;
      }
      .part1_2{
        width: 100%;
        height: 50%;
        margin-top: 1vh;
      }

    }
    .part2{
      padding: 1vh 0vw;
      width: 50%;
      //flex-direction: column;
      //display: block;
      //gap: 2vh;

      .part2_1{
        padding-bottom: 1vh;
        width: 100%;
        height: 50%;

      }
      .part2_2{
        padding-top: 1vh;
        width: 100%;
        height: 50%;
      }
    }
  }
}

</style>
